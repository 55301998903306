import React, { Component } from 'react'

import { Alert, Checkbox, Form, Input } from 'element-react'
import { Drawer } from 'antd'
import { Button } from 'element-react'
import { Popover } from 'antd'

/**
 * 贷超
 */
export default class ProductClassifyFrom extends Component {

    static SaveResultEmpty = {}

    constructor(props) {
        super(props)

        this.state = {
            dcClassifyAll: [],
            saveForm: ProductClassifyFrom.SaveResultEmpty,
            saveFormVisible: false,
            saveResult: null
        }
    }

    componentDidMount() {
        this.getDcClassifyAll();
    }

    componentDidUpdate(preProps) {
        if (preProps.pid !== this.props.pid) {
            this.getDcClassifyAss();
        }

        if (preProps.saveFormVisible !== this.props.saveFormVisible) {
            this.getDcClassifyAll();
        }
    }

    async getDcClassifyAll() {
        const { body } = await this.props.onClassifyReq();
        this.setState({
            dcClassifyAll: body
        });
    }

    async getDcClassifyAss() {
        const { body } = await this.props.onListReq({ pid: this.props.pid });
        this.setState({
            saveForm: body || ProductClassifyFrom.SaveResultEmpty
        });
    }


    objFilter(org, keys) {
        const newObj = {};
        keys.forEach(element => {
            newObj[element] = org[element] ?? 100;
        });
        return newObj;
    }

    async saveData() {
        const saveResult = await this.props.onSaveReq({ pid: this.props.pid, data: this.state.saveForm });
        this.setState({ saveResult })
    }

    render() {
        const { dcClassifyAll, saveResult, saveForm } = this.state;
        const { saveFormVisible, onClose } = this.props;

        return (
            <>
                <Drawer width={500} visible={saveFormVisible} onClose={() => {
                    onClose();
                    this.setState({ saveResult: null });
                }}>
                    <h2>口子归属</h2>
                    <Form>
                        <Form.Item>
                            <Checkbox.Group onChange={x => this.setState({ saveForm: this.objFilter(saveForm, x) })}
                                value={Object.keys(saveForm || {})}>
                                {
                                    dcClassifyAll.map(({ id, name }) =>
                                        <Checkbox key={id} value={`${id}`} label={
                                            <Popover title="排序" width="200" trigger="hover"
                                                content={<Input
                                                    onChange={sort => this.setState({ saveForm: { ...saveForm, [id]: sort } })}
                                                    value={saveForm[id]} type='number' placeholder='排序' />}>
                                                {name}
                                            </Popover>} />
                                    )
                                }
                            </Checkbox.Group>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => this.saveData()}>{'提交'}</Button>
                        </Form.Item>

                        {saveResult ? (
                            <Form.Item>
                                <Alert title={saveResult.message} type={saveResult.success ? 'success' : 'error'} />
                            </Form.Item>
                        ) : null}
                    </Form>
                </Drawer>
            </>
        )
    }
}