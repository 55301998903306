import { Alert, Button, Layout, Message } from 'element-react'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apiV2 from '../../../api/api-v2'
import EnabkeStatus from '../../../components/EnabkeStatus'

import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { GaugeChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { Descriptions, Statistic } from 'antd'
echarts.use(
    [GridComponent, GaugeChart, CanvasRenderer]
)

/**
 * 米融共债
 */
export default class MiRongGZai extends Component {

    static propTypes = {
        userId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            result: {},
            loading: false,
        }
    }

    componentDidMount() {
        this.getMiRongScore()

        const myChart = echarts.init(document.getElementById('fraud_score_chat'))
        this.setState({ myChart })
        const myChart2 = echarts.init(document.getElementById('small_loan_points_chat'))
        this.setState({ myChart2 })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.getMiRongScore()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    setDataFraud(v) {
        this.state.myChart.setOption({
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.25, '#FF6E76'],
                                [0.5, '#FDDD60'],
                                [0.75, '#58D9F9'],
                                [1, '#7CFFB2']
                            ]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '12%',
                        width: 20,
                        offsetCenter: [0, '-60%'],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: '#464646',
                        fontSize: 20,
                        distance: -60,
                        formatter: function () {
                            return ''
                        }
                    },
                    title: {
                        offsetCenter: [0, '-20%'],
                        fontSize: 30
                    },
                    detail: {
                        fontSize: 50,
                        offsetCenter: [0, '0%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            return Math.round(value * 1000) + '分'
                        },
                        color: 'auto'
                    },
                    data: [
                        {
                            value: v / 1000,
                            name: '分数'
                        }
                    ]
                }
            ]
        })
    }

    setDataSmallLoanPoints(v) {
        this.state.myChart2.setOption({
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.25, '#FF6E76'],
                                [0.5, '#FDDD60'],
                                [0.75, '#58D9F9'],
                                [1, '#7CFFB2']
                            ]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '12%',
                        width: 20,
                        offsetCenter: [0, '-60%'],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: '#464646',
                        fontSize: 20,
                        distance: -60,
                        formatter: function () {
                            return ''
                        }
                    },
                    title: {
                        offsetCenter: [0, '-20%'],
                        fontSize: 30
                    },
                    detail: {
                        fontSize: 50,
                        offsetCenter: [0, '0%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            return Math.round(value * 1000) + '分'
                        },
                        color: 'auto'
                    },
                    data: [
                        {
                            value: v / 1000,
                            name: '分数'
                        }
                    ]
                }
            ]
        })
    }


    async getMiRongScore(userId = this.props.userId) {
        if (!userId) {
            this.setState({ result: null })
            return
        }
        const { body } = await apiV2.getMiRongScore({ userId })
        if (body) {
            this.setState({ result: body })
            this.setDataFraud(body.fqzScore || 0)
            this.setDataSmallLoanPoints(body.xdScore || 0)
        }
    }

    async refreshMiRongFraudScore({ userId = this.props.userId, refresh = false } = { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.refreshMiRongFraudScore({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongScore(userId)
        } finally {
            this.setState({ loading: false })
        }
    }


    async refreshMiRongSmallLoanPointsScore({ userId = this.props.userId, refresh = false } = { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.refreshMiRongSmallLoanPointsScore({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongScore(userId)
        } finally {
            this.setState({ loading: false })
        }
    }

    async refreshMiRongBKStatus({ userId = this.props.userId, refresh = false } = { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.refreshMiRongBKStatus({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongScore(userId)
        } finally {
            this.setState({ loading: false })
        }
    }

    async refreshDaRongBKStatus({ userId = this.props.userId, refresh = false } = { userId: this.props.userId, refresh: false }) {
        this.setState({ loading: true })
        try {
            if (!userId) return
            const { message, success } = await apiV2.refreshDaRongBKStatus({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            await this.getMiRongScore(userId)
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { result, loading } = this.state

        const enableStyle = (ok) => {
            return { color: ok ? 'green' : 'red' }
        }

        return (
            result ? (
                <>
                    <Descriptions bordered style={{ padding: '2rem' }}>
                        <Descriptions.Item label="米融黑名单是否命中">
                            {result.whhmdStatus === null ? <Button loading={loading} onClick={_ => this.refreshMiRongBKStatus()}>{'点击获取'}</Button> : <EnabkeStatus style={enableStyle(result.whhmdStatus)} enable={result.whhmdStatus} />}
                        </Descriptions.Item>
                        <Descriptions.Item label="大金融黑名单是否命中">
                            {result.behmdStatus === null ? <Button loading={loading} onClick={_ => this.refreshDaRongBKStatus()}>{'点击获取'}</Button> : <EnabkeStatus style={enableStyle(result.behmdStatus)} enable={result.behmdStatus} />}
                        </Descriptions.Item>
                        <Descriptions.Item label="手机三要素状态">
                            <EnabkeStatus enable={result.isThreeElementsStatus} />
                        </Descriptions.Item>
                    </Descriptions>

                    <Layout.Row>
                        <Layout.Col span="12">
                            <h2>{'反欺诈分：分数值在300~800之间，分数越⾼ ⻛险越⼤'}</h2>
                            {result.fqzScore ? <Button onClick={_ => this.refreshMiRongFraudScore({ refresh: true })} loading={loading}>{'点击刷新'}</Button> : <Button onClick={_ => this.refreshMiRongFraudScore()} loading={loading}>{'点击获取'}</Button>}
                            <Statistic value={result.fqzScore} />
                            <div id="fraud_score_chat" style={{ width: 500, height: 500 }} />
                        </Layout.Col>
                        <Layout.Col span="12">
                            <h2>{'小贷分：分数在300~800之间，分值越⼤，坏⽤户概率越⾼'}</h2>
                            {result.xdScore ? <Button onClick={_ => this.refreshMiRongSmallLoanPointsScore({ refresh: true })} loading={loading}>{'点击刷新'}</Button> : <Button onClick={_ => this.refreshMiRongSmallLoanPointsScore()} loading={loading}>{'点击获取'}</Button>}
                            <Statistic value={result.xdScore} />
                            <div id="small_loan_points_chat" style={{ width: 500, height: 500 }} />
                        </Layout.Col>
                    </Layout.Row>

                </>) : <Alert title="系统错误，请刷新页面" />
        )
    }
}