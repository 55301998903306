//金额类型 1-身份证 2-运营商 3-短信 4-米融分 5-米融雷达探针 6-充值
export const CostTypes = [
    { key: 1, label: '身份证', color: 'gray' },
    { key: 2, label: '运营商', color: 'primary' },
    { key: 3, label: '短信', color: 'danger' },
    { key: 4, label: '米融分', color: 'warning' },
    { key: 6, label: '米融雷达', color: 'success' },
    { key: 5, label: '米融探针', color: 'warning' },
    { key: 8, label: '活体人脸', color: 'success' },
    { key: 11, label: '充值', color: 'none' }
]