import React, { Component } from 'react'

import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'
import Condition from '../../components/Condition'
import { Descriptions } from 'antd'
import { Button, Message } from 'element-react'

const cDataEmpty = {}

/**
 * 探针C
 */
export default class ProbeC extends Component {
    static propTypes = {
        userId: PropTypes.number,
        analysis: PropTypes.any,
        onRefresh: PropTypes.func,
        getReq: PropTypes.func,
        refreshReq: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            cData: cDataEmpty
        }


    }

    componentDidMount() {
        this.preSet()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.getRiskProbeC()
        }

        if (prevProps.analysis !== this.props.analysis) {
            this.preSet()
        }
    }

    preSet() {
        const cData = this.props.analysis
        if (cData) this.setState({ cData })
    }


    cleanData() {
        this.setState({
            cData: cDataEmpty
        })
    }


    async getRiskProbeC(userId = this.props.userId, hasRefresh) {
        if (userId) {
            const { body } = await (this.props.getReq || apiV2.getRiskProbeC)({ userId })
            if (body) {
                this.setState({
                    cData: body
                });
            } else {
                this.cleanData()
            }
        } else {
            if (!this.props.analysis) this.cleanData()
        }

        const onRefresh = this.props.onRefresh
        if (onRefresh) onRefresh()
        else if (hasRefresh) {
            const refresh = this.state.cData.resultCode ? true : false
            const { message, success } = await (this.props.refreshReq || apiV2.getTanzhenCByUserId)({ userId, refresh })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }
            this.getRiskProbeC()
        }
    }

    resultCodeText(code) {
        const c = { 1: '逾期未还款', 2: '正常履约', 3: '逾期又还款', 4: '无法确认' }
        return c[code]
    }

    render() {
        const cData = this.state.cData
        return (
            <>
                <Condition visible={!cData.resultCode}>
                    <Button type="warning" onClick={() => this.getRiskProbeC(this.props.userId, true)}>{'点击获取探针数据'}</Button>
                </Condition>

                {this.props.refreshReq ? null : <Button type="success" onClick={() => this.getRiskProbeC(this.props.userId, true)}>{'刷新探针数据'}</Button>
                }

                <Button type="success" onClick={() => this.getRiskProbeC(this.props.userId, true)}>{'刷新探针数据'}</Button>

                <Descriptions bordered>
                    <Descriptions.Item label="探针结果编码">{this.resultCodeText(cData.resultCode)}</Descriptions.Item>
                    <Descriptions.Item label="最大逾期金额">{cData.maxOverdueAmt}</Descriptions.Item>
                    <Descriptions.Item label="最长逾期天数">{cData.maxOverdueDays}</Descriptions.Item>
                    <Descriptions.Item label="最大履约金额">{cData.maxPerformanceAmt}</Descriptions.Item>
                    <Descriptions.Item label="当前逾期机构数">{cData.currentlyOverdue}</Descriptions.Item>
                    <Descriptions.Item label="当前逾履约构数">{cData.currentlyPerformance}</Descriptions.Item>
                    <Descriptions.Item label="睡眠机构数">{cData.accSleep}</Descriptions.Item>
                    <Descriptions.Item label="最近履约时间">{cData.latestPerformanceTime}</Descriptions.Item>
                    <Descriptions.Item label="最近预期时间">{cData.latestOverdueTime}</Descriptions.Item>
                    <Descriptions.Item label="异常还款机构数">{cData.accExc}</Descriptions.Item>
                </Descriptions>
            </>)
    }
}