import { Select } from 'element-react'
import React, { Component } from 'react'

import { AuthenticationConfig } from './types'
import PropTypes from 'prop-types'

/**
 *  认证状态下拉框
 */
export default class AuthenticationStatusSelect extends Component {

    static propTypes = {
        value: PropTypes.any,
        onChange: PropTypes.func
    }

    render() {
        return (
            <Select style={{width:'8rem'}} clearable value={this.props.value} onChange={this.props.onChange}>
                {Object.keys(AuthenticationConfig).map(key => {
                    const config = AuthenticationConfig[key]
                    return <Select.Option key={key} label={config.text} value={key} />
                })}
            </Select>)
    }
}
