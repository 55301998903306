import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Table, Tag, Tooltip } from 'element-react'
import MyPagination from '@components/MyPagination'
import { pagination } from '../../../utils/pageing'
import { durationFormat } from '../../../utils/format'
import SText from '../../../components/SText'

/**
 * 通话统计列表
 */
export default class CallStaticList extends Component {

    static propTypes = {
        calls: PropTypes.array,
    }

    constructor(props) {
        super(props)

        this.state = {
            reList: props.calls,
            query: {
                page: 1, size: 10,
                city: '',
                pRelation: '',
                callCnt1w: '',
                callCnt1m: '',
                callCnt3m: '',
                callCnt6m: '',
                firstCallTime: null,
                lastCallTime: null,
                phoneNumber: ''
            },
            callContactDetailColumns: [
                {
                    label: '通话地',
                    prop: 'city',
                },
                {
                    label: '与联系人关系',
                    prop: 'p_relation',
                },
                {
                    label: '联系人号码',
                    prop: 'peer_num',
                    fixed: true,
                    width: '140',
                },
                {
                    label: '号码类型',
                    prop: 'group_name',
                },
                {
                    label: '号码标识',
                    prop: 'company_name',
                },
                {
                    label: '周期通话',
                    prop: 'call',
                    width: '200',
                    render: ({ call_cnt_1w: cc1w, call_cnt_1m: cc1, call_cnt_3m: cc3, call_cnt_6m: cc6, call_time_3m: ct3,
                        call_time_6m: ct6, dial_cnt_3m: dc3, dial_cnt_6m: dc6, dial_time_3m: dt3, dial_time_6m: dt6,
                        dialed_cnt_3m: dic3, dialed_cnt_6m: dic6, dialed_time_3m: dit3, dialed_time_6m: dit6 }) => {

                        const ct3Ft = durationFormat(ct3)
                        const ct6Ft = durationFormat(ct6)

                        return (
                            <>
                                <Tag type="gray">{'1周:' + cc1w + '次'}</Tag>
                                <Tag type="primary">{'1月:' + cc1 + '次'}</Tag>
                                <Tooltip effect="light" content={this.infoPeriodicCall(cc3, ct3Ft, dc3, dic3, durationFormat(dt3), durationFormat(dit3))}>
                                    <Tag type="success">{`3月:${cc3}次/${ct3Ft}`}
                                        <i className="el-icon-information" />
                                    </Tag>
                                </Tooltip>
                                <Tooltip effect="light" content={this.infoPeriodicCall(cc6, ct6Ft, dc6, dt6, durationFormat(dic6), durationFormat(dit6))}>
                                    <Tag type="warning">{`6月:${cc6}次/${ct6Ft}`}
                                        <i className="el-icon-information" />
                                    </Tag>
                                </Tooltip>
                            </>
                        )
                    }
                },

                {
                    label: '时辰通话次数',
                    prop: 'row',
                    width: '320',
                    render: ({ call_cnt_morning_3m: cm3,
                        call_cnt_morning_6m: cm6,
                        call_cnt_noon_3m: cn3,
                        call_cnt_noon_6m: cn6,
                        call_cnt_afternoon_3m: ca3,
                        call_cnt_afternoon_6m: ca6,
                        call_cnt_evening_3m: ce3,
                        call_cnt_evening_6m: ce6,
                        call_cnt_night_3m: cni3,
                        call_cnt_night_6m: cni6 }) => {
                        return (
                            <>
                                <Tag type="success">{`近3月 [(早:${cm3})(中:${cn3})(午:${ca3})(晚:${ce3})(晨:${cni3})]`}</Tag>
                                <Tag type="warning">{`近6月 [(早:${cm6})(中:${cn6})(午:${ca6})(晚:${ce6})(晨:${cni6})]`}</Tag>
                            </>
                        )
                    }
                },
                {
                    label: '节日通话次数',
                    prop: 'row',
                    width: '340',
                    render: ({ call_cnt_weekday_3m: cwd3,
                        call_cnt_weekday_6m: cwd6,
                        call_cnt_weekend_3m: cwe3,
                        call_cnt_weekend_6m: cwe6,
                        call_cnt_holiday_3m: ch3,
                        call_cnt_holiday_6m: ch6,
                        call_if_whole_day_3m: cw3,
                        call_if_whole_day_6m: cw6 }) => {
                        return (
                            <>
                                <Tag type="success">{`近3月 [(工作日:${cwd3})(周末:${cwe3})(节日:${ch3})(全天联系:${cw3})]`}</Tag>
                                <Tag type="warning">{`近3月 [(工作日:${cwd6})(周末:${cwe6})(节日:${ch6})(全天联系:${cw6})]`}</Tag>
                            </>
                        )
                    }
                },
                {
                    label: '第一次通话时间',
                    prop: 'trans_start',
                    width: '160',
                },
                {
                    label: '最后一次通话时间',
                    prop: 'trans_end',
                    width: '160',
                }
            ],
        }
    }

    componentDidUpdate(props) {
        if (props.calls !== this.props.calls) {
            this.getList()
        }
    }

    queryChange(value, name) {
        const query = Object.assign(this.state.query, { [name]: value })
        this.setState({ query })
    }

    getList() {
        const { phoneNumber } = this.state.query
        const reList = this.props.calls.filter(({ peer_num: num }) => {
            if (phoneNumber && num !== phoneNumber) {
                return false
            }

            return true
        })

        this.setState({ reList })
    }

    get list() {
        const { query } = this.state
        return pagination(query.page, query.size, this.state.reList)
    }

    /**
   * 通话详情
   * @returns UI
   */
    infoPeriodicCall(totalTimes, totalDuration, primaryTimes, secondaryTimes, primaryDuration, secondaryDuration) {
        return (
            <>
                <SText title={`总时长(${totalDuration})`} value={`主叫(${primaryDuration}) + 被叫(${secondaryDuration})`} />
                <SText title={`总次数(${totalTimes})`} value={`主叫(${primaryTimes}) + 被叫(${secondaryTimes})`} />
            </>
        )
    }


    render() {
        const { query, callContactDetailColumns } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="联系号码">
                        <Input value={query.phoneNumber} onChange={e => this.queryChange(e, 'phoneNumber')} />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={_ => this.getList()}>{'查询'}</Button>
                    </Form.Item>
                </Form>
                {false ?
                    <Form inline>
                        <Form.Item label="通话地点">
                            <Input />
                        </Form.Item>
                        <Form.Item label="与联系人关系">
                            <Input />
                        </Form.Item>
                        <Form.Item label="近1周通话次数">
                            <Input />
                        </Form.Item>
                        <Form.Item label="近1月通话次数">
                            <Input />
                        </Form.Item>
                        <Form.Item label="近3月通话次数">
                            <Input />
                        </Form.Item>
                        <Form.Item label="近6月通话次数">
                            <Input />
                        </Form.Item>
                        <Form.Item label="第一次通话时间">
                            <Input />
                        </Form.Item>
                        <Form.Item label="最后一次通话时间">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary">{'查询'}</Button>
                        </Form.Item>
                    </Form> : null}


                <Table columns={callContactDetailColumns} data={this.list} />
                <MyPagination
                    total={this.props.calls.length}
                    pageSize={query.size}
                    pageNumber={query.page}
                    onSizeChange={e => this.queryChange(e, 'size')}
                    onCurrentChange={e => this.queryChange(e, 'page')} />
            </>)
    }
}