import { Dialog } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../../api/api-v2'
import Paste from '../../../components/paste'
import Text from '../../../components/text'
import PropTypes from 'prop-types'

/**
 * 获取登陆短信验证码
 */
export default class GetLoginSMSCode extends Component {

    static propTypes = {
        userId: PropTypes.any,
        onClose: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            code: '-',
            dialogVisible: false
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.serverGet()
        }
    }

    // 从服务器获取数据
    async serverGet(userId = this.props.userId) {
        if (userId) {
            const { message, success, body } = await apiV2.getUserCode({ userId })

            if (!body || !success) {
                this.setState({ code: message })
            } else {
                this.setState({ code: body })
            }
        } else {
            this.setState({ code: '-' })
        }
    }


    onCancel() {
        this.setState({ dialogVisible: false })
        const onClose = this.props.onClose
        if (onClose) onClose()
    }

    render() {

        return (
            <Dialog
                title="提示"
                size="tiny"
                visible={this.state.dialogVisible}
                onCancel={() => this.onCancel()}
                lockScroll={false}
            >
                <Dialog.Body style={{ textAlign: 'center' }}>
                    <Paste content={<Text color="red" size="4rem">{this.state.code}</Text>} />
                </Dialog.Body>
            </Dialog>)
    }
}