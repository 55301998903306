import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'element-react'
import { connect } from 'react-redux'
class MyPagination extends Component {
  static propTypes = {
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
    total: PropTypes.number,
    onCurrentChange: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    searchAll: PropTypes.object.isRequired,
    list: PropTypes.object.isRequired,
  }
  componentDidMount() {

  }
  onSizeChange = e => {
    this.props.onSizeChange(e)
  }
  onCurrentChange = e => {
    this.props.onCurrentChange(e)
  }
  render() {
    const { total, pageSize, pageNumber } = this.props
    return (
      <div className="pagination-con flex flex-direction_row justify-content_flex-center">
        <Pagination
          pageSize={pageSize}
          currentPage={pageNumber}
          layout="total, sizes, prev, pager, next, jumper"
          total={total}
          onSizeChange={e => this.onSizeChange(e)}
          onCurrentChange={e => this.onCurrentChange(e)}
        />
      </div>
    )
  }
}
const mapStateToProps = state => {
  const { list, searchAll } = state
  return { list, searchAll }
}
export default connect(mapStateToProps)(MyPagination)
