import { Form, Input, DateRangePicker, Select, Button, Table, Pagination, Tag } from 'element-react'
import React, { Component } from 'react'
import { datesAdapterElUI, formatDatePeriod } from '../../utils/format'
import { OrderBillMoneyType } from '../loan/types'
import { PaymentType } from './tyeps'
import api from '../../api/api-v2'
import Text from '../../components/text'
import Money from '../../components/Money'

// 交易流水列表
export default class TransBillList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                beginTime: null,
                current: 1,
                endTime: null,
                orderId: null,
                payType: [],
                size: 10,
                thirdNum: null,
                type: []
            },
            columns: [{
                label: '发生时间',
                prop: 'createTime',
                width: 200,
                align: 'center',
                fixed: true,
                render: ({ createTime }) => <Text bold color="blue">{createTime}</Text>
            }, {
                label: '金额',
                prop: 'money',
                align: 'center',
                render: ({ money }) => <Money value={money} />
            }, {
                label: '订单ID',
                prop: 'orderId',
                width: 140,
                align: 'center',
                render: ({ orderId }) => <Text color="blue">{orderId}</Text>
            }, {
                label: '支付类型',
                prop: 'payType',
                width: 140,
                align: 'center',
                render: ({ payType }) => <Tag type={PaymentType[payType].color}>{PaymentType[payType].name}</Tag>
            }, {
                label: '账单类型',
                prop: 'type',
                align: 'center',
                render: ({ type }) => <Tag type={OrderBillMoneyType[type].color}>{OrderBillMoneyType[type].name}</Tag>
            },
            {
                label: '三方单号',
                prop: 'thirdNum',
                align: 'center',
                render: ({ thirdNum }) => <Text color="blue">{thirdNum}</Text>
            }, {
                label: '备注',
                prop: 'remark',
                align: 'center',
            }],
            result: null
        }
    }

    componentDidMount() {
        this.fetchResult()
    }

    // 修改查询表单参数
    editQueryForm(name, resultRefresh) {
        return v => {
            const queryForm = Object.assign(this.state.queryForm, { [name]: v })
            this.setState(queryForm, () => {
                if (resultRefresh) this.fetchResult()
            })
        }
    }

    // 获取数据
    async fetchResult() {
        const { body } = await api.fetchTransBillList({ param: this.state.queryForm })
        this.setState({
            result: body
        })
    }

    // 修改查询表单参数-时间区间
    editQueryFormDate(startName, endName) {
        return v => {
            const [startValue, endValue] = formatDatePeriod(v)
            const queryForm = Object.assign(this.state.queryForm, { [startName]: startValue, [endName]: endValue })
            this.setState(queryForm)
        }
    }

    render() {
        const { queryForm, columns, result } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="订单号">
                        <Input value={queryForm.orderId} onChange={this.editQueryForm('orderId')} />
                    </Form.Item>
                    <Form.Item label="日期">
                        <DateRangePicker value={datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                            onChange={this.editQueryFormDate('beginTime', 'endTime')} />
                    </Form.Item>
                    <Form.Item label="支付类型">
                        <Select value={queryForm.payType} multiple onChange={this.editQueryForm('payType')}>
                            {
                                Object.keys(PaymentType).map(key => {
                                    const el = PaymentType[key]
                                    return <Select.Option key={key} label={el.name} value={key} />
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="账单类型">
                        <Select value={queryForm.type} multiple onChange={this.editQueryForm('type')}>
                            {
                                Object.keys(OrderBillMoneyType).map(key => {
                                    const el = OrderBillMoneyType[key]
                                    return <Select.Option key={key} label={el.name} value={key} />
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="三方订单号">
                        <Input value={queryForm.thirdNum} onChange={this.editQueryForm('thirdNum')} />
                    </Form.Item>
                    <Form.Item >
                        <Button onClick={() => this.fetchResult()}>{'查询'}</Button>
                    </Form.Item>
                </Form>
                {result ?
                    <>
                        <Table columns={columns} data={result.records} />
                        <Pagination total={result.total} current={queryForm.current} size={queryForm.size}
                            onCurrentChange={this.editQueryForm('current', true)}
                            onSizeChange={this.editQueryForm('size', true)}
                        />
                    </> : null}

            </>)
    }
}