import React, { Component } from 'react'

import { Drawer } from 'antd'
import PropTypes from 'prop-types'

import '../styles/image.less'
import Condition from './Condition'


/**
 * 认证信息
 */
export default class Image extends Component {


    static propTypes = {
        src: PropTypes.any,
        width: PropTypes.any,
        title: PropTypes.string,
        disableDrawer: PropTypes.bool,
    }


    constructor(props) {
        super(props)

        this.state = {
            visible: false
        }
    }

    render() {
        return (
            <div title={this.props.title}>
                <Condition visible={this.props.src}>
                    <img onClick={() => {
                        if (!this.props.disableDrawer) {
                            this.setState({ visible: true });
                        }
                    }}
                        src={this.props.src} alt="图片加载失败"
                        style={{ width: this.props.width }} />
                </Condition>
                <Drawer placement="bottom" height="85%" title="图片预览" zIndex={999999999}
                    onClose={() => this.setState({ visible: false })} visible={this.state.visible}>
                    <div className="image-drawer-warp">
                        <img src={this.props.src} alt="图片加载失败" style={{ width: '30rem' }} />
                    </div>
                </Drawer>
            </div>)
    }
}