import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import UserTables from './components/user_tables'
import UserSearch from './components/user_search'

/**
 * 用户列表
 */
export default class UserList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            body: {},
            queryFormDataHistory: null
        }
    }



    // 从服务器获取数据列表
    async serverGetTableList(queryFormData = this.state.queryFormDataHistory) {
        const { body } = await apiV2.getNoLoanAfterRegistrationUser({ data: queryFormData })
        this.setState({ body: body || {}, queryFormDataHistory: queryFormData })
    }


    render() {
        const { body } = this.state

        return (
            <>
                <UserSearch onSearch={(condition) => this.serverGetTableList(condition)} total={body.total}>
                    <UserTables onRefresh={() => this.serverGetTableList()} list={body} />
                </UserSearch>
            </>)
    }
}
