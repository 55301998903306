// 支付类型   1-支付宝 2-微信 3-银行卡 4-线下 5-汇付支付宝 6-汇付微信
export const PaymentType = {
    1: { color: 'success', name: '支付宝' },
    2: { color: 'gray', name: '微信' },
    3: { color: 'primary', name: '银行卡' },
    4: { color: 'success', name: '线下' },
    5: { color: 'warning', name: '汇付支付宝' },
    6: { color: 'danger', name: '汇付微信' },
    7: { color: 'danger', name: '奥创支付' },
    8: { color: 'danger', name: '汇付宝' }
}