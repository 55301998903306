import React, { Component } from 'react'

import { Table, Button, Form, Pagination, Dialog, Input, Message } from 'element-react'
import apiV2 from '../../api/api-v2'
import { Statistic } from 'antd'
import PropTypes from 'prop-types'
import { MoneyStatisticsStyle } from '../../utils/format'


/**
 * 借款额度,调控
 */
export default class LoanQuotaDefer extends Component {

    static propTypes = {
        quotaId: PropTypes.number
    }
    static quotaDataEmpty = []
    static quotaUpdateEmpty = { quotaId: 0, isDelete: false, dayNum: 0, rate: 0.03 }

    constructor(props) {
        super(props)

        this.state = {
            quotaUpdate: LoanQuotaDefer.quotaUpdateEmpty,
            dialogVisible: false,
            quotaColumns: [
                {
                    label: '#',
                    prop: 'deadlineId',
                    fixed: true,
                    width: '60'
                }, {
                    label: '周期',
                    prop: 'dayNum',
                    render: (row) => (<Statistic valueStyle={MoneyStatisticsStyle} value={row.dayNum} />)
                }, {
                    label: '添加时间',
                    prop: 'createTime',
                }, {
                    label: '费率',
                    prop: 'rate',
                    render: (row) => (<Statistic valueStyle={MoneyStatisticsStyle} value={row.rate * 100 + '%'} />)
                }, {
                    label: '修改时间',
                    prop: 'uptTime',
                }, {
                    label: '操作',
                    prop: 'row',
                    fixed: 'right',
                    render: (row) => {
                        return (
                            <>
                                <Button onClick={() => this.quotaSubmitState(row)}
                                    size="mini" type={row.isDelete ? 'danger' : 'success'}>{row.isDelete ? '启用' : '禁用'}
                                </Button>
                                <Button onClick={() => this.openEditQuota(row)} size="mini" type="text">{'编辑'}</Button>

                            </>)
                    }
                }],
            quotaData: LoanQuotaDefer.quotaDataEmpty,
            quotaQuery: {
                current: 1,
                size: 10
            }
        }
    }

    componentDidMount() {
        this.getQuotaList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.quotaId !== this.props.quotaId && this.props.quotaId) {
            this.getQuotaList()
        }
    }

    async getQuotaList(quotaId = this.props.quotaId) {
        const { body } = await apiV2.getDeadlineList({ quotaId })

        if (body) {
            this.setState({
                quotaData: body
            })
        }
    }

    openEditQuota(quotaUpdate) {
        this.setState({
            quotaUpdate,
            dialogVisible: true,
        })
    }


    changeCurrentQuota(current) {
        const quotaQuery = Object.assign(this.state.quotaQuery, { current })
        this.setState({
            quotaQuery
        }, this.getQuotaList)
    }

    updateFormQuota(name, value, row = this.state.quotaUpdate) {
        const quotaUpdate = Object.assign(row, { [name]: value })
        this.setState({
            quotaUpdate
        })
    }

    async quotaSubmit(data = this.state.quotaUpdate) {
        try {
            data.quotaId = this.props.quotaId
            const { message, success } = await apiV2.saveDeadline({ data })
            if (success) {
                await this.getQuotaList()
                Message.success(message || '修改成功')
            } else {
                Message.error(message)
            }
        } finally {
            this.setState({
                quotaUpdate: LoanQuotaDefer.quotaUpdateEmpty,
                dialogVisible: false
            })
        }
    }

    async quotaSubmitState(row) {
        this.updateFormQuota('isDelete', !row.isDelete, row)
        await this.quotaSubmit(row)
    }

    render() {
        const { quotaColumns, quotaQuery: { current, size }, quotaData, quotaUpdate } = this.state
        return (
            <>
                <Form>
                    <Form.Item>
                        <Button onClick={() => this.openEditQuota(LoanQuotaDefer.quotaUpdateEmpty)} type="primary">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={quotaColumns} data={quotaData} />

                <Pagination total={quotaData.length} current={current} size={size} style={{ display: 'none' }}
                    onCurrentChange={this.changeCurrentQuota.bind(this)} />

                <Dialog
                    title="额度延期"
                    visible={this.state.dialogVisible}
                    onCancel={() => this.setState({ dialogVisible: false })}
                >
                    <Dialog.Body>
                        <Form>
                            <Form.Item label="期限">
                                <Input onChange={this.updateFormQuota.bind(this, 'dayNum')} value={quotaUpdate.dayNum}
                                    placeholder="借款期限（天）" />
                            </Form.Item>
                            <Form.Item label="费率">
                                <Input onChange={this.updateFormQuota.bind(this, 'rate')} value={quotaUpdate.rate}
                                    placeholder="费率（百分比小数）: 0.003表示千分之3" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="danger" onClick={() => this.quotaSubmit()} size="small">{'提交'}</Button>
                            </Form.Item>
                        </Form>
                    </Dialog.Body>
                </Dialog>
            </>)
    }
}