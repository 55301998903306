/*
 * @Author: Your Name you@example.com
 * @Date: 2023-09-25 01:22:46
 * @LastEditors: Your Name you@example.com
 * @LastEditTime: 2023-09-30 18:58:57
 * @FilePath: \credit-loan-admin-repeat\src\containers\member\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { Component } from 'react'
import { Tabs } from 'element-react'

import UList from './m_list'
import RiskBatchProcessing from './risk_batch_processing'
import H5RiskList from './h5_risk_list'

/**
 * 认证配置
 */
export default class AuthConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Tabs activeName="1">
        <Tabs.Pane label="用户管理" name="1">
          <UList />
        </Tabs.Pane>
        <Tabs.Pane label="风控批处理" name="2">
          <RiskBatchProcessing />
        </Tabs.Pane>
        <Tabs.Pane label="H5风控注册列表" name="3">
          <H5RiskList />
        </Tabs.Pane>
      </Tabs>
    )
  }
}
