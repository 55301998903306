import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Tabs } from 'element-react'
import AuthEmergency from './auth_emergency'
import IdCardAuth from './id_card_auth'
import BackCard from './back_card'
import UseEquipment from './use_equipment'


/**
 * 用户信息
 */
export default class UserInfoTabs extends Component {

    static propTypes = {
        userId: PropTypes.number,
    }

    render() {
        return (
            <Tabs type="border-card" activeName="2">
                <Tabs.Pane label="认证信息" name="2">
                    <IdCardAuth userId={this.props.userId} />
                </Tabs.Pane>
                <Tabs.Pane label="银行卡认证" name="3">
                    <BackCard userId={this.props.userId} />
                </Tabs.Pane>
                <Tabs.Pane label="关联设备" name="4">
                    <UseEquipment userId={this.props.userId} />
                </Tabs.Pane>
                <Tabs.Pane label="社交信息" name="1">
                    <AuthEmergency userId={this.props.userId} />
                </Tabs.Pane>
            </Tabs>
        )
    }
}