const TokenName = 'admin_token'

export function saveUserToken(value){
    localStorage.setItem(TokenName,value)
}

export function getUserToken(){
    return localStorage.getItem(TokenName)
}

export function cleanUserToken(){
    localStorage.removeItem(TokenName)
}