import React, { Component } from 'react'

// 已逾期的
import OrderRepayment from '../loan/order_repayment'
import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'

/**
 * 订单
 */
export default class OrderList extends Component {

    static propTypes = {
        queryParam: PropTypes.object,
        exportClick: PropTypes.func,
        rquestListFunction: PropTypes.func,
        readOnly: PropTypes.bool,
    }

    exportMy(param) {
        const propExportClick = this.props.exportClick
        if (propExportClick) {
            propExportClick(param)
        } else {
            apiV2.overdueRepaymentOrderExcel({ isDownload: true, data: param })
        }
    }

    get rquestListFunction() {
        return this.props.rquestListFunction || apiV2.overdueRepaymentOrder
    }

    render() {
        return <OrderRepayment exportClick={(p) => this.exportMy(p)} rquestListFunction={this.rquestListFunction}
            queryParam={this.props.queryParam} overdue fixedRightField="overdue" readOnly={this.props.readOnly} />
    }
}
