import React, { Component } from 'react'

import { Drawer, Alert } from 'antd'
import { DatePicker, Form } from 'element-react'
import { Button } from 'element-react/next'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'


/**
 * 订单减免
 */
export default class OrderReduction extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func,
    }

    static RemissionAmountEmpty = {
        time: 0,
    }
    static ResultEmpty = {}

    constructor(props) {
        super(props)

        this.state = {
            submitLoading: false,
            visible: false,
            addFormData: OrderReduction.RemissionAmountEmpty,
            result: OrderReduction.ResultEmpty,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
        }
    }

    open() {
        this.setState({ visible: true })
    }


    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })
    }

    async onSubmit() {
        this.setState({ submitLoading: true })
        try {
            const { time } = this.state.addFormData
            const orderId = this.props.orderId
            const result = await apiV2.uptOrderRepayTime({ orderDetailId: orderId, time })
            this.setState({ result })
        } finally {
            this.setState({ submitLoading: false })
        }
    }

    close() {
        this.setState({ visible: false, result: OrderReduction.ResultEmpty, })
        this.props.onClose()
    }

    render() {
        const { addFormData, result, visible,submitLoading } = this.state

        return (
            <Drawer title="修改还款时间" placement="right" onClose={() => this.close()} visible={visible}>
                <Form labelPosition="top">
                    <Form.Item label="还款时间">
                        <DatePicker value={addFormData.time} onChange={(e) => this.changeAddForm('time', e)} />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                    </Form.Item>

                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>)
    }
}
