import React, { Component } from 'react'
import { Form, Input, DateRangePicker, Switch, Button, Table, Pagination, Message } from 'element-react'
import { datesAdapterElUI, formatDatePeriod } from '../../utils/format'
import api from '../../api/api-v2'
import Text from '../../components/text'
import EnabkeStatus from '../../components/EnabkeStatus'
import apiV2 from '../../api/api-v2'
import Popconfirm from '../../components/MyPopconfirm'
import Image from '../../components/Image'

// 反馈
export default class ProblemFeedback extends Component {

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                beginTime: null,
                current: 1,
                endTime: null,
                isDispose: false,
                size: 10,
                mobile: null,
                title: null
            },
            columns: [{
                label: '发生时间',
                prop: 'createTime',
                width: 200,
                align: 'center',
                fixed: true,
                render: ({ createTime }) => <Text bold color="blue">{createTime}</Text>
            }, {
                label: '手机号',
                prop: 'mobile',
                align: 'center',

            },{
                label: '照片',
                prop: 'imgUrl',
                align: 'center',
                render: ({ imgUrl }) => <Image src={imgUrl} width="3rem"/>

            }, {
                label: '姓名',
                prop: 'realName',
                align: 'center',

            }, {
                label: '标题',
                prop: 'title',
                align: 'center',

            }, {
                label: '处理完毕',
                prop: 'isDispose',
                width: 140,
                align: 'center',
                render: ({ isDispose }) => <EnabkeStatus enable={isDispose} />
            },
            {
                label: '内容',
                prop: 'content',
                align: 'center',
            }, {
                label: '操作',
                align: 'center',
                fixed: 'right',
                width: 140,
                render: (row) => {
                    return (
                        <Popconfirm onConfirm={this.processingCompleted(row)}>
                            <Button disabled={row.isDispose} type="text">{'处理完毕'}</Button>
                        </Popconfirm>)
                }
            }],
            result: null
        }
    }

    componentDidMount() {
        this.fetchResult()
    }

    // 修改查询表单参数
    editQueryForm(name, resultRefresh) {
        return v => {
            const queryForm = Object.assign(this.state.queryForm, { [name]: v })
            this.setState(queryForm, () => {
                if (resultRefresh) this.fetchResult()
            })
        }
    }

    // 获取数据
    async fetchResult() {
        const { body } = await api.getFeedbackList({ param: this.state.queryForm })
        this.setState({
            result: body
        })
    }

    // 修改查询表单参数-时间区间
    editQueryFormDate(startName, endName) {
        return v => {
            const [startValue, endValue] = formatDatePeriod(v)
            const queryForm = Object.assign(this.state.queryForm, { [startName]: startValue, [endName]: endValue })
            this.setState(queryForm)
        }
    }

    processingCompleted({ feedbackId }) {
        return async () => {
            const { message, success } = await apiV2.uptFeedback({ feedbackId })
            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            this.fetchResult()
        }
    }

    render() {
        const { queryForm, columns, result } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="是否处理">
                        <Switch value={queryForm.isDispose} onChange={this.editQueryForm('isDispose')} />
                    </Form.Item>
                    <Form.Item label="日期">
                        <DateRangePicker value={datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                            onChange={this.editQueryFormDate('beginTime', 'endTime')} />
                    </Form.Item>
                    <Form.Item label="手机号">
                        <Input value={queryForm.mobile} onChange={this.editQueryForm('mobile')} />
                    </Form.Item>
                    <Form.Item label="标题">
                        <Input value={queryForm.title} onChange={this.editQueryForm('title')} />
                    </Form.Item>
                    <Form.Item >
                        <Button onClick={() => this.fetchResult()}>{'查询'}</Button>
                    </Form.Item>
                </Form>
                {result ?
                    <>
                        <Table columns={columns} data={result.records} />
                        <Pagination total={result.total} current={queryForm.current} size={queryForm.size}
                            onCurrentChange={this.editQueryForm('current', true)}
                            onSizeChange={this.editQueryForm('size', true)}
                        />
                    </> : null}

            </>)
    }
}