import React, { Component } from 'react'

import { Drawer, Alert } from 'antd'
import { Form, Input, Select, Tag } from 'element-react'
import { Button } from 'element-react/next'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'


/**
 * 订单减免
 */
export default class OrderReduction extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func,
        isStages: PropTypes.bool
    }

    static RemissionAmountEmpty = {
        orderId: null,
        remissionAmount: 0,
        remissionOverAmount: 0,
        isPartialPayment: false
    }
    static ResultEmpty = {}

    constructor(props) {
        super(props)

        this.state = {
            submitLoading: false,
            visible: false,
            addFormData: OrderReduction.RemissionAmountEmpty,
            result: OrderReduction.ResultEmpty,
            originalAmount: { allRepaymentMoney: 0, agingRepaymentMoney: 0, overMoney: 0 }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
            this.getUnderwayOrderMoney()
        }
    }

    open() {
        this.setState({ visible: true })
    }

    async getUnderwayOrderMoney() {
        const { body } = await apiV2.getUnderwayOrderMoney({ orderId: this.props.orderId })
        this.setState({ originalAmount: body || {} })
    }

    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })
    }

    async onSubmit() {
        this.setState({ submitLoading: true })
        try {
            const data = this.state.addFormData
            data.orderId = this.props.orderId
            const result = await apiV2.derateAmount({ data })
            this.setState({ result })
        } finally {
            this.setState({ submitLoading: false })
        }
    }

    close() {
        this.setState({ visible: false, result: OrderReduction.ResultEmpty, })
        this.props.onClose()
    }

    get topTip() {
        return this.state.addFormData.isPartialPayment ? '部分' : '减免'
    }

    render() {
        const { addFormData, result, visible, originalAmount,submitLoading } = this.state
        const isStages = this.props.isStages

        return (
            <Drawer title="减免还款金额" placement="right" onClose={() => this.close()} visible={visible}>
                <Form labelPosition="top">
                    <Form.Item label="是否部分还款">
                        <Select value={addFormData.isPartialPayment} onChange={(e) => this.changeAddForm('isPartialPayment', e)}>
                            <Select.Option label={'是'} value />
                            <Select.Option label={'否'} value={false} />
                        </Select>
                    </Form.Item>

                    <Form.Item label={this.topTip + '还款金额'}>
                        <Tag type="warning">{`还款金额：${originalAmount.allRepaymentMoney || 0}`}</Tag>

                        <Input value={addFormData.remissionAmount} onChange={(e) => this.changeAddForm('remissionAmount', e)}
                            placeholder="精确到分" />
                    </Form.Item>

                    <Form.Item label={this.topTip + '逾期金额'}>
                        <Tag type="warning">{`逾期金额：${(isStages ? originalAmount.agingRepaymentMoney : originalAmount.overMoney) || 0}`}</Tag>
                        <Input value={addFormData.remissionOverAmount} onChange={(e) => this.changeAddForm('remissionOverAmount', e)}
                            placeholder="精确到分" />
                    </Form.Item>

                    <Form.Item>
                        <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                    </Form.Item>

                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>)
    }
}
