import React, { Component } from 'react'

import { Popover } from 'antd'
import Text from './text'
import PropTypes from 'prop-types'
/**
* 复制粘贴
*/
export default class Paste extends Component {

    static propTypes = {
        content: PropTypes.any,
        rich: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = { visible: false }
    }


    copy() {
        var aux = document.createElement('input')
        aux.setAttribute('value', this.props.content)
        document.body.appendChild(aux)
        aux.select()
        document.execCommand('copy')
        document.body.removeChild(aux)

        this.setState({ visible: true })
    }

    render() {
        return (
            <Popover content="复制成功" trigger="click" visible={this.state.visible}
                onVisibleChange={(visible) => this.setState({ visible })}>
                <Text onClick={() => this.copy()} color="orange" rich={this.props.rich}>
                    <a>{this.props.content}</a>
                </Text>
            </Popover>
        )
    }
}
