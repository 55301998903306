import React, { Component } from 'react'
import PropTypes from 'prop-types'


import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use(
    [GridComponent, LineChart, CanvasRenderer]
)

/**
 * 逾期金额
 */
export default class OverdueAmountChat extends Component {

    static propTypes = {
        data: PropTypes.any,
    }

    componentDidMount() {
        const myChart = echarts.init(document.getElementById('overdue_amount_chat'))
        this.setState({ myChart }, this.setData)
    }

    componentDidUpdate() {
        this.setData()
    }

    setData(data = this.props.data || {}) {
        const { m6, m12, m24 } = data
        this.state.myChart.setOption({
            title: {
                text: '逾期金额'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['近6月', '近12月', '近24月',]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['最低', '最高']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '近6月',
                    type: 'line',
                    stack: 'Total',
                    data: m6
                },
                {
                    name: '近12月',
                    type: 'line',
                    stack: 'Total',
                    data: m12
                },
                {
                    name: '近24月',
                    type: 'line',
                    stack: 'Total',
                    data: m24
                }
            ]
        })
    }

    render() {

        return (
            <>
                <div id="overdue_amount_chat" style={{ width: 400, height: 200 }} />
            </>)
    }
}