import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'
import { Descriptions } from 'antd'
import Condition from '../../components/Condition'
import { Alert, Button, Message } from 'element-react'
import ScoringReport from '../risk/scoring_report'
import BehaviorRadarReport from '../risk/behavior_radar_report'
import CreditChart from '../risk/credit_chart'
import ConfidenceBox from '../risk/confidence_box'
import LoanRepaymentChat from '../risk/loan_repayment_chat'

import Text from '../../components/text'

const radarDataEmpty = { 'currentReportDetail': {}, 'behaviorReportDetail': {}, 'applyReportDetail': {} }
/**
 * 全景雷达
 */
export default class PanoramicRadar extends Component {
    static propTypes = {
        userId: PropTypes.number,
        analysis: PropTypes.any,
        onRefresh: PropTypes.func,
        getReq: PropTypes.func,
        refreshReq: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            radarData: radarDataEmpty,
            showDescriptions: false
        }


    }

    componentDidMount() {
        this.preSet()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.getRiskRadar()
        }

        if (prevProps.analysis !== this.props.analysis) {
            this.preSet()
        }
    }

    preSet() {
        const radarData = this.props.analysis
        if (radarData) this.setState({ radarData })
    }

    cleanData() {
        this.setState({
            radarData: radarDataEmpty
        })
    }

    get creditInstitutionsData() {
        const defaultValue = (v) => parseInt(v) || 0

        const v = this.state.radarData.applyReportDetail || {}
        return [defaultValue(v.a22160008), defaultValue(v.a22160009), defaultValue(v.a22160010)]
    }

    get behaviorRadarReportList() {
        const defaultValue = (v) => v || 0

        const behaviorReportDetail = this.state.radarData.behaviorReportDetail || {}
        const obviousGreenRender = (value) => {
            return <Text size="1.1rem" bold color="green">{value}</Text>
        }

        return [
            {
                name: '贷款笔数',
                m1: defaultValue(behaviorReportDetail.b22170002),
                m3: defaultValue(behaviorReportDetail.b22170003),
                m6: defaultValue(behaviorReportDetail.b22170004),
                m12: defaultValue(behaviorReportDetail.b22170005),
                m24: defaultValue(behaviorReportDetail.b22170006),
            },
            {
                name: '贷款总金额',
                m1: defaultValue(behaviorReportDetail.b22170007),
                m3: defaultValue(behaviorReportDetail.b22170008),
                m6: defaultValue(behaviorReportDetail.b22170009),
                m12: defaultValue(behaviorReportDetail.b221700010),
                m24: defaultValue(behaviorReportDetail.b221700011)
            },
            {
                name: '贷款机构数',
                m1: defaultValue(behaviorReportDetail.b22170016),
                m3: defaultValue(behaviorReportDetail.b22170017),
                m6: defaultValue(behaviorReportDetail.b22170018),
                m12: defaultValue(behaviorReportDetail.b22170019),
                m24: defaultValue(behaviorReportDetail.b22170020)
            },
            {
                name: '履约贷款总金额',
                m1: defaultValue(behaviorReportDetail.b22170040),
                m3: defaultValue(behaviorReportDetail.b22170041),
                m6: defaultValue(behaviorReportDetail.b22170042),
                m12: defaultValue(behaviorReportDetail.b22170043),
                m24: defaultValue(behaviorReportDetail.b22170044),
                overallRender: obviousGreenRender
            },
            {
                name: '履约贷款笔数',
                m1: defaultValue(behaviorReportDetail.b22170045),
                m3: defaultValue(behaviorReportDetail.b22170046),
                m6: defaultValue(behaviorReportDetail.b22170047),
                m12: defaultValue(behaviorReportDetail.b22170048),
                m24: defaultValue(behaviorReportDetail.b22170049)
            },
            {
                name: '失败扣款笔数',
                m1: defaultValue(behaviorReportDetail.b22170036),
                m3: defaultValue(behaviorReportDetail.b22170037),
                m6: defaultValue(behaviorReportDetail.b22170038),
                m12: defaultValue(behaviorReportDetail.b22170039),
                m24: defaultValue(behaviorReportDetail.b221700340)
            }
        ]
    }

    get lendersChat() {
        const defaultValue = (v) => parseInt(v) || 0

        const v = this.state.radarData.behaviorReportDetail || {}
        return {
            auriferous: [defaultValue(v.b22170021), defaultValue(v.b22170022)],
            onlineLoan: [defaultValue(v.b22170023), defaultValue(v.b22170024)]
        }
    }

    get numberOverdueLoans() {
        const defaultValue = (v) => parseInt(v) || 0

        const v = this.state.radarData.behaviorReportDetail || {}
        return {
            m0: [defaultValue(v.b22170025), defaultValue(v.b22170026), defaultValue(v.b22170027)],
            m1: [defaultValue(v.b22170028), defaultValue(v.b22170029), defaultValue(v.b22170030)]
        }
    }

    get overdueAmountChat() {
        const sectionSymbolicAnalysis = (v) => {
            if (!v) return [0, 0]
            return v.replace('[', '').replace(')', '').split(',')
        }

        const v = this.state.radarData.behaviorReportDetail || {}
        return {
            m6: sectionSymbolicAnalysis(v.b22170031),
            m12: sectionSymbolicAnalysis(v.b22170032),
            m24: sectionSymbolicAnalysis(v.b22170033)
        }
    }

    get month12LoanCmount() {
        const defaultValue = (v) => parseInt(v) || 0

        const v = this.state.radarData.behaviorReportDetail || {}
        return [defaultValue(v.b22170012), defaultValue(v.b22170013), defaultValue(v.b22170014), defaultValue(v.b22170015)]
    }

    get confidenceList() {
        const currentReportDetail = this.state.radarData.currentReportDetail || {}
        const behaviorReportDetail = this.state.radarData.behaviorReportDetail || {}

        const defaultValue = (v) => v || 0
        const c22180001 = defaultValue(currentReportDetail.a22160001)
        const c22180002 = defaultValue(currentReportDetail.a22160002)
        const b22170051 = defaultValue(behaviorReportDetail.c22180012)
        const b22170034 = defaultValue(behaviorReportDetail.b22170034).toString().replace('%', '')
        return [
            {
                label: '网贷授信额度',
                value: `${c22180001}分/100分`,
                percentage: c22180001,
            },
            {
                label: '网贷额度置信度',
                value: `${c22180002}分/100分`,
                percentage: c22180002,
            },
            {
                label: '贷款行为置信度',
                value: `${b22170051}分/100分`,
                percentage: b22170051,
            },
            {
                label: '正常还款订单数占贷款总订单数比例',
                value: `${b22170034}分/100分`,
                percentage: b22170034,
            }
        ]
    }

    get scoringReportList() {
        const applyReportDetail = this.state.radarData.applyReportDetail || {}
        const currentReportDetail = this.state.radarData.currentReportDetail || {}
        const behaviorReportDetail = this.state.radarData.behaviorReportDetail || {}


        const defaultValue = (v) => v || 0
        const a22160001 = defaultValue(applyReportDetail.a22160001)
        const a22160002 = defaultValue(applyReportDetail.a22160002)
        const c22180012 = defaultValue(currentReportDetail.c22180012)
        const b22170001 = defaultValue(behaviorReportDetail.b22170001)

        return [
            {
                label: '申请准入分',
                value: `${a22160001}分/1000分`,
                percentage: a22160001 / 10,
            },
            {
                label: '申请置信分',
                value: `${a22160002}分/100分`,
                percentage: a22160002,
            },
            {
                label: '消费额度置信分',
                value: `${c22180012}分/100分`,
                percentage: c22180012,
            },
            {
                label: '贷款行为分',
                value: `${b22170001}分/1000分`,
                percentage: b22170001 / 10,
            }
        ]
    }

    async getRiskRadar(userId = this.props.userId, hasRefresh) {
        if (userId) {
            const { body } = await (this.props.getReq || apiV2.getRiskRadar)({ userId })
            if (body) {
                this.setState({
                    radarData: body
                })
            } else {
                this.cleanData()
            }
        } else {
            if (!this.props.analysis) this.cleanData()
        }

        const { refreshReq, onRefresh } = this.props

        if (onRefresh) {
            onRefresh()
        } else if (hasRefresh) {
            const refresh = this.state.radarData.behaviorReportDetail ? true : false
            const { message, success } = await (refreshReq || apiV2.getLeidaByUserId)({ userId, refresh })
            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }
            this.getRiskRadar()
        }
    }

    get topAlertText() {
        const applyReportDetail = this.state.radarData.applyReportDetail || {}
        const behaviorReportDetail = this.state.radarData.behaviorReportDetail || {}

        return [`最近一次查询时间:${applyReportDetail.a22160007 || '无'}`,
        `最近一次贷款放款时间:${behaviorReportDetail.b22170054 || '无'}`,
        `信用贷款时长:${behaviorReportDetail.b22170053 || 0}天`].join()
    }

    render() {
        let { radarData: { currentReportDetail: behaviorDetail, behaviorReportDetail: applyDetail,
            applyReportDetail: currentDetail } } = this.state


        currentDetail = currentDetail || {}
        behaviorDetail = behaviorDetail || {}
        applyDetail = applyDetail || {}

        return (
            <>
                <Alert title={this.topAlertText} type="success" />
                <CreditChart creditInstitutions={this.creditInstitutionsData} report={this.state.radarData} />

                <Condition visible={!currentDetail.a22160001}>
                    <Button type="warning" onClick={() => this.getRiskRadar(this.props.userId, true)}>{'点击获取雷达数据'}</Button>
                </Condition>

                {this.props.refreshReq ? null : <Button type="success" onClick={() => this.getRiskRadar(this.props.userId, true)}>{'刷新雷达数据'}</Button>
                }

                <ScoringReport data={this.scoringReportList} />
                <BehaviorRadarReport data={this.behaviorRadarReportList} />
                <ConfidenceBox data={this.confidenceList} />

                <LoanRepaymentChat month12LoanCmountData={this.month12LoanCmount} lendersData={this.lendersChat}
                    numberOverdueLoansData={this.numberOverdueLoans}
                    overdueAmountData={this.overdueAmountChat} />

                <Condition visible={this.state.showDescriptions}>
                    <Descriptions title="信用详情" bordered>
                        <Descriptions.Item label="申请准入分">{currentDetail.a22160001}</Descriptions.Item>
                        <Descriptions.Item label="申请准入置信度">{currentDetail.a22160002}</Descriptions.Item>
                        <Descriptions.Item label="申请命中机构数">{currentDetail.a22160003}</Descriptions.Item>
                        <Descriptions.Item label="申请命中消金类机构数">{currentDetail.a22160004}</Descriptions.Item>
                        <Descriptions.Item label="申请命中网络贷款类机构数">{currentDetail.a22160005}</Descriptions.Item>
                        <Descriptions.Item label="机构总查询次数">{currentDetail.a22160006}</Descriptions.Item>
                        <Descriptions.Item label="最近一次查询时间">{currentDetail.a22160007}</Descriptions.Item>
                        <Descriptions.Item label="近1个月机构总查询笔数">{currentDetail.a22160008}</Descriptions.Item>
                        <Descriptions.Item label="近3个月机构总查询笔数">{currentDetail.a22160009}</Descriptions.Item>
                        <Descriptions.Item label="近6个月机构总查询笔数">{currentDetail.a22160010}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions title="申请行为详情" bordered>
                        <Descriptions.Item label="网贷授信额度">{behaviorDetail.c22180001}</Descriptions.Item>
                        <Descriptions.Item label="网贷额度置信度">{behaviorDetail.c22180002}</Descriptions.Item>
                        <Descriptions.Item label="网络贷款类机构数">{behaviorDetail.c22180003}</Descriptions.Item>
                        <Descriptions.Item label="网络贷款类产品数">{behaviorDetail.c22180004}</Descriptions.Item>
                        <Descriptions.Item label="网络贷款机构最大授信额度">{behaviorDetail.c22180005}</Descriptions.Item>
                        <Descriptions.Item label="网络贷款机构平均授信额度">{behaviorDetail.c22180006}</Descriptions.Item>
                        <Descriptions.Item label="消金贷款类机构数">{behaviorDetail.c22180007}</Descriptions.Item>
                        <Descriptions.Item label="消金贷款类产品数">{behaviorDetail.c22180008}</Descriptions.Item>
                        <Descriptions.Item label="消金贷款类机构最大授信额度">{behaviorDetail.c22180009}</Descriptions.Item>
                        <Descriptions.Item label="消金贷款类机构平均授信额度">{behaviorDetail.c22180010}</Descriptions.Item>
                        <Descriptions.Item label="消金建议授信额度">{behaviorDetail.c22180011}</Descriptions.Item>
                        <Descriptions.Item label="消金额度置信度">{behaviorDetail.c22180012}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions title="放款还款详情" bordered>
                        <Descriptions.Item label="贷款行为分">{applyDetail.b22170001}</Descriptions.Item>
                        <Descriptions.Item label="近1个月贷款笔数">{applyDetail.b22170002}</Descriptions.Item>
                        <Descriptions.Item label="近3个月贷款笔数">{applyDetail.b22170003}</Descriptions.Item>
                        <Descriptions.Item label="近6个月贷款笔数">{applyDetail.b22170004}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款笔数">{applyDetail.b22170005}</Descriptions.Item>
                        <Descriptions.Item label="近24个月贷款笔数">{applyDetail.b22170006}</Descriptions.Item>
                        <Descriptions.Item label="近1个月贷款总金额">{applyDetail.b22170007}</Descriptions.Item>
                        <Descriptions.Item label="近3个月贷款总金额">{applyDetail.b22170008}</Descriptions.Item>
                        <Descriptions.Item label="近6个月贷款总金额">{applyDetail.b22170009}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款总金额">{applyDetail.b22170010}</Descriptions.Item>
                        <Descriptions.Item label="近24个月贷款总金额">{applyDetail.b22170011}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款金额在1k及以下的笔数">{applyDetail.b22170012}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款金额在1k-3k的笔数">{applyDetail.b22170013}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款金额在3k-10k的笔数">{applyDetail.b22170014}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款金额在1w以上的笔数">{applyDetail.b22170015}</Descriptions.Item>
                        <Descriptions.Item label="近1个月贷款机构数">{applyDetail.b22170016}</Descriptions.Item>
                        <Descriptions.Item label="近3个月贷款机构数">{applyDetail.b22170017}</Descriptions.Item>
                        <Descriptions.Item label="近6个月贷款机构数">{applyDetail.b22170018}</Descriptions.Item>
                        <Descriptions.Item label="近12个月贷款机构数">{applyDetail.b22170019}</Descriptions.Item>
                        <Descriptions.Item label="近24个月贷款机构数">{applyDetail.b22170020}</Descriptions.Item>
                        <Descriptions.Item label="近12个月消金类贷款机构数">{applyDetail.b22170021}</Descriptions.Item>
                        <Descriptions.Item label="近24个月消金类贷款机构数">{applyDetail.b22170022}</Descriptions.Item>
                        <Descriptions.Item label="近12个月网贷类贷款机构数">{applyDetail.b22170023}</Descriptions.Item>
                        <Descriptions.Item label="近24个月网贷类贷款机构数">{applyDetail.b22170024}</Descriptions.Item>
                        <Descriptions.Item label="近6个月M0+逾期贷款笔数">{applyDetail.b22170025}</Descriptions.Item>
                        <Descriptions.Item label="近12个月M0+逾期贷款笔数">{applyDetail.b22170026}</Descriptions.Item>
                        <Descriptions.Item label="近24个月M0+逾期贷款笔数">{applyDetail.b22170027}</Descriptions.Item>
                        <Descriptions.Item label="近6个月M1+逾期贷款笔数">{applyDetail.b22170028}</Descriptions.Item>
                        <Descriptions.Item label="近12个月M1+逾期贷款笔数">{applyDetail.b22170029}</Descriptions.Item>
                        <Descriptions.Item label="近24个月M1+逾期贷款笔数">{applyDetail.b22170030}</Descriptions.Item>
                        <Descriptions.Item label="近6个月累计逾期金额">{applyDetail.b22170031}</Descriptions.Item>
                        <Descriptions.Item label="近12个月累计逾期金额">{applyDetail.b22170032}</Descriptions.Item>
                        <Descriptions.Item label="近24个月累计逾期金额">{applyDetail.b22170033}</Descriptions.Item>
                        <Descriptions.Item label="正常还款订单数占贷款总订单数比例">{applyDetail.b22170034}</Descriptions.Item>
                        <Descriptions.Item label="近1个月失败扣款笔数">{applyDetail.b22170035}</Descriptions.Item>
                        <Descriptions.Item label="近3个月失败扣款笔数">{applyDetail.b22170036}</Descriptions.Item>
                        <Descriptions.Item label="近6个月失败扣款笔数">{applyDetail.b22170037}</Descriptions.Item>
                        <Descriptions.Item label="近12个月失败扣款笔数">{applyDetail.b22170038}</Descriptions.Item>
                        <Descriptions.Item label="近24个月失败扣款笔数">{applyDetail.b22170039}</Descriptions.Item>
                        <Descriptions.Item label="近1个月履约贷款总金额">{applyDetail.b22170040}</Descriptions.Item>
                        <Descriptions.Item label="近3个月履约贷款总金额">{applyDetail.b22170041}</Descriptions.Item>
                        <Descriptions.Item label="近6个月履约贷款总金额">{applyDetail.b22170042}</Descriptions.Item>
                        <Descriptions.Item label="近12个月履约贷款总金额">{applyDetail.b22170043}</Descriptions.Item>
                        <Descriptions.Item label="近24个月履约贷款总金额">{applyDetail.b22170044}</Descriptions.Item>
                        <Descriptions.Item label="近1个月履约贷款次数">{applyDetail.b22170045}</Descriptions.Item>
                        <Descriptions.Item label="近3个月履约贷款次数">{applyDetail.b22170046}</Descriptions.Item>
                        <Descriptions.Item label="近6个月履约贷款次数">{applyDetail.b22170047}</Descriptions.Item>
                        <Descriptions.Item label="近12个月履约贷款次数">{applyDetail.b22170048}</Descriptions.Item>
                        <Descriptions.Item label="近24个月履约贷款次数">{applyDetail.b22170049}</Descriptions.Item>
                        <Descriptions.Item label="最近一次履约距今天数">{applyDetail.b22170050}</Descriptions.Item>
                        <Descriptions.Item label="贷款行为置信度">{applyDetail.b22170051}</Descriptions.Item>
                        <Descriptions.Item label="贷款已结清订单数">{applyDetail.b22170052}</Descriptions.Item>
                        <Descriptions.Item label="信用贷款时长">{applyDetail.b22170053}</Descriptions.Item>
                        <Descriptions.Item label="最近一次贷款放款时间">{applyDetail.b22170054}</Descriptions.Item>

                    </Descriptions>
                </Condition>
            </>)
    }
}