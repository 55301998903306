import { Table } from 'element-react'
import React, { Component } from 'react'
import CardHead from '../../components/card_head'
import { columnRender } from '../../utils/app'
import PropTypes from 'prop-types'

/**
 * 行为雷达报告
 */
export default class BehaviorRadarReport extends Component {

    static propTypes = {
        data: PropTypes.array,
    }

    constructor(props) {
        super(props)

        this.state = {
            columns: [
                {
                    label: '详情',
                    prop: 'name',
                    align: 'center',
                    render: row => columnRender(row, 'name')
                }, {
                    label: '近1月',
                    prop: 'm1',
                    align: 'center',
                    render: row => columnRender(row, 'm1')
                }, {
                    label: '近3月',
                    prop: 'm3',
                    align: 'center',
                    render: row => columnRender(row, 'm3')
                }, {
                    label: '近6月',
                    prop: 'm6',
                    align: 'center',
                    render: row => columnRender(row, 'm6')
                }, {
                    label: '近12月',
                    prop: 'm12',
                    align: 'center',
                    render: row => columnRender(row, 'm12')
                }, {
                    label: '近24月',
                    prop: 'm24',
                    align: 'center',
                    render: row => columnRender(row, 'm24')
                },
            ],
        }
    }

    render() {
        return (
            <CardHead title="行为雷达报告">
                <Table stripe border columns={this.state.columns} data={this.props.data} />
            </CardHead>
        )
    }
}