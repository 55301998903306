import React, { Component } from 'react';
import ManageUserSelect from '../../../components/ManageUserSelect';
import PropTypes from 'prop-types'
import { Alert, Button, Dialog } from 'element-react';

export default class AllocationManageForm extends Component {

    static propTypes = {
        onRef: PropTypes.func,
        onSubmit: PropTypes.func,
        onSuccess: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            dialogVisible: false,
            manageId: null,
            loading: false,
            submitError: null
        };
    }

    open() {
        this.setState({ dialogVisible: true });
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    async submit() {
        this.setState({ loading: true });
        try {
            const resp = await this.props.onSubmit(this.state.manageId);
            if (resp.success) {
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
                this.close();
            } else {
                this.setState({ submitError: resp });
            }
        } catch (err) {
            this.setState({ submitError: err });
        } finally {
            this.close();
        }
    }

    close() {
        this.setState({ dialogVisible: false, loading: false });
        if (this.props.onSuccess) {
            this.props.onSuccess();
        }
    }

    render() {
        const { submitError } = this.state;

        return (
            <div>
                <Dialog
                    title="分配管理员"
                    size="tiny"
                    visible={this.state.dialogVisible}
                    onCancel={() => this.close()}
                    lockScroll={false}
                >
                    <Dialog.Body>
                        <ManageUserSelect value={this.state.manageId} onChange={manageId => this.setState({ manageId })} />
                    </Dialog.Body>
                    <Dialog.Footer className="dialog-footer">
                        <Button onClick={() => this.close()}>取消</Button>
                        <Button disabled={!this.state.manageId || this.state.loading}
                            type="primary" onClick={() => this.submit()}>确定</Button>
                        {submitError ? <Alert style={{ margin: '1em' }} title={submitError.message} type="error" /> : null}
                    </Dialog.Footer>
                </Dialog>
            </div>
        )
    }

}