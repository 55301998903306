import { Button, DateRangePicker, Form, Select, Card, Layout, Table } from 'element-react'
import React, { Component } from 'react'
import { Statistic } from 'antd'
import { CostTypes } from './types'
import Space from '../../components/space'
import Pagination from '../../components/MyPagination'
import Money from '../../components/Money'
import { formatDatePeriod, datesAdapterElUI } from '../../utils/format'
import apiV2 from '../../api/api-v2'

/**
 * 消费记录
 */
export default class Bills extends Component {

    constructor(props) {
        super(props)

        this.state = {
            body: {},
            queryFormData: {
                type: null,
                beginTime: null,
                endTime: null,
                current: 1,
                size: 10
            },
            result: {},
            billColumns: [
                {
                    label: '#',
                    prop: 'sysAmountId',
                    width: '80',
                    fixed: true,
                    align: 'center',
                },
                {
                    label: '账单时间',
                    prop: 'createTime',
                    align: 'center',
                },
                {
                    label: '所属关联信息',
                    prop: 'relevanceData',
                    align: 'center',

                },
                {
                    label: '金额类型名称',
                    prop: 'typeName',
                    align: 'center',
                },
                {
                    label: '金额',
                    prop: 'money',
                    width: '120',
                    fixed: 'right',
                    align: 'center',
                    render: ({ money }) => <Money value={money} />
                }
            ],
            statistics: []
        }
    }

    componentDidMount() {
        this.serverFetchList()
    }

    async getPlatformStatistics() {
        const { body } = await apiV2.getPlatformStatistics({ data: this.state.queryFormData })
        this.setState({ statistics: body || [] })
    }

    async serverFetchList() {
        const { body } = await apiV2.getMerchantBills({ data: this.state.queryFormData })
        this.setState({ result: body || {} })
        await this.getPlatformStatistics()
    }

    changeQueryForm(name, value, queryLoad) {
        const queryFormData = Object.assign(this.state.queryFormData, { [name]: value })
        this.setState({ queryFormData }, () => { if (queryLoad) this.serverFetchList() })
    }

    changeQueryFormDate(dateArr, startName, endName) {
        const [startValue, endValue] = formatDatePeriod(dateArr)
        const queryFormData = Object.assign(this.state.queryFormData, { [startName]: startValue, [endName]: endValue })
        this.setState({ queryFormData })
    }


    render() {

        const { result, billColumns, queryFormData, statistics } = this.state

        return (
            <>
                <Card>
                    <Layout.Row gutter="5" justify="space-between" type="flex">
                        {statistics.map(({ typeName, sumMoney }) => {
                            return (
                                <Layout.Col key={typeName} span="3">
                                    <Statistic title={typeName} value={sumMoney} />
                                </Layout.Col>
                            )
                        })}
                    </Layout.Row>
                </Card>
                <Space>
                    <Form inline>
                        <Form.Item label="金额类型">
                            <Select value={queryFormData.type} onChange={e => this.changeQueryForm('type', e)} clearable>
                                {CostTypes.map(({ key, label }) => <Select.Option value={key} key={label} label={label} />)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="产生日期">
                            <DateRangePicker value={datesAdapterElUI(queryFormData.beginTime, queryFormData.endTime)}
                                onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={() => this.serverFetchList()} type="primary">{'搜索'}</Button>
                        </Form.Item>
                    </Form>
                </Space>

                <Table columns={billColumns} data={result.records} />

                <Pagination total={result.total} pageNumber={queryFormData.current} pageSize={queryFormData.size}
                    onCurrentChange={e => this.changeQueryForm('current', e, true)}
                    onSizeChange={e => this.changeQueryForm('size', e, true)} />
            </>)
    }
}
