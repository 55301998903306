import React, { Component } from 'react';

import apiV2 from '../../../api/api-v2';
import DcCategorize from './dc_categorize';

/**
 * 贷超类目
 */
export default class Classify extends Component {

    render() {
        return (<DcCategorize onSaveReq={apiV2.saveClassifyData} onListReq={apiV2.getClassifyData} />);
    }
}