import React, { Component } from "react";

// 已逾期的
import OrderRepayment from "./order_repayment";
import PropTypes from "prop-types";
import apiV2 from "../../api/api-v2";

/**
 * 订单
 */
export default class OrderList extends Component {
  static propTypes = {
    queryParam: PropTypes.object,
    exportClick: PropTypes.func,
    rquestListFunction: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  exportMy(param) {
    const propExportClick = this.props.exportClick;
    if (propExportClick) {
      propExportClick(param);
    } else {
      apiV2.loanOverdueExportExcel({ isDownload: true, data: param });
    }
  }

  render() {
    return (
      <OrderRepayment
        exportClick={(p) => this.exportMy(p)}
        rquestListFunction={this.props.rquestListFunction}
        {...this.props}
        queryParam={this.props.queryParam}
        overdue
        fixedRightField="overdue"
        readOnly={this.props.readOnly}
      />
    );
  }
}
