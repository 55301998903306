import React, { Component } from 'react';

import apiV2 from '../../../api/api-v2';
import ProductCategorizeFrom from './product_categorize_from';

/**
 * 贷超
 */
export default class ProductClassifyFrom extends Component {

    render() {
        return <ProductCategorizeFrom  {...this.props}
            onSaveReq={apiV2.saveDcClassifyAss} onListReq={apiV2.getDcClassifyAss}
            onClassifyReq={apiV2.getDcClassifyAll} />
    }
}