import React, { Component } from 'react'

import OrderDuaTomorrowReadOnly from '../loan/order_due_tomorrow'

/**
 * 只读
 */
export default class OrderList extends Component {

    render() {

        return (
            <OrderDuaTomorrowReadOnly readOnly />
        )
    }
}