import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'element-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { tabAdd, goHomePage } from '@redux/actions'
import { selectRolemenusV2, selectRolemenus, selectRolemenusV2Parse } from '../containers/user/action'
import apiV2 from '../api/api-v2'
import { ThemeConfig } from '../utils/format'

// import { SIDE_BAR_TEXT } from '@meta/sidebarText'
// import { CHILD_ROUTES } from '../routes/childRoutes'
class Sidebar extends Component {
  static propTypes = {
    defaultActive: PropTypes.string,
    routerList: PropTypes.array,
    router: PropTypes.object,
    history: PropTypes.any.isRequired,
    tabAdd: PropTypes.func.isRequired,
    selectRolemenusV2: PropTypes.func.isRequired,
    selectRolemenus: PropTypes.func.isRequired,
    goHomePage: PropTypes.func.isRequired,
    goPage: PropTypes.any
  }
  constructor(props) {
    super(props)
    this.state = {
      routerList: [],
      defaultActive: '1'
    }
  }

  componentDidMount() {
    this.fetchLoginManagementSystemMenu()
    this.props.selectRolemenus('any')
    console.log(this.props)
  }
  async fetchLoginManagementSystemMenu() {
    const data = await apiV2.getLoginManagementSystemMenu()
    if (data.success) {
      // Data parse
      const rawData = data.body
      const routerList = selectRolemenusV2Parse(rawData)
      this.setState({
        routerList
      })


      if (this.props.goPage) {
        goHomePage(routerList, this.props.history)
      }
    }
  }
  handleClick = v => {
    this.props.tabAdd(v)
  }
  recursion = arr => {
    const menuStyle = {}
    if (ThemeConfig.primaryColorWeigt) {
      // menuStyle.backgroundColor = ThemeConfig.primaryColorWeigt
    }

    const time = +new Date()
    const menu = arr.map((item) => {
      if (!item.hideInMenu) {
        if (item.children && item.children.length) {
          return (
            <Menu.SubMenu style={menuStyle}
              index={item.path}
              key={item.name}
              title={
                <span>
                  {item.name}
                </span>
              }
            >
              <div>{this.recursion(item.children)}</div>
            </Menu.SubMenu>
          )
        } else if (!item.hideInMenu) {
          return (
            <Link to={item.path + '?' + time} key={item.name + time} onClick={() => this.handleClick(item)}>
              <Menu.Item index={item.path}>
                {item.name}
              </Menu.Item>
            </Link>
          )
        }
      }
      return true
    })
    return menu
  }
  render() {
    const { defaultActive, routerList } = this.state

    const menuStyle = { width: 230 }
    if (ThemeConfig.primaryColorLight) {
      menuStyle.backgroundColor = ThemeConfig.primaryColorLight
    }

    return (
      <Menu theme="dark" style={menuStyle} defaultActive={defaultActive}>
        {this.recursion(routerList)}
        <div style={{ height: 120 }} />
      </Menu>
    )
  }
}

const mapStateToProps = state => {
  const { router } = state
  return { router }
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ tabAdd, goHomePage, selectRolemenusV2, selectRolemenus }, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)