import api from '@api/index'
import { MessageBox, Message } from 'element-react'
import { btnRequestPosts, btnReceivePosts, btnFailurePosts } from '@redux/actions'
import * as type from '@redux/actionTypes'
import apiV2 from '../../api/api-v2'
import { saveUserToken, cleanUserToken } from '../../api/user'

// 存储用户信息
export const saveUser = (form, data) => ({
  type: type.SAVE_USER,
  form: form, // 提交的表单信息
  data: data // 登录返回的数据
})
// 清除用户信息
export const clearUser = () => ({
  type: type.CLEAR_USER
})


// 登录(用户名-密码)
export const managelogin = (form, fn) => {
  return async dispatch => {
    dispatch(btnRequestPosts())

    let data

    switch (form.mode) {
      case 'VERIFYCODE': {
        data = await apiV2.phoneVerificationCodeLogin(form.password, form.adminName)
        break
      }
      case 'PASSWORD': {
        data = await apiV2.loginByPassword({
          mobile: form.adminName,
          password: form.password
        })
        break
      }

      default: {
        data = { message: '未知的登录方式' }
      }
    }



    //const data = await api.manageloginApi(form)
    if (data.success) {
      dispatch(saveUser(form, data.body))
      saveUserToken(data.body)
      // fn.push('/home')
      // setTimeout(() => {
      dispatch(btnReceivePosts(data.message + '【登录】'))

      fn.push({ pathname: '/home', state: { go: true } })
      //dispatch(selectRolemenusV2())
      dispatch(selectRolemenus('admin'))
      // }, 1000)
    } else {
      dispatch(btnFailurePosts(data.message + '【登录】'))
    }
  }
}
// 退出(用户名-密码)
export const logout = (fn) => {
  return dispatch => {
    MessageBox.confirm('退出登录, 是否继续?', '提示', {
      type: 'warning'
    }).then(async () => {
      const data = { success: true, msg: '成功' }//await api.logoutApi()
      if (data.success) {
        dispatch(clearUser())
        Message.success(data.msg)

        apiV2.logout({}).catch(err => console.warn(err))
        cleanUserToken()


        fn.push('/login')
        window.sessionStorage.clear()
        window.sessionStorage.removeItem('defaultRouter')
        window.sessionStorage.removeItem('routerArr')
      } else {
        Message.error(data.msg)
      }
      console.log(data)
    }).catch(() => {
      Message.info('已取消退出')
    })
  }
}
const saveDefaultRouter = data => ({
  type: type.SAVE_DEFAULT_ROUTER,
  data
})
export const selectRolemenus = name => {
  return async dispatch => {
    const data = await api.selectRolemenusApi({ adminName: name })
    if (data.success) {
      dispatch(saveDefaultRouter(data.data))
    } else {
      Message.error(data.msg)
    }
  }
}

export const selectRolemenusV2 = () => {
  return async dispatch => {
    const data = await apiV2.getLoginManagementSystemMenu()
    if (data.success) {
      // Data parse
      const rawData = data.body
      dispatch(saveDefaultRouter(selectRolemenusV2Parse(rawData)))
    } else {
      Message.error(data.message)
    }
  }

}

export function selectRolemenusV2Parse(list, path = '') {
  const newData = []

  for (const item of list) {
    const { permName, permPath, permIcon, type: mType } = item
    const top = {
      exact: true, id: item.id, name: permName,
      path: mType === 2 ? permPath : path + permPath,
      text: permName, icon: permIcon
    }
    top.children = selectRolemenusV2Parse(item.childNodes, permPath)
    newData.push(top)
  }

  return newData
}