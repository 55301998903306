import React, { Component } from 'react'

import { Checkbox, Switch } from 'element-react'
import PropTypes from 'prop-types'


/**
 * 可以禁用的开关
 */
export default class SwitchesThatCanDisabled extends Component {

    static propTypes = {
        check: PropTypes.bool,
        value: PropTypes.bool,
        onChangeCheck: PropTypes.func,
        onChangeValue: PropTypes.func,
        onText: PropTypes.string,
        offText: PropTypes.string
    }

    constructor(props) {
        super(props)

        this.state = { checked: props.check || props.value || false }
    }

    changeCheck(checked) {
        this.setState({ checked }, () => this.changeValue(checked))
        const onChangeCheck = this.props.onChangeCheck
        if (onChangeCheck) onChangeCheck(checked)
    }

    changeValue(e) {
        const onChangeValue = this.props.onChangeValue
        if (this.state.checked) {
            if (onChangeValue) onChangeValue(e || false)
        } else {
            if (onChangeValue) onChangeValue(null)
        }
    }

    render() {
        const { value } = this.props

        return (
            <>
                <Checkbox checked={this.state.checked} onChange={(e) => this.changeCheck(e)} />
                <Switch value={value} onChange={(e) => this.changeValue(e)}
                    onText={this.props.onText} offText={this.props.offText} />
            </>
        )
    }
}
