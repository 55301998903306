import React, { Component } from 'react'

import { Button, Form, Input, Pagination, Select, Table, Tag, Message } from 'element-react'
import apiV2 from '../../api/api-v2'
import Condition from '../../components/Condition'
import { Popconfirm, Drawer } from 'antd'

/**
 * 黑名单管理
 */
export default class UserBlacklist extends Component {

    /**
     * 搜索类型
     */
    static SearchType = [
        { label: '身份证号', value: '1', color: 'primary', },
        //{ label: '客户姓名', value: '3',color: 'warning', },
        { label: 'IP地址', value: '4', color: 'warning', },
        { label: '行政区域（x省x市x县）', value: '3', color: 'danger', },
        { label: '手机号码', value: '2', color: 'success', }
    ]

    static ListResultEmpty = { records: [], total: 0 }
    static AddFormEmpty = { type: '', num: '', blackId: null }

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            addForm: UserBlacklist.AddFormEmpty,
            queryForm: {
                type: '',
                num: '',
                size: 10,
                current: 1
            },
            listResult: UserBlacklist.ListResultEmpty,
            listResultColumns: [
                {
                    label: '#',
                    prop: 'blackId',
                    width: '80',
                    fixed: true
                },
                {
                    label: '内容',
                    prop: 'num',
                },
                {
                    label: '类型',
                    prop: 'type',
                    render: ({ type }) => {
                        const that = UserBlacklist.SearchType.find(({ value }) => type.toString() === value) || { color: 'gray', text: '未知' }
                        return (<Tag type={that.color}>{that.label}</Tag>)
                    }
                },
                {
                    label: '添加时间',
                    prop: 'createTime',
                },
                {
                    label: '操作',
                    prop: 'op',
                    width: '120',
                    fixed: 'right',
                    render: (row) => {
                        return (
                            <>
                                <Popconfirm
                                    title="确定要删除吗？"
                                    onConfirm={() => this.delOne(row)}
                                    okText="确定删除"
                                    cancelText="不删除"
                                >
                                    <Button type="danger" size="mini">{'删除'}</Button>
                                </Popconfirm>
                            </>)
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.getBlackList()
    }

    changeQueryForm(name, value, listRefresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })

        if (name === 'type') queryForm.num = ''

        this.setState({ queryForm }, () => {
            if (listRefresh) this.getBlackList()
        })
    }

    changeAddForm(name, value) {
        const addForm = Object.assign(this.state.addForm, { [name]: value })
        this.setState({ addForm })
    }


    async getBlackList() {
        const { body } = await apiV2.getBlackList({ data: this.state.queryForm })
        this.setState({ listResult: body || UserBlacklist.ListResultEmpty })
    }

    async delOne({ blackId: id }) {
        const { success, message } = await apiV2.deleteBlackList({ id })

        if (success) {
            Message.success(message)
            await this.getBlackList()
        } else {
            Message.error(message)
        }
    }

    async addBlackList() {
        const { success, message } = await apiV2.addBlackList({ data: this.state.addForm })
        if (success) {
            Message.success(message)
            await this.getBlackList()
        } else {
            Message.error(message)
        }
    }

    onClose() {
        this.setState({
            visible: false
        })
    }

    openAddForm() {
        this.setState({
            visible: true
        })
    }

    

    render() {
        const { listResult, listResultColumns, queryForm: { type, num, current, size }, visible } = this.state
        return (
            <>
                <Form inline>
                    <Form.Item label="搜索类型">
                        <Select value={type} onChange={(value) => this.changeQueryForm('type', value)} clearable>
                            {UserBlacklist.SearchType.map(({ label, value }) => {
                                return <Select.Option key={value} label={label} value={value} />
                            })}
                        </Select>
                    </Form.Item>

                    <Condition visible={this.state.queryForm.type} tag="span">
                        <Form.Item label="内容">
                            <Input value={num} onChange={(value) => this.changeQueryForm('num', value)} placeholder="请输入内容" />
                        </Form.Item>
                    </Condition>

                    <Form.Item>
                        <Button onClick={() => this.getBlackList()} type="primary">{'搜索'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.openAddForm()} type="info">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table border data={listResult.records} columns={listResultColumns} />

                <Pagination total={listResult.total} current={current} size={size}
                    onSizeChange={(value) => this.changeQueryForm('size', value, true)}
                    onCurrentChange={(value) => this.changeQueryForm('current', value, true)} />

                <Drawer title="添加黑名单列表" placement="right" onClose={() => this.onClose()} visible={visible}>
                    <Form >
                        <Form.Item label="数据类型">
                            <Select value={this.state.addForm.type} onChange={(value) => this.changeAddForm('type', value)}>
                                {UserBlacklist.SearchType.map(({ label, value }) => {
                                    return <Select.Option key={value} label={label} value={value} />
                                })}
                            </Select>
                        </Form.Item>

                        <Condition visible={this.state.addForm.type}>
                            <Form.Item label="数据内容">
                                <Input value={this.state.addForm.num} onChange={(value) => this.changeAddForm('num', value)} placeholder="请输入内容" />
                            </Form.Item>
                        </Condition>

                        <Form.Item>
                            <Button onClick={() => this.addBlackList()} type="primary">{'提交'}</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>
        )
    }
}
