import React, { Component } from 'react'

import { Dialog, Table, Tabs, Tag, Collapse, Progress, Loading, Form, Input, Button, Message } from 'element-react'
import PropTypes from 'prop-types'
import apiV2 from '../../api/api-v2'
import MyPagination from '@components/MyPagination'

import SText from '../../components/SText'
import { Descriptions, Badge, Statistic } from 'antd'

import { formatFileSize, durationFormat } from '../../utils/format'
import RiskManagement from './risk_management'
import CallStaticList from './telecom_operator/call_static_list'
import UserSmsList from '../sms/user_sms_list'
import UserInfoTabs from './user_info_tabs'
import UserAppList from './components/user_app_list'

import MobileFriendList from '../risk/coms/mobile-friend-list'


const hideStyle = {}

const reportEmpty = {
    smses: [],
    recharges: [],
    calls: [],
    nets: [],
    'carrier_user_info': {},
    bills: [],
    packages: [],
    families: []
}

const analysisEmpty = {
    'user_basic': [],
    'friend_circle': {},
    'call_risk_analysis': [],
    'cell_behavior': [],
    'consumption_detail': [],
    'basic_check_items': [],
    'contact_region': [],
    'call_duration_detail': [],
    'call_time_detail': [],
    'trip_info': [],
    'main_service': [],
    'active_degree': [],
    'call_service_analysis': [],
    'cell_phone': [],
    'roam_detail': [],
    'sms_contact_detail': [],
    'call_family_detail': [],
    'call_contact_detail': [],
    'roam_analysis': [],
    report: [],
    'behavior_check': []
}

/**
 * 运营商详情
 */
export default class OperatorInfo extends Component {

    static propTypes = {
        userId: PropTypes.number,
        onClose: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            syncMobileBookNumLoad: false,
            addressBookParam: { phone: '', name: '' },
            dialogVisible: false,
            clsoe: false,
            tabsActiveName: 'communication3', //basic
            addressBookError: null,
            addressBookData: {},
            addressBookQuery: {
                current: 1,
                size: 10
            },

            report: reportEmpty,
            analysis: analysisEmpty,
            mobileLoading: false,

            activeDegreeColumn: [
                {
                    prop: 'app_point_zh',
                }, {
                    label: '近1月',
                    render: ({ item: { item_1m: _1m } }) => _1m
                }, {
                    label: '近3月',
                    render: ({ item: { item_3m: _3m } }) => _3m
                }, {
                    label: '近6月',
                    render: ({ item: { item_6m: _6m } }) => _6m
                }, {
                    label: '近6月平均',
                    render: ({ item: { avg_item_6m: _6m } }) => _6m
                }, {
                    label: '近3月平均',
                    render: ({ item: { avg_item_3m: _3m } }) => _3m
                },
            ],

            packageColumns: [
                {
                    label: '套餐名称',
                    prop: 'name',
                },
                {
                    label: '使用量',
                    prop: 'used',
                }, {
                    label: '总量',
                    prop: 'total',
                }, {
                    label: '数据单位',
                    prop: 'unit',
                    fixed: 'right'
                }
            ],
            addressBookColumns: [
                {
                    label: '#',
                    prop: 'bookId',
                    width: '80',
                    fixed: true
                },
                {
                    label: '联系人名字',
                    prop: 'contactName',
                },
                {
                    label: '联系人电话号码',
                    prop: 'contactPhone',
                },
                {
                    label: '添加时间',
                    prop: 'smsTime',
                    align: 'center',
                },
                {
                    label: '通话次数',
                    align: 'center',
                    render: ({ one, three, six }) =>
                        <>
                            <Tag size="mini" type="success">{`1月:${one || '-'}次`}</Tag>
                            <Tag size="mini" type="primary">{`3月:${three || '-'}次`}</Tag>
                            <Tag size="mini" type="warning">{`6月:${six || '-'}次`}</Tag>
                        </>
                }, {
                    label: '查询时间',
                    prop: 'createTime',
                }
            ],

            netColumns: [
                {
                    label: '#',
                    prop: 'details_id',
                    fixed: true
                },
                {
                    label: '流量使用时间',
                    prop: 'time',
                },
                {
                    label: '使用时长',
                    prop: 'duration',
                    render: (row) => durationFormat(row.duration)
                },
                {
                    label: '流量使用量',
                    prop: 'subflow',
                    render: (row) => {
                        return formatFileSize(row.subflow * 1024)
                    }
                },
                {
                    label: '使用地点',
                    prop: 'location',
                },
                {
                    label: '网络类型',
                    prop: 'net_type',
                },
                {
                    label: '业务名称',
                    prop: 'service_name',
                },
                {
                    label: '消费',
                    prop: 'fee',
                    render: (row) => {
                        return '¥' + row.fee / 100
                    }
                },
            ],

            billColumns: [
                {
                    label: '账单周期',
                    prop: 'bill_month',
                    width: '120',
                    fixed: true
                }, {
                    label: '账期起始时间',
                    width: '120',
                    prop: 'bill_start_date'
                }, {
                    label: '账期结束时间',
                    width: '120',
                    prop: 'bill_end_date'
                }, {
                    label: '固定套餐费',
                    prop: 'bass_fee',
                    render: (row) => {
                        return '¥' + row.bass_fee / 100
                    }
                }, {
                    label: '增值业务费',
                    prop: 'extra_service_fee',
                    render: (row) => {
                        return '¥' + row.extra_service_fee / 100
                    }
                }, {
                    label: '语音费',
                    prop: 'voice_fee',
                    render: (row) => {
                        return '¥' + row.voice_fee / 100
                    }
                }, {
                    label: '短信费',
                    prop: 'sms_fee',
                    render: (row) => {
                        return '¥' + row.sms_fee / 100
                    }
                }, {
                    label: '流量费',
                    prop: 'web_fee',
                    render: (row) => {
                        return '¥' + row.web_fee / 100
                    }
                }, {
                    label: '其他费用',
                    prop: 'extra_fee',
                    render: (row) => {
                        return '¥' + row.extra_fee / 100
                    }
                }, {
                    label: '总费用',
                    prop: 'total_fee',
                    fixed: 'right',
                    with: '100',
                    render: (row) => {
                        return '¥' + row.total_fee / 100
                    }
                }, {
                    label: '优惠费',
                    prop: 'discount',
                    render: (row) => {
                        return '¥' + row.discount / 100
                    }
                }, {
                    label: '其他优惠费',
                    prop: 'extra_discount_fee',
                    render: (row) => {
                        return '¥' + row.extra_discount_fee / 100
                    }
                }, {
                    label: '个人实际费用',
                    prop: 'actual_fee',
                    render: (row) => {
                        return '¥' + row.actual_fee / 100
                    }
                },
                {
                    label: '本期已付费用',
                    prop: 'paid_fee',
                    render: (row) => {
                        return '¥' + row.paid_fee / 100
                    }
                },
                {
                    label: '本期未付费',
                    prop: 'unpaid_fee',
                    render: (row) => {
                        return '¥' + row.unpaid_fee / 100
                    }
                },
                {
                    label: '本期可用积分',
                    prop: 'point'
                },
                {
                    label: '上期可用积分',
                    prop: 'last_point'
                },
                {
                    label: '其他优惠费',
                    prop: 'extra_discount_fee'
                },
                {
                    label: '本手机关联号码',
                    prop: 'related_mobiles'
                },
                {
                    label: '备注',
                    prop: 'notes'
                }
            ],
            familieColumns: [
                {
                    label: '失效时间',
                    prop: 'expire_date',
                }, {
                    label: '成员短号',
                    prop: 'short_number',
                }, {
                    label: '成员长号码',
                    prop: 'long_number',
                }, {
                    label: '加入时间',
                    prop: 'join_date',
                }, {
                    label: '成员类型',
                    prop: 'member_type',
                    render: row => {
                        const types = {
                            'MASTER': {
                                text: '家长',
                                color: 'success'
                            },
                            'MEMBER': {
                                text: '成员',
                                color: 'warning'
                            }
                        }

                        const thisType = types[row.member_type]
                        return <Tag type={thisType.color}>{thisType.text}</Tag>
                    }
                }
            ],
            rechargeColumns: [
                {
                    label: '充值时间',
                    prop: 'recharge_time',
                },
                {
                    label: '充值金额',
                    prop: 'amount',
                    render: (row) => {
                        return '¥' + row.amount / 100
                    }
                },
                {
                    label: '缴费方式',
                    prop: 'type'
                },
            ],

            smsColumns: [
                {
                    label: '#',
                    prop: 'details_id',
                    fixed: true
                },
                {
                    label: '发生时间',
                    prop: 'time',
                },
                {
                    label: '地点',
                    prop: 'location',
                },
                {
                    label: '短信类型',
                    prop: 'msg_type',
                    render: row => {
                        const types = {
                            'SMS': {
                                text: '短信',
                                color: 'success'
                            },
                            'MMS': {
                                text: '被叫',
                                color: 'warning'
                            }
                        }

                        const thisType = types[row.msg_type]
                        return <Tag type={thisType.color}>{thisType.text}</Tag>
                    }
                },

                {
                    label: '方向',
                    prop: 'send_type',
                    render: row => {
                        const types = {
                            'SEND': {
                                text: '发送',
                                color: 'success'
                            },
                            'RECEIVE': {
                                text: '收取',
                                color: 'warning'
                            }
                        }

                        const thisType = types[row.send_type]
                        return <Tag type={thisType.color}>{thisType.text}</Tag>
                    }
                },
                {
                    label: '对方号码',
                    prop: 'peer_number',
                }
                ,
                {
                    label: '业务名称',
                    prop: 'service_name',
                }
                ,
                {
                    label: '产生费用',
                    prop: 'fee',
                    render: (row) => {
                        return '¥' + row.fee / 100
                    }
                }
            ],

            peerNumTopColumns: [
                {
                    label: '对方号码',
                    prop: 'peer_number',
                },
                {
                    label: '通话地',
                    prop: 'peer_num_loc',
                },
                {
                    label: '号码类型',
                    prop: 'group_name',
                },
                {
                    label: '号码标识',
                    prop: 'company_name',
                },
                {
                    label: '通话次数',
                    prop: 'call_cnt',
                },
                {
                    label: '通话时长',
                    prop: 'call_time',
                    render: (row) => durationFormat(row.call_time)
                },
                {
                    label: '主叫次数',
                    prop: 'dial_cnt',
                },
                {
                    label: '被叫次数',
                    prop: 'dialed_cnt',
                },
                {
                    label: '主叫时长',
                    prop: 'dial_time',
                    render: (row) => durationFormat(row.dial_time)
                },
                {
                    label: '被叫时长',
                    prop: 'dialed_time',
                    render: (row) => durationFormat(row.dialed_time)
                },
            ],
            callsColumns: [
                {
                    label: '#',
                    prop: 'details_id',
                    fixed: true
                },
                {
                    label: '起始时间',
                    prop: 'time',
                },
                {
                    label: '通话地点',
                    prop: 'location',
                },
                {
                    label: '通话地类型',
                    prop: 'location_type',
                },
                {
                    label: '呼叫类型',
                    prop: 'dial_type',
                    render: (row) => {
                        const types = {
                            'DIAL': {
                                text: '主叫',
                                color: 'success'
                            },
                            'DIALED': {
                                text: '被叫',
                                color: 'warning'
                            }
                        }

                        const thisType = types[row.dial_type]
                        return <Tag type={thisType.color}>{thisType.text}</Tag>
                    }
                },
                {
                    label: '对方号码',
                    prop: 'peer_number',
                },
                {
                    label: '通话时长',
                    prop: 'duration',
                    render: (row) => durationFormat(row.duration)
                },
                {
                    label: '通话费用',
                    prop: 'fee',
                    render: (row) => {
                        return '¥' + row.fee / 100
                    }
                }]
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                dialogVisible: true
            })

            this.getMobileBook()
            this.getMobileData()
        }
    }

    close() {
        this.setState({
            tabsActiveName: 'basic',
            dialogVisible: false,
            report: reportEmpty,
            analysis: analysisEmpty,
        })
        this.props.onClose()
    }

    async getMobileData(userId = this.props.userId) {
        if (!userId) return

        try {
            this.setState({
                mobileLoading: true
            })
            const { body } = await apiV2.getMobileData({
                userId
            })

            if (body) {
                // this.setState({
                //     report: JSON.parse(body),
                //     analysis: analysisEmpty,
                // })

                const { mobileData, mobileReport } = body
                this.setState({
                    report: JSON.parse(mobileData) || reportEmpty,
                    analysis: JSON.parse(mobileReport) || analysisEmpty,
                })
            } else {
                this.setState({
                    report: reportEmpty,
                    analysis: analysisEmpty,
                })
            }
        } finally {
            this.setState({
                mobileLoading: false
            })
        }

    }

    async syncMobileBookNum(userId = this.props.userId) {
        if (!userId) return

        this.setState({ syncMobileBookNumLoad: true })
        try {
            const { message, success } = await apiV2.uptMobileBookNum({ userId });
            if (success) {
                Message.success(message);
                await this.getMobileBook();
            } else {
                Message.error(message);
            }
        } finally {
            
            this.setState({ syncMobileBookNumLoad: false })
        }

    }

    async getMobileBook(userId = this.props.userId) {
        if (!userId) return
        const { message, success, body } = await apiV2.getMobileBook({
            bodyState: {
                userId,
                ...this.state.addressBookQuery,
                ...this.state.addressBookParam
            }
        })

        if (success) {
            this.setState({
                addressBookData: body,
                addressBookError: null
            })
        } else {
            this.setState({
                addressBookData: {},
                addressBookError: message
            })
        }
    }

    addressBookSizeChange(e) {
        const addressBookQuery = Object.assign(this.state.addressBookQuery, { size: e })
        this.setState({
            addressBookQuery
        }, this.getMobileBook)
    }

    addressBookCurrentChange(e) {
        const addressBookQuery = Object.assign(this.state.addressBookQuery, { current: e })
        this.setState({
            addressBookQuery
        }, this.getMobileBook)
    }

    reportTotal(data, name = 'total_size', init = 0) {
        let t = init
        for (const item of data) {
            const sum = item[name]
            if (sum) {
                t += sum
            }
        }

        return t
    }

    reportTotalList(data, name = 'items', init = 0) {
        let t = init
        for (const item of data) {
            const sum = (item[name] || []).length
            if (sum) {
                t += sum
            }
        }

        return t
    }


    /**
     * 查询分析报告中值
     * @param {*} list 数据列表
     * @param {*} key 健名
     * @param {*} defaultValue 数据中值为空时使用默认值
     * @returns 符合健名下的值 
     */
    analysisReportValueLookup(list, key, defaultValue) {
        for (const item of list) {
            if (item.key === key) {
                return item.value || defaultValue
            }
        }
    }




    /**
     * 查询分析报告中值(检查点)
     * @param {*} list 数据列表
     * @param {*} key 健名
     * @param {*} defaultValue 数据中值为空时使用默认值
     * @returns 符合健名下的值 
     */
    analysisReportBCheckLookup(list, key, defaultValue) {
        for (const item of list) {
            if (item.check_item === key) {
                return { v: item.result || defaultValue, c: item.comment }
            }
        }
        return {}
    }


    reportDateFormat(item, name = 'bill_month', sumName = 'total_size') {
        return (
            <span>
                <span>{'日期：' + item[name]}</span>
                <Tag style={{ marginLeft: '1rem' }} type="success">{item[sumName]}</Tag>
                <span>{'条'}</span>
            </span>)
    }

    uptAddressBookParam(name, value) {
        const addressBookParam = Object.assign(this.state.addressBookParam, { [name]: value })
        this.setState({ addressBookParam })
    }

    render() {
        // callsColumns, smsColumns, netColumns,calls, smses, nets,
        const { addressBookError, addressBookData, addressBookColumns, tabsActiveName, peerNumTopColumns, activeDegreeColumn,
            rechargeColumns, billColumns, packageColumns, familieColumns,
            report: { carrier_user_info: carrierUserInfo, recharges, bills, packages, families },
            analysis: { report: basicReport, user_basic: userBasic, cell_phone: cellPhone, basic_check_items: basicCheckItems,
                behavior_check: behaviorCheck, friend_circle: friendCircle, call_risk_analysis: callRiskAnalysis,
                call_contact_detail: callContactDetail,
                active_degree: activeDegree },
            syncMobileBookNumLoad
        } = this.state

        const addressBookParam = this.state.addressBookParam


        function findActiveDegrees(...name) {
            return (activeDegree || []).filter(({ app_point: p }) => name.includes(p))
        }

        let peerNumTopList = []
        if (friendCircle) {
            peerNumTopList = friendCircle.peer_num_top_list || []
        }

        const reliabilityType = { '1': '已登记', '2': '未登记｜', '-1': '未知' }
        const ISPType = { 'CHINA_MOBILE': '中国移动', 'CHINA_TELECOM': '中国电信', 'CHINA_UNICOM': '中国联通' }

        const DecimalProgress = (v) => {
            return (<Progress style={{ width: '100px' }} strokeWidth={18} percentage={v * 10} textInside />)
        }

        return (
            <Dialog
                size="large"
                title="运营商详情"
                visible={this.state.dialogVisible}
                onCancel={this.close.bind(this)}
                lockScroll={false} >
                <Dialog.Body>
                    <Tabs activeName={tabsActiveName} type="card">
                        <Tabs.Pane label="用户详情" name="communication3">
                            <UserInfoTabs userId={this.props.userId} />
                        </Tabs.Pane>

                        <Tabs.Pane label="通讯录" name="communication">
                            <Form inline>
                                <Form.Item label="手机号">
                                    <Input value={addressBookParam.phone} onChange={e => this.uptAddressBookParam('phone', e)} />
                                </Form.Item>
                                <Form.Item label="名称">
                                    <Input value={addressBookParam.name} onChange={e => this.uptAddressBookParam('name', e)} />
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={() => this.getMobileBook()} type="primary">{'查询'}</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={syncMobileBookNumLoad} onClick={() => this.syncMobileBookNum()} type="primary">{'刷新通话次数'}</Button>
                                </Form.Item>
                            </Form>
                            {addressBookError ? <Tag type="danger">{addressBookError}</Tag> :
                                (!addressBookData.total ? <Tag type="warning">{'没有查询到通讯记录哦'}</Tag> :
                                    <div>
                                        <Table columns={addressBookColumns} data={addressBookData.records} />
                                        <MyPagination
                                            total={addressBookData.total}
                                            pageSize={addressBookData.size}
                                            pageNumber={addressBookData.current}
                                            onSizeChange={e => this.addressBookSizeChange(e)}
                                            onCurrentChange={e => this.addressBookCurrentChange(e)} />
                                    </div>
                                )
                            }
                        </Tabs.Pane>
                        <Tabs.Pane label="用户短信详情" name="communication2">
                            <UserSmsList userId={this.props.userId} />
                        </Tabs.Pane>


                        <Tabs.Pane label="运营商信息" name="basic">
                            <Loading loading={this.state.mobileLoading}>
                                <Descriptions bordered>
                                    <Descriptions.Item label="姓名">{carrierUserInfo.name}</Descriptions.Item>
                                    <Descriptions.Item label="入网时间">{carrierUserInfo.open_time}</Descriptions.Item>
                                    <Descriptions.Item label="手机号">{carrierUserInfo.mobile}</Descriptions.Item>
                                    <Descriptions.Item label="实名制信息">
                                        <Badge status="processing" text={reliabilityType[carrierUserInfo.reliability]} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="身份证号" span={2}>
                                        {carrierUserInfo.idcard}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="套餐" span={3}>
                                        {carrierUserInfo.package_name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="省份">{carrierUserInfo.province}</Descriptions.Item>
                                    <Descriptions.Item label="城市">{carrierUserInfo.city}</Descriptions.Item>
                                    <Descriptions.Item label="话费">{'¥' + carrierUserInfo.real_balance / 100}</Descriptions.Item>
                                    <Descriptions.Item label="详细地址">
                                        {carrierUserInfo.address}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="邮箱">{carrierUserInfo.email}</Descriptions.Item>
                                    <Descriptions.Item label="运营商">{ISPType[carrierUserInfo.carrier]}</Descriptions.Item>
                                    <Descriptions.Item label="级别">{carrierUserInfo.level}</Descriptions.Item>
                                    <Descriptions.Item label="账户状态" span={2}>{carrierUserInfo.state}</Descriptions.Item>
                                    <Descriptions.Item label="IMSI" >{carrierUserInfo.imsi}</Descriptions.Item>
                                    <Descriptions.Item label="最后编辑时间" >{carrierUserInfo.last_modify_time}</Descriptions.Item>
                                </Descriptions>
                            </Loading>
                        </Tabs.Pane>

                        <Tabs.Pane label="用户应用列表" name="communication_3">
                            <UserAppList userId={this.props.userId} />
                        </Tabs.Pane>




                        {/* <Tabs.Pane label="通话详单" name="i3" style={hideStyle}>
                            <Statistic title="总通话次数" value={this.reportTotal(calls, 'total_size')} />
                            <Collapse accordion name="i3">
                                {
                                    calls.map(cell => {
                                        return (
                                            <Collapse.Item key={cell.bill_month} title={this.reportDateFormat(cell)}>
                                                <Table columns={callsColumns}
                                                    data={cell.items} />
                                            </Collapse.Item>
                                        )
                                    })
                                }
                            </Collapse>
                        </Tabs.Pane>
                        <Tabs.Pane label="短信详单" name="i4" style={hideStyle}>
                            <Statistic title="总短信详单数" value={this.reportTotal(smses)} />
                            <Collapse accordion name="i4">
                                {
                                    smses.map(cell => {
                                        return (
                                            <Collapse.Item key={cell.bill_month} title={this.reportDateFormat(cell)}>
                                                <Table columns={smsColumns}
                                                    data={cell.items} />
                                            </Collapse.Item>
                                        )
                                    })
                                }
                            </Collapse>
                        </Tabs.Pane> */}
                        {/* <Tabs.Pane label="流量详单" name="i5" style={hideStyle}>
                            <Statistic title="总流量详单数" value={this.reportTotal(nets)} />
                            <Collapse accordion name="i4">
                                {
                                    nets.map(cell => {
                                        return (
                                            <Collapse.Item key={cell.bill_month} title={this.reportDateFormat(cell)}>
                                                <Table columns={netColumns}
                                                    data={cell.items} />
                                            </Collapse.Item>
                                        )
                                    })
                                }
                            </Collapse>
                        </Tabs.Pane> */}
                        {
                            false ? (
                                <>
                                    <Tabs.Pane label="缴费信息" name="i6" style={hideStyle}>
                                        <Table columns={rechargeColumns} data={recharges} />
                                    </Tabs.Pane>
                                    <Tabs.Pane label="账单信息" name="i7" style={hideStyle}>
                                        <Statistic title="总账单数" value={bills.length} />
                                        <Table columns={billColumns} data={bills} />
                                    </Tabs.Pane>
                                    <Tabs.Pane label="套餐信息" name="i8" style={hideStyle}>
                                        <Statistic title="总套餐数" value={this.reportTotalList(packages)} />
                                        <Collapse accordion name="i8">
                                            {
                                                packages.map(cell => {
                                                    const key = cell.billStartDate + ' - ' + cell.billEndDate
                                                    return (
                                                        <Collapse.Item key={key} title={key}>
                                                            <Table columns={packageColumns} data={cell.items} />
                                                        </Collapse.Item>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    </Tabs.Pane>
                                    <Tabs.Pane label="亲情号码详情" name="i9" style={hideStyle}>
                                        <Statistic title="总亲情号码网数" value={this.reportTotalList(families)} />

                                        <Collapse accordion name="i9">
                                            {
                                                families.filter(({ family_num: n, items }) => n || items.length > 0).map(cell => {
                                                    const key = cell.family_num
                                                    return (
                                                        <Collapse.Item key={key} title={key}>
                                                            <Table columns={familieColumns} data={cell.items} />
                                                        </Collapse.Item>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    </Tabs.Pane>
                                </>) : null
                        }

                        <Tabs.Pane label={(<span><i className="el-icon-share" />{'分析报告'}</span>)} name="analysis">

                            <Tabs type="border-card" activeName="1">
                                <Tabs.Pane label="基本报告" name="1">
                                    <Descriptions title="基本信息" bordered>
                                        <Descriptions.Item label="报告类型">{this.analysisReportValueLookup(basicReport, 'data_type')}</Descriptions.Item>
                                        <Descriptions.Item label="数据来源">
                                            <SText title={this.analysisReportValueLookup(basicReport, 'source_name_zh')}
                                                value={this.analysisReportValueLookup(basicReport, 'source_name')} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="数据获取时间">{this.analysisReportValueLookup(basicReport, 'data_gain_time')}</Descriptions.Item>
                                        <Descriptions.Item label="报告生成时间">{this.analysisReportValueLookup(basicReport, 'update_time')}</Descriptions.Item>
                                        <Descriptions.Item label="报告版本" span={2}>{this.analysisReportValueLookup(basicReport, 'version')}</Descriptions.Item>
                                        <Descriptions.Item label="报告ID" >{this.analysisReportValueLookup(basicReport, 'task_id')}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions title="用户基本信息" bordered>
                                        <Descriptions.Item label="姓名">{this.analysisReportValueLookup(userBasic, 'name')}</Descriptions.Item>
                                        <Descriptions.Item label="身份证号码">{this.analysisReportValueLookup(userBasic, 'id_card')}</Descriptions.Item>
                                        <Descriptions.Item label="性别">{this.analysisReportValueLookup(userBasic, 'gender')}</Descriptions.Item>
                                        <Descriptions.Item label="年龄">{this.analysisReportValueLookup(userBasic, 'age')}</Descriptions.Item>
                                        <Descriptions.Item label="星座">{this.analysisReportValueLookup(userBasic, 'constellation')}</Descriptions.Item>
                                        <Descriptions.Item label="所属省">{this.analysisReportValueLookup(userBasic, 'province')}</Descriptions.Item>
                                        <Descriptions.Item label="所属市">{this.analysisReportValueLookup(userBasic, 'city')}</Descriptions.Item>
                                        <Descriptions.Item label="所属县">{this.analysisReportValueLookup(userBasic, 'region')}</Descriptions.Item>
                                        <Descriptions.Item label="籍贯">{this.analysisReportValueLookup(userBasic, 'native_place')}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions title="手机号基本信息" bordered>
                                        <Descriptions.Item label="手机号">{this.analysisReportValueLookup(cellPhone, 'mobile')}</Descriptions.Item>
                                        <Descriptions.Item label="姓名">{this.analysisReportValueLookup(cellPhone, 'carrier_name')}</Descriptions.Item>
                                        <Descriptions.Item label="身份证号码">{this.analysisReportValueLookup(cellPhone, 'carrier_idcard')}</Descriptions.Item>
                                        <Descriptions.Item label="开户时间">{this.analysisReportValueLookup(cellPhone, 'reg_time')}</Descriptions.Item>
                                        <Descriptions.Item label="用户邮箱">{this.analysisReportValueLookup(cellPhone, 'email')}</Descriptions.Item>
                                        <Descriptions.Item label="地址">{this.analysisReportValueLookup(cellPhone, 'address')}</Descriptions.Item>
                                        <Descriptions.Item label="是否实名">{this.analysisReportValueLookup(cellPhone, 'reliability')}</Descriptions.Item>
                                        <Descriptions.Item label="手机号码归属地">{this.analysisReportValueLookup(cellPhone, 'phone_attribution')}</Descriptions.Item>
                                        <Descriptions.Item label="居住地址">{this.analysisReportValueLookup(cellPhone, 'live_address')}</Descriptions.Item>
                                        <Descriptions.Item label="余额">{this.analysisReportValueLookup(cellPhone, 'available_balance') / 100 + '¥'}</Descriptions.Item>
                                        <Descriptions.Item label="开户时长">{this.analysisReportValueLookup(cellPhone, 'in_time')}</Descriptions.Item>
                                        <Descriptions.Item label="套餐">{this.analysisReportValueLookup(cellPhone, 'package_namemobile')}</Descriptions.Item>
                                        <Descriptions.Item label="账单认证日期">{this.analysisReportValueLookup(cellPhone, 'bill_certification_day')}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions title="基本信息校验项" bordered>
                                        <Descriptions.Item label="身份证有效性">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'idcard_check')
                                            return <SText key={i} title={v} value={c} />
                                        })
                                        }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="邮箱有效性">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'email_check')
                                            return <SText key={i} title={v} value={c} />
                                        })
                                        }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="地址有效性"> {[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'address_check')
                                            return <SText key={i} title={v} value={c} />
                                        })
                                        }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="通话记录完整性">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'call_data_check')
                                            return <SText key={i} title={v} value={c} />
                                        })
                                        }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="身份证与运营商匹配">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'idcard_match')
                                            return <SText key={i} title={v} value={c} />
                                        })
                                        }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="姓名与运营商匹配">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'name_match')
                                            return <SText key={i} title={v} value={c} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="是否出现在法院黑名单">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'is_name_and_idcard_in_court_black')
                                            return <SText key={i} title={v} value={c} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="姓名是否与运营商数据匹配">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 's_name_and_idcard_in_finance_black')
                                            return <SText key={i} title={v} value={c} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="是否出现在金融机构黑名单">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'is_name_and_mobile_in_finance_black')
                                            return <SText key={i} title={v} value={c} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="号码沉默度(近3月)">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'mobile_silence_3m')
                                            return <SText key={i} title={v + '分'} value={c} prefix={DecimalProgress(v)} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="号码沉默度(近6月)">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'mobile_silence_6m')
                                            return <SText key={i} title={v + '分'} value={c} prefix={DecimalProgress(v)} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="欠费风险度(近3月)">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'arrearage_risk_3m')
                                            return <SText key={i} title={v + '分'} value={c} prefix={DecimalProgress(v)} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="欠费风险度(近6月)">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'arrearage_risk_6m')
                                            return <SText key={i} title={v + '分'} value={c} prefix={DecimalProgress(v)} />
                                        })}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="亲情网风险度">{[0].map(i => {
                                            const { v, c } = this.analysisReportBCheckLookup(basicCheckItems, 'binding_risk')
                                            return <SText key={i} title={v + '分'} value={c} prefix={DecimalProgress(v)} />
                                        })}
                                        </Descriptions.Item>
                                    </Descriptions>


                                </Tabs.Pane>
                                <Tabs.Pane label="行为监测" name="5">
                                    <Table size="mini" border columns={activeDegreeColumn} data={findActiveDegrees('no_call_day', 'power_off_day')} />

                                    <Descriptions bordered>
                                        {behaviorCheck.map(({ check_point: cp, check_point_cn: cpc, evidence, result }) => {
                                            return (
                                                <Descriptions.Item key={cp} label={cpc}>
                                                    <SText title={result} value={evidence} />
                                                </Descriptions.Item>
                                            )
                                        })}
                                    </Descriptions>
                                </Tabs.Pane>
                                <Tabs.Pane label="朋友圈联系人" name="2">
                                    <MobileFriendList userId={this.props.userId} />
                                    <div style={{ display: 'none' }}>
                                        <Collapse value={['3m', '6m']} >
                                            {
                                                peerNumTopList.map(({ key, top_item: items }) => {
                                                    switch (key) {
                                                        case 'peer_num_top3_3m': {
                                                            return (
                                                                <Collapse.Item name="3m" title="近3个月" key={key}>
                                                                    <Table columns={peerNumTopColumns} data={items} />
                                                                </Collapse.Item>)
                                                        }
                                                        case 'peer_num_top3_6m': {
                                                            return (
                                                                <Collapse.Item name="6m" title="近6个月" key={key}>
                                                                    <Table columns={peerNumTopColumns} data={items} />
                                                                </Collapse.Item>)
                                                        }
                                                        default: { return <div key={key} /> }
                                                    }
                                                })
                                            }
                                        </Collapse>
                                    </div>
                                </Tabs.Pane>
                                <Tabs.Pane label="通话详单" name="3">
                                    <CallStaticList calls={(callContactDetail || []).sort((a, b) => b.call_cnt_6m - a.call_cnt_6m)} />
                                </Tabs.Pane>
                                <Tabs.Pane label="通话风险分析项" name="4">
                                    <Collapse value={['3m', '6m']}>
                                        {
                                            callRiskAnalysis.map(({ analysis_item: item, analysis_point: point, analysis_desc: desc }) => {
                                                const { call_analysis_dial_point: dial, call_analysis_dialed_point: dialed } = point

                                                return (
                                                    <Collapse.Item key={item} name={item} title={desc}>
                                                        <Descriptions bordered>
                                                            <Descriptions.Item label="近1月通话次数">{point.call_cnt_1m}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月通话次数">{point.call_cnt_3m}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月通话次数">{point.call_cnt_6m}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月平均通话次数">{point.avg_call_cnt_3m}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月平均通话次数">{point.avg_call_cnt_6m}</Descriptions.Item>
                                                            <Descriptions.Item label="近1月通话时长">{durationFormat(point.call_time_1m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月通话时长">{durationFormat(point.call_time_3m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月通话时长">{durationFormat(point.call_time_6m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月平均通话时长">{durationFormat(point.avg_call_time_3m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月平均通话时长">{durationFormat(point.avg_call_time_6m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近1月主叫通话次数">{dial.call_dial_cnt_1m}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月主叫通话次数">{dial.call_dial_cnt_3m}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月主叫通话次数">{dial.call_dial_cnt_6m}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月主叫月均通话次数">{dial.avg_call_dial_cnt_3m}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月主叫月均通话次数">{dial.avg_call_dial_cnt_6m}</Descriptions.Item>
                                                            <Descriptions.Item label="近1月主叫通话时长">{durationFormat(dial.call_dial_time_1m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月主叫通话时长">{durationFormat(dial.call_dial_time_3m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月主叫通话时长">{durationFormat(dial.call_dial_time_6m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月主叫月均通话时长">{durationFormat(dial.avg_call_dial_time_3m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月主叫月均通话时长">{durationFormat(dial.avg_call_dial_time_6m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近1个月被叫通话次数">{dialed.call_dialed_cnt_1m}</Descriptions.Item>
                                                            <Descriptions.Item label="近3个月被叫通话次数">{dialed.call_dialed_cnt_3m}</Descriptions.Item>
                                                            <Descriptions.Item label="近6个月被叫通话次数">{dialed.call_dialed_cnt_6m}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月被叫月均通话次数">{dialed.avg_call_dialed_cnt_3m}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月被叫月均通话次数">{dialed.avg_call_dialed_cnt_6m}</Descriptions.Item>
                                                            <Descriptions.Item label="近1月被叫通话时长">{durationFormat(dialed.call_dialed_time_1m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月被叫通话时长">{durationFormat(dialed.call_dialed_time_3m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月被叫通话时长">{durationFormat(dialed.call_dialed_time_6m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近3月被叫月均通话时长">{durationFormat(dialed.avg_call_dialed_time_3m)}</Descriptions.Item>
                                                            <Descriptions.Item label="近6月被叫月均通话时长">{durationFormat(dialed.avg_call_dialed_time_6m)}</Descriptions.Item>
                                                        </Descriptions>
                                                    </Collapse.Item>)
                                            })
                                        }
                                    </Collapse>
                                </Tabs.Pane>
                            </Tabs>
                        </Tabs.Pane>
                        <Tabs.Pane label="风控管理" name="risk">
                            <RiskManagement userId={this.props.userId} />
                        </Tabs.Pane>
                    </Tabs>
                </Dialog.Body>
            </Dialog>
        )
    }

}