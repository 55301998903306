import React, { Component } from 'react'
import { Button, Form, Input, Message } from 'element-react'

import RiskManagement from '../member/risk_management'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'

export default class RidkInput extends Component {

    constructor(props) {
        super(props)


        this.state = {
            radarData: null,
            probeCData: null,
            currentQueryData: null,

            queryForm: {
                idCardNum: '',
                name: '',
                mobile: ''
            }
        }

    }
    changeQueryForm(name, value, refresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (refresh) this.serverGet() })
    }

    async refreshProbe() {
        const { body, message, success } = await apiV2.getRealTimeRiskC({ param: this.state.currentQueryData })
        if (!success) Message.error(message)

        this.setState({ probeCData: JSON.parse(body) })
    }

    async refreshRadar() {
        const { body, message, success } = await apiV2.getRealTimeRiskRadar({ param: this.state.currentQueryData })
        if (!success) Message.error(message)
        this.setState({ radarData: JSON.parse(body) })
    }


    serverGet() {
        const { name, mobile, idCardNum } = this.state.queryForm
        this.setState({ currentQueryData: { realName: name, idCardNum, phone: mobile } }, () => {
            this.refreshRadar()
            this.refreshProbe()
        })
    }


    render() {
        const { queryForm, radarData, probeCData, currentQueryData } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="身份证号">
                        <Input value={queryForm.idCardNum}
                            onChange={(e) => this.changeQueryForm('idCardNum', e)} />
                    </Form.Item>
                    <Form.Item label="姓名">
                        <Input value={queryForm.name}
                            onChange={(e) => this.changeQueryForm('name', e)} />
                    </Form.Item>
                    <Form.Item label="手机号">
                        <Input value={queryForm.mobile}
                            onChange={(e) => this.changeQueryForm('mobile', e)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.serverGet()}>{'查询'}</Button>
                    </Form.Item>
                    <Form.Item>
                        {/* <Button type="success" onClick={() => this.openForm()}>{'保存此信息'}</Button> */}
                    </Form.Item>
                </Form>

                <Condition visible={currentQueryData}>
                    <RiskManagement noCreditScore radarAnalysis={radarData} probeCAnalysis={probeCData} noRequest
                        onRefreshProbe={() => this.refreshProbe()} onRefreshRadar={() => this.refreshRadar()} />
                </Condition>
            </>
        )
    }
}