/*
 * @Author: FATE boss@fellw.com
 * @Date: 2023-11-20 11:52:33
 * @LastEditors: FATE boss@fellw.com
 * @LastEditTime: 2023-12-16 17:12:47
 * @FilePath: \credit-loan-admin-repeat\src\containers\risk\coms\mi-rong_gzai.ext.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Tag } from 'element-react'
import { OrderStatusList } from '../../loan/types'
import React from 'react'
import moment from 'moment'

export function fmtTime(obj) {
    if (!obj) return ''
    return `${obj.year}-${obj.monthValue}-${obj.dayOfMonth}`
}

export function newLoanColumns() {
    return [
        {
            label: '手机号',
            prop: 'mobile',
            align: 'center',
            fixed: true,
        }, {
            label: '姓名',
            prop: 'name',
            align: 'center',
        }, {
            label: '平台名称',
            prop: 'companyName',
            align: 'center',
        }, {
            label: '借款金额',
            prop: 'amount',
            align: 'center',
        }, {
            label: '借款时间',
            prop: 'createTime',
            align: 'center',
        }, {
            label: '订单状态',
            prop: 'statusLabel',
            align: 'center',
        }
    ]
}

export function newLoanColumnsV2(more = []) {
    return [
        // , 
        // {
        //     label: '借款金额',
        //     prop: 'quota',
        //     align: 'center',
        // }
        {
            label: '借款时间',
            prop: 'borrowingTime',
            align: 'center',
            render: ({ borrowingTime }) => fmtTime(borrowingTime)
        },
        ...more,
        {
            label: '平台名称',
            prop: 'companyName',
            align: 'center',
            render: ({ openPlatformName }) => openPlatformName ? `${openPlatformName.substring(0, 1)}****` : '*****'
        },
        {
            label: '订单状态',
            prop: 'borrowingStatus',
            align: 'center',
            render: ({ borrowingStatus, estimatedRepayTime:{year,monthValue,dayOfMonth} }) => {
                const find = OrderStatusList.find(({ gzV2Enum }) => borrowingStatus === gzV2Enum)
                // 是否逾期

                const estimatedRepayTimeOver = moment(`${year}-${monthValue}-${dayOfMonth}`)
                    .add(1, 'days')
                    .toDate()
                    .getTime();

                const overdue = borrowingStatus === 'REQUIREDREPAYMENT'
                    && new Date().getTime() >= estimatedRepayTimeOver;
                return <Tag type={find.theme}>{overdue ? '逾期未还款' : find.name}</Tag>
            }
        }
    ]
}