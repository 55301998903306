import React, { Component } from 'react'

import PropTypes from 'prop-types'


/**
 * 认证信息
 */
export default class Image extends Component {


    static propTypes = {
        children: PropTypes.any,
        width: PropTypes.any,
    }



    render() {
        const { width } = this.props
        const defaultWidth = width || '0.5rem'
        return (<span style={{ padding: defaultWidth }}>{this.props.children}</span>)
    }
}