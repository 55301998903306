import React, { Component } from 'react'

import { Drawer, Alert } from 'antd'
import { Form, Input, Tag } from 'element-react'
import { Button } from 'element-react/next'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'
import apiV2 from '../../api/api-v2'


/**
 * 订单延期
 */
export default class OrderReduction extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func,
        isStages: PropTypes.bool
    }

    static RemissionAmountEmpty = {
        orderId: null,
        dayNum: 3,
        overdueAmount: 0,
        deferMoney: 0
    }
    static ResultEmpty = { message: '' }

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            submitLoading: false,
            addFormData: OrderReduction.RemissionAmountEmpty,
            result: OrderReduction.ResultEmpty,
            originalAmount: { allRepaymentMoney: 0, agingRepaymentMoney: 0, overMoney: 0 }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
            this.getUnderwayOrderMoney()
        }
    }

    async getUnderwayOrderMoney() {
        const { body } = await apiV2.getUnderwayOrderMoney({ orderId: this.props.orderId })
        this.setState({ originalAmount: body || {} })
        this.changeAddForm('overdueAmount', this.state.originalAmount.overMoney || 0)
    }

    open() {
        this.setState({ visible: true })
    }

    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })
    }

    async onSubmit() {
        this.setState({ loading: true })
        try {
            const data = this.state.addFormData
            data.orderId = this.props.orderId
            const result = await apiV2.orderDefer({ data })
            this.setState({ result })
        } finally {
            this.setState({ loading: false })
        }
    }

    close() {
        this.setState({
            visible: false,
            addFormData: OrderReduction.RemissionAmountEmpty,
            result: OrderReduction.ResultEmpty,
        })
        this.props.onClose()
    }

    render() {
        const { addFormData, result, visible, originalAmount,submitLoading } = this.state
        const isStages = this.props.isStages

        return (
            <Drawer title="订单延期" placement="right" onClose={() => this.close()} visible={visible}>
                <Form labelPosition="top">
                    <Form.Item label="延期数">
                        <Input value={addFormData.dayNum} onChange={(e) => this.changeAddForm('dayNum', e)}
                            placeholder="单位（天）" />
                    </Form.Item>

                    <Form.Item label="延期额">
                        <Tag type="warning">{`还款金额：${(isStages ? originalAmount.agingRepaymentMoney : originalAmount.allRepaymentMoney) || 0}`}</Tag>
                        <Input value={addFormData.deferMoney} onChange={(e) => this.changeAddForm('deferMoney', e)}
                            placeholder="精确到分" />
                    </Form.Item>

                    <Form.Item label="逾期额">
                        <Input value={addFormData.overdueAmount} onChange={(e) => this.changeAddForm('overdueAmount', e)}
                            placeholder="精确到分" />
                    </Form.Item>

                    <Form.Item>
                        <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                    </Form.Item>

                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>)
    }
}
