import React, { Component } from 'react'
import { Table, Switch, Message } from 'element-react'
import apiV2 from '../../api/api-v2'



/**
 * 认证配置
 */
export default class AuthConfig extends Component {

    constructor(props) {
        super(props)
        this.state = {
            list: [],
            columns: [{
                label: '编号',
                prop: 'type'
            },{
                label: '认证类型',
                prop: 'name'
            }, {
                label: '是否开启',
                render: row => {
                    return (<Switch onText="开启" offText="关闭" onChange={this.updateOpen.bind(this, row)} value={row.isOpen} />)
                }
            }, {
                label: '是否必须认证',
                render: row => {
                    return (<Switch onText="是" offText="否" onChange={this.updateEnable.bind(this, row)} value={row.isMustAuth} />)
                }
            }]
        }
    }

    componentWillMount() {
        this.getAuthConfig()
    }
    componentWillUnmount(){}

    async updateSwitch(data, attr) {
        const list = this.state.list.map(item => {
            if (item.type === data.type) {
                item[attr] = !item[attr]
            }
            return item
        })

        const { success, message } = await apiV2.updateAuthConfig({ body: list })

        if (success) {
           
            this.setState({
                list
            })
        } else {
            Message.error(message)
        }

    }

    async updateOpen(data) {
        const attr = 'isOpen'
        this.updateSwitch(data, attr)
    }

    async updateEnable(data) {
        const attr = 'isMustAuth'
        this.updateSwitch(data, attr)
    }

    async getAuthConfig() {
        const { body, success } = await apiV2.getAuthConfig({})

        if (success) {
            this.setState({
                list: body
            })
        }
    }



    render() {
        return (
            <Table columns={this.state.columns} data={this.state.list} />
        )
    }
}