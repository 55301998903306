import React, { Component } from 'react'

import PropTypes from 'prop-types'
import '../styles/text.less'
import { Popover } from 'antd'

/**
 * 认证信息
 */
export default class NumberTextText extends Component {


    static propTypes = {
        size: PropTypes.any,
        color: PropTypes.any,
        children: PropTypes.any,
        onClick: PropTypes.func,
        rich: PropTypes.any,
        visible: PropTypes.bool,
        bold: PropTypes.any
    }

    click() {
        const onClick = this.props.onClick

        if (onClick) {
            onClick()
        }
    }


    render() {
        const { size, color, rich, bold } = this.props
        const className = [rich ? 'text-overflow' : null].join(' ')
        const children = this.props.children

        let htmlContent = children
        const isStrong = typeof children === 'string'
        if (isStrong) htmlContent = this.props.children.toString().replace(new RegExp('\n', 'gm'), '<br/>')
        let fontWeight = null
        if (bold === true) {
            fontWeight = 500
        } else if (bold) {
            fontWeight = bold
        }

        return (
            <Popover content={!isStrong ? htmlContent : <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
                title="详情" trigger="hover">
                <span className={className}
                    onClick={() => this.click()}
                    style={{ fontSize: size, color, fontWeight }}>{children}
                </span>
            </Popover>)
    }
}