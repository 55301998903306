import { Button, Table } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../../api/api-v2'
import PropTypes from 'prop-types'
import { durationFormat } from '../../../utils/format'

/**
 * 米融共债
 */
export default class MiRongGZai extends Component {

    static propTypes = {
        userId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            result: null,
            columns: [
                {
                    label: '时间',
                    prop: 'dataTime',
                    align: 'center',
                    width: 80,
                    fixed: true
                },
                {
                    label: '通讯录名称',
                    prop: 'mobileBookName',
                    align: 'center',
                    width: 140
                },
                {
                    label: '对方号码',
                    prop: 'peerNumber',
                    align: 'center',
                    width: 150
                },
                {
                    label: '通话地',
                    prop: 'peerNumLoc',
                    align: 'center',
                    width: 100
                },
                {
                    label: '号码类型',
                    prop: 'groupName',
                    align: 'center',
                    width: 100
                },
                {
                    label: '号码标识',
                    prop: 'companyName',
                    align: 'center',
                    width: 100
                },
                {
                    label: '通话次数',
                    prop: 'callCnt',
                    align: 'center',
                    width: 140
                },
                {
                    label: '通话时长',
                    prop: 'callTime',
                    align: 'center',
                    width: 120,
                    render: ({ callTime }) => <a>{durationFormat(callTime)}</a>
                },
                {
                    label: '主叫次数',
                    prop: 'dialCnt',
                    align: 'center',
                    width: 150
                },
                {
                    label: '被叫次数',
                    prop: 'dialedCnt',
                    align: 'center',
                    width: 140
                },
                {
                    label: '主叫时长',
                    prop: 'dialTime',
                    align: 'center',
                    width: 120,
                    render: ({ callTime }) => <a>{durationFormat(callTime)}</a>
                },
                {
                    label: '被叫时长',
                    prop: 'dialedTime',
                    align: 'center',
                    width: 120,
                    render: ({ dialedTime }) => <a>{durationFormat(dialedTime)}</a>
                },
            ]
        }
    }

    componentDidMount() {
        this.fetchList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.fetchList()
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async fetchList(userId = this.props.userId) {
        if (userId) {
            const { body } = await apiV2.getMobileFriendData({ userId })
            this.setState({ result: body })
        } else {
            this.setState({ result: null })
        }
    }

    render() {
        const { result, columns } = this.state

        return result ?
            <>
                <Table border columns={columns} data={result} />
            </> : <Button type="danger" click={_ => this.fetchList()}>{'暂无数据点击刷新'}</Button>
    }
}