import { Button, Table, Pagination, Dialog, Message, Progress } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

/**
 * 手机白名单
 */
export default class PhoneWhitelistTask extends Component {

    static EmptyResult = { records: [], total: 0 }

    constructor(props) {
        super(props)

        this.state = {
            columns: [
                {
                    label: "添加日期",
                    prop: "createTime",
                },
                {
                    label: "导入状态",
                    prop: "mobile",
                    render({ finish, abnormal, abnormalMessage, ongoing, cancel }) {
                        const queuing = !finish && !abnormal && !ongoing && !cancel;

                        return (
                            <>
                                {cancel ? <i title='已取消' className="el-icon-delete"></i> : null}
                                {abnormal ? <i title={abnormalMessage} className="el-icon-warning"></i> : null}
                                {finish ? <i title={'已完成'} className="el-icon-check"></i> : null}
                                {ongoing ? <i title={'正在导入'} className="el-icon-loading"></i> : null}
                                {queuing ? '正在排队' : null}
                            </>
                        )
                    }
                },
                {
                    label: "导入结果",
                    prop: "totalNumberEntries",
                    render({ totalNumberEntries, nonRepeatingNumber, finish }) {
                        const isEmptyNumber = (v) => v === null || v === undefined

                        if (isEmptyNumber(totalNumberEntries) || isEmptyNumber(nonRepeatingNumber)) {
                            return <i className="el-icon-loading" />
                        }

                        const percentage = nonRepeatingNumber === 0 ? 0 : ((nonRepeatingNumber / totalNumberEntries) * 100).toFixed(2);
                        return (
                            <>
                                <Progress strokeWidth={18} percentage={percentage || 0}
                                    status={finish ? 'success' : null}
                                    textInside />
                                <span>{`总数量[${totalNumberEntries}]/不重复数[${nonRepeatingNumber}]`}</span>
                            </>)
                    }
                },
                {
                    label: "操作",
                    prop: "id",
                    align: 'center',
                    fixed: 'right',
                    render: row => {
                        const showCancel = !row.cancel && !row.finish;
                        const showRetry = showCancel && row.abnormal;

                        return (
                            <div>
                                {showCancel ? <Button type="text" onClick={() => this.cancel(row)}>{'取消'}</Button> : null}
                                {showRetry ? <Button
                                    loading={this.state.retryLoading}
                                    disabled={this.state.retryLoading}
                                    type="text"
                                    onClick={() => this.retry(row)}>{'重试'}</Button> : null}
                                <Button
                                    disabled
                                    type="text"
                                    onClick={() => this.retry(row)}>{'下载原始文件'}</Button>
                            </div>)
                    }
                }
            ],
            listBody: PhoneWhitelistTask.EmptyResult,
            queryForm: {
                current: 1,
                size: 10
            },
            retryLoading: false
        }
    }

    componentDidMount() {
        this.getList()
        setInterval(() => this.getList(), 2000);
    }

    changeQueryForm(name, value, listRefresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })

        this.setState({ queryForm }, () => {
            if (listRefresh) this.getList()
        })
    }


    async getList() {
        const { body } = await apiV2.getMobileWhitelistTask({ param: this.state.queryForm })
        this.setState({
            listBody: body || PhoneWhitelistTask.EmptyResult
        })
    }

    async cancel({ id: taskId }) {
        const { success, message } = await apiV2.cancelMobileWhitelistTask({ taskId })
        if (!success) {
            return Message.error(message);
        }
        await this.getList();
    }

    async retry({ id: taskId }) {
        this.setState({ retryLoading: true })
        try {
            const { success, message } = await apiV2.retryMobileWhitelistTask({ taskId })
            if (!success) {
                return Message.error(message);
            }
            await this.getList();
        } finally {
            this.setState({ retryLoading: false })
        }
    }

    render() {
        const { queryForm, columns, listBody } = this.state
        return (
            <>
                <Dialog
                    title="白名单上传任务"
                    size="full"
                    lockScroll={false}
                    {...this.props.dialog}
                >

                    <Dialog.Body>
                        <div style={{ 'margin': '1em' }}>
                            <Button onClick={() => this.getList()} type="primary">刷新</Button>
                        </div>
                        <Table columns={columns}
                            data={listBody.records}
                        />
                    </Dialog.Body>
                    <Dialog.Footer className="dialog-footer">
                        <Pagination
                            total={listBody.total}
                            pageSize={queryForm.size} currentPage={queryForm.current}
                            onSizeChange={(v) => this.changeQueryForm('size', v, true)}
                            onCurrentChange={(v) => this.changeQueryForm('current', v, true)}
                        />
                    </Dialog.Footer>
                </Dialog>
            </>
        )
    }
}