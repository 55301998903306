import { Layout, Alert, Progress } from 'element-react'
import React, { Component } from 'react'
import Text from '../../components/text'
import Space from '../../components/space'
import CardHead from '../../components/card_head'
import PropTypes from 'prop-types'

/**
 * 评分报告
 */
export default class ScoringReport extends Component {

    static propTypes = {
        data: PropTypes.array,
        title: PropTypes.any
    }

    render() {

        return (
            <CardHead title={this.props.title}>
                <Layout.Row gutter="20" justify="space-between" align="middle">
                    <Layout.Col span="14">
                        {this.props.data.map(({ label, value, percentage }) => {
                            return (
                                <Space width="0" key={label}>
                                    <Layout.Row gutter="20" justify="space-between" align="middle">
                                        <Layout.Col span="5">
                                            <Text size="0.9rem" color="#CA14B8">{label}</Text>
                                        </Layout.Col>
                                        <Layout.Col span="14">
                                            <Progress strokeWidth={18} percentage={percentage} textInside />
                                        </Layout.Col>
                                        <Layout.Col span="5">
                                            <Text size="0.7rem" color="#146CCA">{value}</Text>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Space>)
                        })}
                    </Layout.Col>
                    <Layout.Col span="10">
                        <Alert title="温馨提示" closable={false} type="warning"
                            description="分值越高，用户的可信度越高！！！"
                            showIcon />
                    </Layout.Col>
                </Layout.Row>
            </CardHead>
        )
    }
}