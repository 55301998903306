import {
  Form,
  DateRangePicker,
  Input,
  Switch,
  Select,
  Button,
  Table,
  Pagination,
  Tag,
} from "element-react";
import React, { Component } from "react";
import apiV2 from "../../api/api-v2";
import Paste from "../../components/paste";
import Money from "../../components/Money";
import {
  OrderType,
  OrderTypeList,
  delayRepaymentUI,
  orderInfoButtonGroup,
} from "./types";
import moment from "moment";

import PropTypes from "prop-types";
import { CommonTableFields, PayableTableFields } from "./assembly/order";

import OperatorInfo from "../member/operator_info";
import UserInfo from "../member/user_info";
import NewUserForm from "./new_user_switch";

import OrderReduction from "./order_reduction";
import OrderRepayTimeForm from "./order_repay_time_form";

import OrderDelay from "./order_delay";
import OrderRepaymentFrom from "./order_repayment_from";
import OrderInfo from "./order_info";
import OrderStages from "./order_stages";

import Condition from "../../components/Condition";
import { DeductionAmountTableColumn } from "../../utils/app";
import ManageUserSelect from "../../components/ManageUserSelect";
import AllocationManageForm from "./assembly/AllocationManageForm";
import { getShareOrderColumns } from "../../components/order-tools";

/**
 * 订单
 */
export default class OrderList extends Component {
  static ListResultEmpty = { records: [], total: 0 };

  static propTypes = {
    overdue: PropTypes.bool,
    fixedRightField: PropTypes.string,
    startTimeExpire: PropTypes.string,
    endTimeExpire: PropTypes.string,
    queryParam: PropTypes.object,
    exportClick: PropTypes.func,
    rquestListFunction: PropTypes.func,
    readOnly: PropTypes.bool,
    exportName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    let shouldRepayAmountFixed = false;
    let overdueAmountFixed = false;

    const fixedRightField = props.fixedRightField || "shouldRepayAmount";

    switch (fixedRightField) {
      case "shouldRepayAmount": {
        shouldRepayAmountFixed = null;
        break;
      }
      case "overdue": {
        overdueAmountFixed = "right";
        break;
      }
      default: {
        console.log(fixedRightField);
      }
    }

    const gr = props.orderAllocation
      ? [
          {
            type: "selection",
            fixed: true,
          },
          {
            label: "分配客服",
            prop: "endTime",
            align: "center",
            width: "150",
            render: ({ becomeDueUser, overDueUser }) => {
              return (
                <>
                  <div>
                    <span>到期提醒:</span>
                    <Tag type={becomeDueUser ? "success" : "warning"}>
                      {becomeDueUser ?? "未分配"}
                    </Tag>
                  </div>
                  {props.overdue ? (
                    <div>
                      <span>催单客服:</span>
                      <Tag type={overDueUser ? "success" : "warning"}>
                        {overDueUser ?? "未分配"}
                      </Tag>
                    </div>
                  ) : null}
                </>
              );
            },
            fixed: true,
          },
        ]
      : [];

    this.state = {
      selectRowId: [],
      allocationManageFormRef: null,
      orderColumns: [
        ...getShareOrderColumns(),
        ...gr,
        DeductionAmountTableColumn,
        {
          label: "用户姓名",
          prop: "userName",
          width: "100",
          align: "center",
          render: ({ userName }) => <Paste content={userName} />,
          fixed: true,
        },
        {
          label: "渠道",
          prop: "channel",
          width: "150",
          align: "center",
          render: ({ channel }) => <Paste content={channel} />,
        },
        {
          label: "借款额度",
          width: "120",
          align: "center",
          prop: "quota",
          render: ({ quota }) => <Money value={quota} />,
        },
        {
          label: "申请借款周期",
          width: "140",
          align: "center",
          prop: "deadlineDay",
          render: ({ deadlineDay }) => <Money value={deadlineDay} />,
        },
        {
          label: "借款次数",
          prop: "num",
          align: "center",
          width: "120",
        },
        {
          label: "约定还款时间",
          prop: "endTime",
          align: "center",
          width: "150",
        },
        {
          label: "手机号",
          prop: "phone",
          align: "center",
          width: "120",
          fixed: true,
        },
        {
          label: "服务费",
          prop: "rateMoney",
          align: "center",
          width: "120",
          render: ({ rateMoney }) => <Money value={rateMoney} />,
        },
        {
          label: "逾期信息",
          prop: "overdueAmount,overdueDay,overdueDerateAmount",
          align: "center",
          width: "120",
          fixed: overdueAmountFixed,
          render: ({ overdueAmount, overdueDay }) =>
            delayRepaymentUI("逾期", overdueAmount, overdueDay, 0),
        },
        {
          label: "延期信息",
          prop: "deferredAmount,deferredDay",
          align: "center",
          width: "120",
          render: ({ deferredAmount, deferredDay }) =>
            delayRepaymentUI("延期", deferredAmount, deferredDay),
        },

        {
          label: "订单类型",
          prop: "type",
          width: "120",
          align: "center",
          render: ({ type }) => {
            const that = OrderType[type];
            return <Tag type={that.theme}>{that.name}</Tag>;
          },
        },
        {
          label: "是否新客",
          prop: "num",
          width: "120",
          align: "center",
          render: ({ num }) => {
            return num > 0 ? (
              <i className="el-icon-close" />
            ) : (
              <i color="red" className="el-icon-check" />
            );
          },
        },
        ...CommonTableFields,
        ...PayableTableFields,
        // {
        //     label: '雷达分',
        //     prop: 'kaiqiScore',
        //     width: '120',
        //     align: 'center',
        //     render: ({ kaiqiScore }) => <Money value={kaiqiScore} />
        // },
        // {
        //     label: '米融分',
        //     prop: 'zhimiScore',
        //     width: '120',
        //     align: 'center',
        //     render: ({ zhimiScore }) => <Money value={zhimiScore} />
        // },
        {
          label: "下单时间",
          prop: "createTime",
          width: "140",
          align: "center",
        },
        {
          label: "到账金额",
          prop: "arrivalAmount",
          align: "center",
          width: "120",
          render: ({ arrivalAmount }) => <Money value={arrivalAmount} />,
        },
        {
          label: "应还金额",
          prop: "shouldRepayAmount",
          align: "center",
          fixed: shouldRepayAmountFixed,
          width: "120",
          render: ({ shouldRepayAmount }) => (
            <Money value={shouldRepayAmount} />
          ),
        },
        {
          label: "放款时间",
          prop: "remitTime",
          align: "center",
          width: "150",
        },
        {
          label: "操作",
          prop: "id",
          fixed: "right",
          align: "center",
          width: "150",
          render: (row) => {
            const isStages = row.type === 3;
            const { userId, orderId, orderDetailId } = row;
            return this.props.readOnly ? (
              orderInfoButtonGroup(this, orderId, userId)
            ) : (
              <>
                {orderInfoButtonGroup(this, orderId, userId)}

                {isStages ? (
                  <Button
                    type="text"
                    size="mini"
                    onClick={() => this.setState({ stagesId: orderId })}
                  >
                    {"分期金额"}
                  </Button>
                ) : null}
                <Button
                  type="text"
                  size="mini"
                  onClick={() => this.setState({ reductionId: orderId })}
                >
                  {"减免"}
                </Button>
                <Button
                  type="text"
                  size="mini"
                  onClick={() => this.setState({ delayId: orderId })}
                >
                  {"延期"}
                </Button>
                <Button
                  type="text"
                  size="mini"
                  onClick={() =>
                    this.setState({ clickOrder: row, repaymentId: orderId })
                  }
                >
                  {"还款"}
                </Button>
                <Button
                  type="text"
                  size="mini"
                  onClick={() =>
                    this.setState({ uptRepayTimeId: orderDetailId })
                  }
                >
                  {"修改还款时间"}
                </Button>
              </>
            );
          },
        },
        {
          label: "ID",
          prop: "orderId",
          width: "60",
          align: "center",
        },
      ],
      orderList: OrderList.ListResultEmpty,
      queryForm: {
        orderCode: "",
        size: 10,
        current: 1,
        type: "",
        isNewUser: null,
        beginAuditTime: null,
        endAuditTime: null,
        beginRemitTime: null,
        endRemitTime: null,
        beginTime: props.startTimeExpire,
        endTime: props.endTimeExpire,
        channel: "",
        phone: "",
        repaymentBeginTime: null,
        repaymentEndTime: null,
        overdueStatus: props.overdue || false,
        uptRepayTimeId: null,
        isAllocation: false,
        manageId: null,
      },
      stagesId: null,
      userInfoId: null,
      phoneInfoId: null,
      reductionId: null,
      delayId: null,
      repaymentId: null,
      orderInfoId: null,
      clickOrder: {},
    };
  }

  componentDidMount() {
    this.loadRequest();
  }

  componentDidUpdate(preProps) {
    if (preProps.queryParam !== this.props.queryParam) {
      this.loadRequest();
    }
  }

  //组件销毁前调用，清除一些事件(比如定时事件)
  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  allocationApi(data) {
    return (
      this.props.overdue
        ? apiV2.batchAllocationOverOrder
        : apiV2.batchAllocationOrder
    )({ data });
  }

  hExportClick() {
    const exportClick = this.props.exportClick;
    if (exportClick) exportClick(this.state.queryForm);
    else {
      apiV2.exportOrderReimbursementList({
        data: this.state.queryForm,
        isDownload: true,
        fileName: this.props.exportName,
      });
    }
  }

  loadRequest() {
    const queryParam = this.props.queryParam;
    if (!queryParam) {
      this.getOrderList();
    } else {
      this.setState(
        Object.assign(this.state.queryForm, queryParam),
        this.getOrderList
      );
    }
  }

  async getOrderList() {
    const rquestListFunction =
      this.props.rquestListFunction || apiV2.getOrderReimbursementList;
    const { body } = await rquestListFunction({ data: this.state.queryForm });
    this.setState({ orderList: body || OrderList.ListResultEmpty });
  }

  changeQueryForm(name, value, formatFn, listRefresh) {
    if (formatFn) value = formatFn(value);

    const queryForm = Object.assign(this.state.queryForm, { [name]: value });
    this.setState({ queryForm }, () => {
      if (listRefresh) this.getOrderList();
    });
  }

  datesAdapterElUI(date1String, date2String) {
    if (!date1String || !date2String) return [null, null];
    return [new Date(date1String), new Date(date2String)];
  }

  changeQueryFormDate(value, nameStart, nameEnd) {
    const format = "YYYY-MM-DD";
    let start = null,
      end = null;
    if (value && value.length > 1) {
      start = moment(value[0]).format(format);
      end = moment(value[1]).format(format);
    }

    const queryForm = Object.assign(this.state.queryForm, {
      [nameStart]: start,
      [nameEnd]: end,
    });
    this.setState({ queryForm });
  }

  onRepayment() {
    this.setState({ repaymentId: null });
    this.getOrderList();
  }

  get orderInstallment() {
    return this.state.clickOrder.type === 3;
  }

  render() {
    const { orderColumns, orderList, queryForm } = this.state;
    let { queryParam } = this.props;
    queryParam = queryParam || {};

    return (
      <>
        <Form inline>
          <Form.Item label="订单号">
            <Input
              value={queryForm.orderCode}
              onChange={(e) => this.changeQueryForm("orderCode", e)}
            />
          </Form.Item>

          <Form.Item label="用户姓名">
            <Input
              value={queryForm.userName}
              onChange={(e) => this.changeQueryForm("userName", e)}
            />
          </Form.Item>

          <Form.Item label="手机号">
            <Input
              value={queryForm.phone}
              onChange={(e) => this.changeQueryForm("phone", e)}
            />
          </Form.Item>

          <Form.Item label="渠道号">
            <Input
              value={queryForm.channel}
              onChange={(e) => this.changeQueryForm("channel", e)}
            />
          </Form.Item>

          <Form.Item label="类型">
            <Select
              value={queryForm.type}
              clearable
              onChange={(e) => this.changeQueryForm("type", e)}
            >
              {OrderTypeList.map(({ key, name }) => (
                <Select.Option key={key} label={name} value={key} />
              ))}
            </Select>
          </Form.Item>

          <Condition
            visible={
              queryParam &&
              (queryParam.isNewUser === null ||
                queryParam.isNewUser === undefined)
            }
            tag="span"
          >
            <Form.Item label="查询新老客">
              <NewUserForm
                value={queryForm.isNewUser}
                onChangeValue={(e) => this.changeQueryForm("isNewUser", e)}
              />
            </Form.Item>
          </Condition>

          <Form.Item style={{ display: "none" }}>
            <Switch
              value={queryForm.overdueStatus}
              onChange={(e) => this.changeQueryForm("overdueStatus", e)}
              onText="逾期"
              offText="未逾 "
            />
          </Form.Item>

          <Form.Item label="审核时间">
            <DateRangePicker
              value={this.datesAdapterElUI(
                queryForm.beginAuditTime,
                queryForm.endAuditTime
              )}
              onChange={(e) =>
                this.changeQueryFormDate(e, "beginAuditTime", "endAuditTime")
              }
            />
          </Form.Item>

          <Condition
            visible={
              queryParam &&
              (!queryParam.beginRemitTime || !queryParam.endRemitTime)
            }
            tag="span"
          >
            <Form.Item label="放款时间">
              <DateRangePicker
                value={this.datesAdapterElUI(
                  queryForm.beginRemitTime,
                  queryForm.endRemitTime
                )}
                onChange={(e) =>
                  this.changeQueryFormDate(e, "beginRemitTime", "endRemitTime")
                }
              />
            </Form.Item>
          </Condition>

          <Condition
            visible={
              queryParam &&
              (!queryParam.repaymentBeginTime || !queryParam.repaymentEndTime)
            }
            tag="span"
          >
            <Form.Item label="还款时间">
              <DateRangePicker
                value={this.datesAdapterElUI(
                  queryForm.repaymentBeginTime,
                  queryForm.repaymentEndTime
                )}
                onChange={(e) =>
                  this.changeQueryFormDate(
                    e,
                    "repaymentBeginTime",
                    "repaymentEndTime"
                  )
                }
              />
            </Form.Item>
          </Condition>

          <Condition
            visible={!this.props.startTimeExpire && !this.props.endTimeExpire}
            tag="span"
          >
            <Form.Item label="下单时间">
              <DateRangePicker
                value={this.datesAdapterElUI(
                  queryForm.beginTime,
                  queryForm.endTime
                )}
                onChange={(e) =>
                  this.changeQueryFormDate(e, "beginTime", "endTime")
                }
              />
            </Form.Item>
          </Condition>

          {/* <Form.Item label='是否分配管理员'>
                        <Switch value={queryForm.isAllocation}
                            onChange={(e) => this.changeQueryForm('isAllocation', e)}
                            onText="是" offText="否" />
                    </Form.Item> */}

          {this.props.orderAllocation ? (
            <Form.Item label="已分配管理员">
              <ManageUserSelect
                value={queryForm.manageId}
                onChange={(e) => this.changeQueryForm("manageId", e)}
              />
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button onClick={() => this.getOrderList()} type="primary">
              {"搜索"}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={() => this.hExportClick()} type="warning">
              {"导出"}
            </Button>
          </Form.Item>

          {this.state.selectRowId.length ? (
            <Form.Item>
              <Button
                onClick={() => this.state.allocationManageFormRef.open()}
                type="success"
              >
                {"分配管理员"}
              </Button>
            </Form.Item>
          ) : null}

          <Table
            border
            columns={orderColumns}
            data={orderList.records}
            onSelectChange={(x) =>
              this.setState({ selectRowId: x.map(({ orderId }) => orderId) })
            }
          />

          <Pagination
            total={orderList.total}
            current={queryForm.current}
            size={queryForm.size}
            onCurrentChange={(e) =>
              this.changeQueryForm("current", e, null, true)
            }
            onSizeChange={(e) => this.changeQueryForm("size", e, null, true)}
          />

          <OrderInfo
            orderId={this.state.orderInfoId}
            onClose={() => this.setState({ orderInfoId: null })}
          />
          <OrderRepaymentFrom
            showRepaymentMethod={this.orderInstallment}
            orderId={this.state.repaymentId}
            onClose={() => this.onRepayment()}
          />
          <OrderDelay
            isStages={this.orderInstallment}
            orderId={this.state.delayId}
            onClose={() => this.setState({ delayId: null })}
          />
          <OrderReduction
            isStages={this.orderInstallment}
            orderId={this.state.reductionId}
            onClose={() => this.setState({ reductionId: null })}
          />
          <OperatorInfo
            userId={this.state.phoneInfoId}
            onClose={() => this.setState({ phoneInfoId: null })}
          />
          <UserInfo
            userId={this.state.userInfoId}
            onClose={() => this.setState({ userInfoId: null })}
          />

          <OrderRepayTimeForm
            orderId={this.state.uptRepayTimeId}
            onClose={() => this.setState({ uptRepayTimeId: null })}
          />
          <OrderStages
            tab="2"
            orderId={this.state.stagesId}
            onClose={() => this.setState({ stagesId: null })}
          />
        </Form>

        <AllocationManageForm
          onRef={(allocationManageFormRef) =>
            this.setState({ allocationManageFormRef })
          }
          onSubmit={(adminUserId) =>
            this.allocationApi({
              adminUserId,
              orderIdList: this.state.selectRowId,
            })
          }
          onSuccess={() => {
            this.getOrderList();
            this.setState({ selectRowId: [] });
          }}
        />
      </>
    );
  }
}
