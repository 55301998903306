import { Button, Form, Input, Layout, Table, Tag } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

import MyPagination from '../../components/MyPagination'
import PropTypes from 'prop-types'

/**
 * 用户短信列表
 */
export default class UserSmsList extends Component {

    static ListResiltEmpty = { records: [], total: 0 }
    static propTypes = {
        userId: PropTypes.number,
    }

    static TAGs = [
        {
            name: '正面影响',
            list: ['结清', '您的还款', '已还', '成功', '代扣成功',]
        },
        {
            name: '疑似负面影响',
            list: ['通讯录', '来电提醒', '拖欠', '还款', '拨打您的手机', '立案', '境外来电', '不还了', '投诉', '困难', '不准备还', '催收', '留言', '逾期', '上门催', '协商']
        },
        {
            name: '负面影响',
            list: ['通讯录', '超出还款期限', '拖欠', '已经逾期', '严重逾期', '立案', '超期', '已逾',]
        }
    ]

    constructor(props) {
        super(props)

        this.state = {
            listResilt: UserSmsList.ListResiltEmpty,
            queryParam: {
                current: 1,
                size: 10,
                key: '',
                userId: props.userId
            },
            columnsTable: [
                {
                    label: '手机号',
                    prop: 'mobile',
                    width: '150',
                    align: 'center',
                    fixed: true
                }, {
                    label: '创建时间',
                    prop: 'createTime',
                    width: '200',
                    align: 'center',
                }, {
                    label: '内容',
                    prop: 'context',
                    align: 'center',
                }, {
                    label: '添加时间',
                    prop: 'smsTime',
                    align: 'center',
                }
            ],
            tags: UserSmsList.TAGs
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.changeQueryParam('userId', this.props.userId)
            this.fetchSMSFindKeys()
        }
    }
    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async fetchSMSFindKeys(userId = this.props.userId) {
        if (userId) {
            const { body } = await apiV2.getSMSFindKeys({ userId })
            if (body) {
                const listSMS = []
                for (const key of Object.keys(body)) {
                    const item = {}
                    const names = { doubtIsNegative: '负面影响', negative: '疑似负面影响', positiveInfluence: '正面影响' }
                    item.name = names[key]
                    const list = body[key]
                    item.list = list//list.map(it => it.key)
                    listSMS.push(item)
                }
                this.setState({ tags: listSMS })
            } else {
                this.setState({ tags: UserSmsList.TAGs })
            }
        } else {
            this.setState({ tags: UserSmsList.TAGs })
        }
    }

    async getUserSmsDataList() {
        const { body } = await apiV2.getUserSmsDataList({ data: this.state.queryParam })
        this.setState({ listResilt: body || UserSmsList.ListResiltEmpty })
    }

    changeQueryParam(name, value, disbaleRf) {
        const queryParam = Object.assign(this.state.queryParam, { [name]: value })
        this.setState(queryParam, () => {
            if (!disbaleRf) this.getUserSmsDataList()
        })
    }

    render() {
        const { columnsTable, queryParam, listResilt } = this.state

        return (
            <>
                <Form>
                    <Form.Item>
                        {this.state.tags.map(({ name, list }) => {
                            return (
                                <Layout.Row gutter="10" key={name} type="flex" justify="start">
                                    <Layout.Col span="3">{name}</Layout.Col>
                                    <Layout.Col span="22">
                                        {list.map(item => {
                                            let s = item
                                            let v = 0
                                            if (item instanceof Object) {
                                                s = item.key
                                                v = item.value
                                            }
                                            return (
                                                <span onClick={_ => this.changeQueryParam('key', s)} style={{ marginLeft: '.2rem' }} key={s}>
                                                    <Tag>{`${s}`}<span style={{ color: 'red' }}>{`(${v})`}</span></Tag>
                                                </span>)
                                        })}
                                    </Layout.Col>
                                </Layout.Row>)
                        })}
                    </Form.Item>

                    <Form inline>

                        <Form.Item label="关键字过滤">
                            <Input value={queryParam.key} onChange={v => this.changeQueryParam('key', v, true)} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={_ => this.getUserSmsDataList()}>{'点击查询'}</Button>
                        </Form.Item>
                    </Form>
                </Form>

                <Table border columns={columnsTable} data={listResilt.records} />
                <MyPagination total={listResilt.total} pageNumber={queryParam.current} pageSize={queryParam.size}
                    onCurrentChange={v => this.changeQueryParam('current', v)}
                    onSizeChange={v => this.changeQueryParam('size', v)} />
            </>)
    }

}