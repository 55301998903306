import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MonthLoanCmountChat12 from './12_month_loan_amount_chat'
import LendersChat from './lenders_chat'
import NumberOverdueLoansChat from './number_overdue_loans_chat'
import OverdueAmountChat from './overdue_amount_chat'

import { Layout } from 'element-react'

/**
 * 放款还款图表
 */
export default class LoanRepaymentChat extends Component {

    static propTypes = {
        month12LoanCmountData: PropTypes.any,
        lendersData: PropTypes.any,
        numberOverdueLoansData: PropTypes.any,
        overdueAmountData: PropTypes.any,
    }


    render() {

        return (
            <>
                <Layout.Row gutter="20" justify="space-between" align="middle">
                    <Layout.Col span="8">
                        <MonthLoanCmountChat12 data={this.props.month12LoanCmountData} />
                    </Layout.Col>
                    <Layout.Col span="8">
                        <LendersChat data={this.props.lendersData} />
                    </Layout.Col>
                    <Layout.Col span="8">
                        <NumberOverdueLoansChat data={this.props.numberOverdueLoansData} />
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20" justify="space-between" align="middle">
                    <Layout.Col span="8">
                        <OverdueAmountChat data={this.props.overdueAmountData} />
                    </Layout.Col>
                </Layout.Row>
            </>)
    }
}