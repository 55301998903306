import { Button, Form, Input, Message, Table } from 'element-react'
import React, { Component } from 'react'
import Pagination from '../../components/MyPagination'
import apiV2 from '../../api/api-v2'
import RidkUserForm from './user_form'
import EnabkeStatus from '../../components/EnabkeStatus'
import RiskPopup from './risk_popup'

export default class RiskUser extends Component {


    static ResultEmpty = {
        records: [], total: 0
    }

    constructor(props) {
        super(props)


        this.state = {
            childRefs: React.createRef(),
            riskPopupRef: React.createRef(),
            radarData: null,
            probeCData: null,
            currentUser: null,

            queryForm: {
                idCardNum: '',
                current: 1,
                size: 10,
                name: '',
                mobile: ''
            },
            tableResult: RiskUser.ResultEmpty,
            tableColumns: [
                {
                    label: '#',
                    prop: 'id',
                    width: 100,
                    align: 'center',
                    fixed: true,
                },
                {
                    label: '姓名',
                    prop: 'realUserName',
                    width: 80,
                    align: 'center',
                    fixed: true
                },
                {
                    label: '手机号',
                    prop: 'mobile',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '身份证号',
                    prop: 'cardNum',
                    width: 250,
                    align: 'center',
                },
                {
                    label: '添加时间',
                    prop: 'createTime',
                    width: 160,
                    align: 'center',
                },
                // {
                //     label: '修改时间',
                //     prop: 'uptTime',
                //     width: 160,
                //     align: 'center',
                // },
                {
                    label: '雷达状态',
                    prop: 'ldStatus',
                    width: 100,
                    align: 'center',
                    fixed: 'right',
                    render: ({ ldStatus }) => <EnabkeStatus enable={ldStatus} />
                },
                {
                    label: '探针C状态',
                    prop: 'tzStatus',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                    render: ({ tzStatus }) => <EnabkeStatus enable={tzStatus} />
                },
                {
                    label: '操作',
                    prop: 'row',
                    width: 100,
                    align: 'center',
                    fixed: 'right',
                    render: (row) => <Button type="text" onClick={() => this.openRiskPopup(row)}>{'风控报告'}</Button>
                },

            ]
        }

        this.serverGet()
    }

    openForm() {
        this.state.childRefs.current.open()
    }

    async refreshRiskProbe(showTip, adminId = this.state.currentUser) {
        const { body: c, message, success } = await apiV2.getAdminUserRiskC({ userId: adminId })
        if (!success && showTip) Message.error(message)

        this.openRiskPopup({ id: this.state.currentUser })
        return c
    }
    async refreshRiskRader(showTip, adminId = this.state.currentUser) {
        const { body: rader, message, success } = await apiV2.getAdminUserRiskRadar({ userId: adminId })
        if (!success && showTip) Message.error(message)

        this.openRiskPopup({ id: this.state.currentUser })
        return rader
    }

    async openRiskPopup({ id: adminId }) {
        const c = (await apiV2.adminUserRiskCRefresh({ userId: adminId })).body
        const rader = (await apiV2.adminUserRiskRadarRefresh({ userId: adminId })).body
        
        this.setState({ radarData: rader, probeCData: c, currentUser: adminId })
        this.state.riskPopupRef.current.open()
    }

    async serverGet() {
        const { body } = await apiV2.getAdminUserRisk({ query: this.state.queryForm })
        this.setState({ tableResult: body || RiskUser.ResultEmpty })
    }

    changeQueryForm(name, value, refresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (refresh) this.serverGet() })
    }

    render() {
        const { tableResult, tableColumns, queryForm, radarData, probeCData } = this.state
        return (
            <>
                <Form inline>
                    <Form.Item label="身份证号">
                        <Input value={queryForm.idCardNum}
                            onChange={(e) => this.changeQueryForm('idCardNum', e)} />
                    </Form.Item>
                    <Form.Item label="姓名">
                        <Input value={queryForm.name}
                            onChange={(e) => this.changeQueryForm('name', e)} />
                    </Form.Item>
                    <Form.Item label="手机号">
                        <Input value={queryForm.mobile}
                            onChange={(e) => this.changeQueryForm('mobile', e)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.serverGet()}>{'查询'}</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.openForm()}>{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={tableColumns} border data={tableResult.records} />

                <Pagination total={tableResult.total} pageNumber={queryForm.current} pageSize={queryForm.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />

                <RidkUserForm ref={this.state.childRefs} onSubmittedSuccessfully={() => this.serverGet()} />
                <RiskPopup ref={this.state.riskPopupRef} radarData={radarData} probeCData={probeCData}
                    onRefreshProbe={() => this.refreshRiskProbe(true)}
                    onRefreshRadar={() => this.refreshRiskRader(true)} />
            </>
        )
    }
}