/*
 * @Author: FATE boss@fellw.com
 * @Date: 2023-11-20 11:52:33
 * @LastEditors: FATE boss@fellw.com
 * @LastEditTime: 2023-12-09 16:19:14
 * @FilePath: \credit-loan-admin-repeat\src\utils\app.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Tag } from 'element-react'
import React from 'react'

export const APP_NAME = process.env.REACT_APP_NAME
export const ACCOUNT_MODE = process.env.REACT_APP_ACCOUNT_MODE

/**
 * VERIFYCODE：短信验证码登录 PASSWORD:密码登录
 */
export const LOGIN_MODE = process.env.REACT_APP_LOGIN_MODE


export const PAY_METHOD = process.env.REACT_APP_PAY_METHOD

export const REACT_APP_ENABLE_HFB_RECHARGE = process.env.REACT_APP_ENABLE_HFB_RECHARGE === 'true'

export const ENABLE_DT_1 = process.env.REACT_APP_ENABLE_DT_1 === 'true'
export const ENABLE_DT_2 = process.env.REACT_APP_ENABLE_DT_2 === 'true'
export const ENABLE_GZ = process.env.REACT_APP_ENABLE_GZ === 'true'
export const ENABLE_GZ_V2 = process.env.REACT_APP_ENABLE_GZ_V2 === 'true'
export const ENABLE_FR = process.env.REACT_APP_ENABLE_FR === 'true'

// LOGO
export const APP_LOGO_SRC = process.env.REACT_APP_LOGO_SRC
export const APP_LOGO_TEXT = process.env.REACT_APP_LOGO_TEXT === 'true'
export const SYS_MODE = process.env.REACT_APP_SYS_MODE

export const HIDE_ORDER_NO = process.env.REACT_APP_HIDE_ORDER_NO === 'true'


// 是否贷款超市
export const IsLoanSupermarket = SYS_MODE === 'LoanSupermarket'

// 是否启用QL支付
export const QL_PAY_ENABLE = process.env.REACT_APP_QL_PAY_ENABLE === 'true'

// 是否启用QL支付
export const ONE_PAY_ENABLE = process.env.REACT_APP_ONE_PAY_ENABLE === 'true'

// 是否启用QL支付
export const DUOLA_PAY_ENABLE = process.env.REACT_APP_DUOLA_PAY_ENABLE === 'true'

// 是否启用JST支付
export const JST_PAY_ENABLE = process.env.REACT_APP_JST_PAY_ENABLE === 'true'
// 是否启用回付宝支付
export const HFB_PAY_CLOSE = process.env.REACT_APP_HFB_PAY_CLOSE === 'true'
// 是否启用奥创支付
export const AOC_PAY_CLOSE = process.env.REACT_APP_AOC_PAY_CLOSE === 'true'
// 是否启用王者支付
export const AKing_PAY_ENABLE = process.env.REACT_APP_AKing_PAY_ENABLE === 'true'

// 是否启用BAIJING_PAY
export const BAIJING_PAY_ENABLE = process.env.REACT_APP_BAIJING_PAY_ENABLE === 'true'
// U代付
export const UDF_PAY_ENABLE = process.env.REACT_APP_UDF_PAY_ENABLE === 'true'
// 中东支付
export const ZHONGDONG_PAY_ENABLE = process.env.REACT_APP_ZHONGDONG_PAY_ENABLE === 'true'

// 牛牛支付
export const CATTLE2X_PAY_ENABLE = process.env.REACT_APP_CATTLE2X_PAY_ENABLE === 'true'

// 导入白名单模式
export const IMPOER_MOBILE_MODE = process.env.REACT_APP_IMPOER_MOBILE_MODE || 'default'

export const columnRender = (row, keyName) => {
    const value = row[keyName]
    const columnRenderFunction = row[keyName + 'Render']
    const overallRender = row.overallRender

    if (columnRenderFunction) {
        return columnRenderFunction(value)
    } else if (overallRender) {
        return overallRender(value)
    } else {
        return value
    }
}

/**
 第一步：fetch接口获取其内容转化为blob对象；
 第二部：将blob对象使用reateObjectURL转化为ObjectURL，这个相等于下载地址的链接；
 第三步：创建一个a标签，并赋予ObjectURL，且执行一次click；
 第四步：通过revokeObjectURL回收ObjectURL；
*/
export function blobDownloadFiles(blob, fileName = new Date().toString()) {
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([blob]))
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
}

// 减免金额列
export const DeductionAmountTableColumn = {
    label: '减免金额',
    prop: 'derateAmount&overdueDerateAmount',
    align: 'center',
    width: '150',
    fixed: 'right',
    render: ({ overdueDerateAmount, derateAmount, overdueMitigateMoney } = {}) => {
        return (
            <>
                <Tag type="primary">{`本金减免:${derateAmount || 0}元`}</Tag>
                <Tag type="warning">{`逾期减免:${overdueMitigateMoney || overdueDerateAmount || 0}元`}</Tag>
            </>
        )
    }
}

export function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substring(1).match(reg)
    if (r != null) {
        return decodeURIComponent(r[2])
    }
    return ''
}