// 报表统计-推广渠道
import { Button, DatePicker, Form, Input, Table } from 'element-react'
import React, { Component } from 'react'
import Pagination from '../../components/MyPagination'
import Text from '../../components/text'
import Money from '../../components/Money'
import apiV2 from '../../api/api-v2'
import moment from 'moment'
import PropTypes from 'prop-types'
import { IsLoanSupermarket } from '../../utils/app'

export default class PromotionChannels extends Component {

    static propTypes = {
        tableColumn: PropTypes.array,
        getListFunction: PropTypes.func,
        queryParamHandle: PropTypes.func,
        defaultParam: PropTypes.object
    }


    static ResultEmpty = {
        records: [], total: 0
    }

    constructor(props) {
        super(props)

        const propTableColumn = props.tableColumn || []
        const defaultParam = props.defaultParam || {}

        this.state = {
            queryForm: Object.assign({
                channel: '',
                current: 1,
                lastFindTime: null,
                size: 10
            }, defaultParam),
            tableResult: PromotionChannels.ResultEmpty,
            tableColumns: [
                ...propTableColumn,
                {
                    label: '统计时间',
                    prop: 'createTime',
                    width: 140,
                    align: 'center',
                    fixed: true,
                    render: ({ createTime }) => {
                        return <span onClick={() => this.goDay(createTime)}><Text bold color="blue">{moment(createTime).format('YYYY-MM-DD')}</Text></span>
                    }
                },
                {
                    label: 'PV',
                    prop: 'pv',
                    width: 120,
                    align: 'center',
                    render: ({ pv }) => <Text size="1.3rem" color="blue" bold>{pv}</Text>
                }, {
                    label: 'UV',
                    prop: 'uv',
                    width: 120,
                    align: 'center',
                    render: ({ uv }) => <Text size="1.3rem" color="blue" bold>{uv}</Text>
                },
                {
                    label: '注册人数',
                    prop: 'registerNum',
                    width: IsLoanSupermarket ? null : 140 ,
                    align: 'center',
                    render: ({ registerNum, qqNum, wxNum }) => {
                        const split = () => IsLoanSupermarket ? ' ' : <br />
                        return (
                            <>
                                <Text color="red" size="1rem" bold>{registerNum}</Text>{split()}
                                <Text color="green" size=".6rem" bold>{'QQ渠道：'}{qqNum}</Text>{split()}
                                <Text color="green" size=".6rem" bold>{'微信渠道：'}{wxNum}</Text>{split()}
                                <Text color="green" size=".6rem" bold>{'其他渠道：'}{registerNum - (qqNum + wxNum)}</Text>
                            </>
                        )
                    }
                },
                ...IsLoanSupermarket ? [] : [
                    {
                        label: '登录人数',
                        prop: 'loginNum',
                        width: 100,
                        align: 'center',
                        render: ({ loginNum }) => <Text color="green" size="1.1rem" bold>{loginNum}</Text>,
                    },
                    {
                        label: '登录比例',
                        prop: 'loginRatio',
                        align: 'center',
                        width: 120,
                        render: ({ loginRatio }) => <Money percentage value={loginRatio} />
                    },
                    {
                        label: '实名数(成功/失败)',
                        prop: 'registerNum',
                        width: 220,
                        align: 'center',
                        render: ({ idCardFalseNum, idCardNum }) => {
                            return (
                                <>
                                    <Text color="green" size="1.2rem" bold>{idCardNum}</Text>{'/'}
                                    <Text color="red" size="1.2rem" bold>{idCardFalseNum}</Text><br />
                                </>
                            )
                        }
                    },
                    {
                        label: '个人认证数',
                        width: 150,
                        align: 'center',
                        render: ({ grNum }) => {
                            return (
                                <>
                                    <Text color="green" size="1rem" bold>{grNum}</Text>
                                </>
                            )
                        }
                    },
                    {
                        label: '运营商数(成功/失败)',
                        width: 200,
                        align: 'center',
                        render: ({ mobileFalseNum, mobileNum }) =>
                            <Text color="green" size="1.1rem" bold>{mobileNum}{'/'}
                                <Text color="red" size="1.1rem" bold>{mobileFalseNum}</Text>
                            </Text>
                    },

                    {
                        label: '银行卡认证数',
                        width: 150,
                        align: 'center',
                        render: ({ bankNum, bankFalseNum }) =>
                            <Text color="green" size="1.1rem" bold>{bankNum}{'/'}
                                <Text color="red" size="1.1rem" bold>{bankFalseNum}</Text>
                            </Text>
                    },
                    {
                        label: '资料完成人数',
                        prop: 'authNum',
                        width: 140,
                        align: 'center',
                        render: ({ authNum }) => <Text color="orange" size="1.1rem" bold>{authNum}</Text>
                    },
                    {
                        label: '黑名单数',
                        prop: 'registerNum',
                        width: 150,
                        align: 'center',
                        render: ({ idCardBlackNum, mobileBlackNum, areaBlackNum }) => {
                            return (
                                <>
                                    <Text color="green" size=".6rem" bold>{'身份证：'}{idCardBlackNum || 0}</Text><br />
                                    <Text color="green" size=".6rem" bold>{'手机：'}{mobileBlackNum || 0}</Text><br />
                                    <Text color="green" size=".6rem" bold>{'区域：'}{areaBlackNum || 0}</Text>
                                </>
                            )
                        }
                    },
                    {
                        label: '申请人数',
                        prop: 'applayNum',
                        width: 120,
                        align: 'center',
                        render: ({ applayNum }) => <Text color="green" size="1.1rem" bold>{applayNum}</Text>
                    },
                    {
                        label: '申请比例',
                        prop: 'applayRatio',
                        width: 120,
                        align: 'center',
                        render: ({ applayRatio }) => <Money percentage value={applayRatio} />
                    },

                    {
                        label: '放款人数',
                        prop: 'loanNum',
                        align: 'center',
                        width: 120,
                        render: ({ loanNum }) => <Text color="green" size="1.1rem" bold>{loanNum}</Text>
                    },
                    {
                        label: '放款比例',
                        prop: 'loanRatio',
                        align: 'center',
                        width: 120,
                        render: ({ loanRatio }) => <Money percentage value={loanRatio} />
                    },
                    // {
                    //     label: '获客成本',
                    //     prop: 'costPrice',
                    //     align: 'center',
                    //     width: 120,
                    //     render: ({ costPrice }) => <Money value={costPrice} />
                    // },
                ]

            ]
        }

        this.serverGet()
    }

    goDay(date) {
        window.location.href = `?c_s_d=${date}/#/system/channel_statistics_day`
    }


    async serverGet() {
        const queryForm = JSON.parse(JSON.stringify(this.state.queryForm))
        if (queryForm.lastFindTime) {
            queryForm.lastFindTime = moment(queryForm.lastFindTime).format('YYYY-MM-DD')
        }
        const queryParamHandle = this.props.queryParamHandle
        if (queryParamHandle) queryParamHandle(queryForm)

        const getFunc = this.props.getListFunction || apiV2.getChannelStatistics
        const { body } = await getFunc({ query: queryForm })
        this.setState({ tableResult: body || PromotionChannels.ResultEmpty })
    }

    changeQueryForm(name, value, refresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (refresh) this.serverGet() })
    }

    render() {
        const { tableResult, tableColumns, queryForm } = this.state
        return (
            <>
                <Form inline>
                    <Form.Item label="渠道名称">
                        <Input value={queryForm.channel}
                            onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>
                    <Form.Item label="查询日期">
                        <DatePicker value={queryForm.lastFindTime}
                            onChange={(e) => this.changeQueryForm('lastFindTime', e, false,)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.serverGet()}>{'查询'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={tableColumns} data={tableResult.records} />

                <Pagination total={tableResult.total} pageNumber={queryForm.current} pageSize={queryForm.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />
            </>
        )
    }
}