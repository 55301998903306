import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Space from '../../components/space'
import { Layout } from 'element-react'
import QueryStatisticsCreditInstitutions from './query_statistics_credit_institutions'
import { Statistic } from 'antd'


/**
 * 评分报告
 */
export default class CreditChart extends Component {

    static propTypes = {
        creditInstitutions: PropTypes.array,
        report: PropTypes.object
    }


    render() {
        let { currentReportDetail, behaviorReportDetail, applyReportDetail } = this.props.report
        currentReportDetail = currentReportDetail || {}
        behaviorReportDetail = behaviorReportDetail || {}
        applyReportDetail = applyReportDetail || {}

        return (
            <Space width="0.2rem">
                <Layout.Row gutter="20" justify="space-between" align="middle">
                    <Layout.Col span="8">
                        <QueryStatisticsCreditInstitutions data={this.props.creditInstitutions} />
                    </Layout.Col>
                    <Layout.Col span="3">
                        <Statistic title="申请命中机构数" value={applyReportDetail.a22160003} />
                        <Statistic title="申请命中消金机构数" value={applyReportDetail.a22160004} />
                        <Statistic title="消金贷款类机构平均授信额度" value={currentReportDetail.c22180010} />

                    </Layout.Col>
                    <Layout.Col span="3">
                        <Statistic title="申请命中网络贷款类机构数" value={applyReportDetail.a22160005} />
                        <Statistic title="机构总查询次数" value={applyReportDetail.a22160006} />
                        <Statistic title="网络贷款类产品数" value={currentReportDetail.c22180004} />
                    </Layout.Col>
                    <Layout.Col span="3">
                        <Statistic title="网络贷款机构平均授信额度" value={currentReportDetail.c22180006} />
                        <Statistic title="消金贷款类机构数" value={currentReportDetail.c22180007} />
                        <Statistic title="消金建议授信额度" value={currentReportDetail.c22180011} />
                    </Layout.Col>
                    <Layout.Col span="3">
                        <Statistic title="消金贷款类产品数" value={currentReportDetail.c22180008} />
                        <Statistic title="消金贷款类机构最大授信额度" value={currentReportDetail.c22180009} />
                        <Statistic title="网络贷款类机构数" value={currentReportDetail.c22180003} />
                    </Layout.Col>
                    <Layout.Col span="3">
                        <Statistic title="网络贷款机构最大授信额度" value={currentReportDetail.c22180005} />
                        <Statistic title="贷款已结清订单数" value={behaviorReportDetail.b22170052} />
                        <Statistic title="最近一次履约距今数" value={behaviorReportDetail.b22170050} />
                    </Layout.Col>
                </Layout.Row>
            </Space>)
    }
}