import React, { Component } from 'react';

import apiV2 from '../../../api/api-v2';
import ProductCategorizeFrom from './product_categorize_from';

/**
 * 贷超
 */
export default class ProductClassifyFrom extends Component {

    render() {
        return <ProductCategorizeFrom onSaveReq={apiV2.saveDcSeriesAss} onListReq={apiV2.getDcSeriesAss}
            onClassifyReq={apiV2.getDcSeriesAll} {...this.props} />
    }
}