import React, { Component } from 'react'

import { Popover } from 'antd'
import { Button } from 'element-react'
import PropTypes from 'prop-types'


export default class MyPopconfirm extends Component {

    static propTypes = {
        title: PropTypes.any,
        onCancelText: PropTypes.any,
        onConfirmText: PropTypes.any,
        children: PropTypes.any,
        onCancel: PropTypes.func,
        onConfirm: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = { visible: false }
    }

    onCancelClose() {
        const onCancel = this.props.onCancel
        if (onCancel) {
            onCancel()
        } else {
            this.setState({ visible: false })
        }
    }

    onConfirm() {
        const onConfirm = this.props.onConfirm
        if (onConfirm) onConfirm()
        this.setState({ visible: false })
    }

    render() {
        const onCancelText = this.props.onCancelText || '取消'
        const onConfirmText = this.props.onConfirmText || '确定'
        const title = this.props.title || '是否继续？'

        return (
            <Popover title={title} content={
                <div>
                    <div style={{ textAlign: 'right', margin: 0 }}>
                        <Button size="mini" type="text" onClick={() => this.onCancelClose()}>{onCancelText}</Button>
                        <Button type="primary" size="mini" onClick={() => this.onConfirm()}>{onConfirmText}</Button>
                    </div>
                </div>
            } trigger="click" visible={this.state.visible}
                onVisibleChange={(visible) => this.setState({ visible })}>
                {this.props.children}
            </Popover>
        )
    }
}
