import React, { Component } from 'react'

import apiV2 from '../../../api/api-v2'
import Pagination from '../../../components/MyPagination'
import PropTypes from 'prop-types'
import { Alert, Button, Form, Input, Message, Switch, Table } from 'element-react'
import EnabkeStatus from '../../../components/EnabkeStatus'
import Paste from '../../../components/paste'
import Popconfirm from '../../../components/MyPopconfirm'
import SwitchesThatCanDisabled from '../../../components/switches_that_can_disabled'

import { Drawer } from 'antd'
import moment from 'moment'
import ImageUploadV1 from '../../../components/ImageUploadV1'
import Image from '../../../components/Image'

/**
 * 贷超
 */
export default class Categorize extends Component {

    static ListResultEmpty = { records: [], total: 0 }
    static SaveResultEmpty = {
        name: null,
        defaultCollation: 100,
        activation: true,
        logoUrl: null
    }

    static propTypes = {
        readOnly: PropTypes.bool,
    }



    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                currentPage: 1,
                pageSize: 10,
                name: '',
                status: null
            },

            result: Categorize.ListResultEmpty,
            columns: [
                {
                    label: '#',
                    prop: 'id',
                    width: '100',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '名称',
                    prop: 'name',
                    width: '140',
                    align: 'center',
                    fixed: true,
                    render: ({ name }) => <Paste content={name} />
                },

                {
                    label: '默认排序',
                    prop: 'defaultCollation',
                    width: '100',
                    align: 'center',
                },
                {
                    label: '图标',
                    prop: 'logo',
                    width: '100',
                    align: 'center',
                    render: ({ logoUrl }) => <Image src={logoUrl} width="3rem" />
                },
                {
                    label: '是否启用',
                    prop: 'activation',
                    width: '150',
                    align: 'center',
                    render: ({ activation }) => <EnabkeStatus enable={activation} />
                },

                {
                    label: '添加时间',
                    prop: 'createTime',
                    width: '200',
                    align: 'center',
                },

                {
                    label: '修改时间',
                    prop: 'editTime',
                    width: '200',
                    align: 'center',
                },
                {
                    label: '操作',
                    prop: 'row',
                    align: 'center',
                    fixed: 'right',
                    render: row => {
                        return (
                            <>
                                <Button onClick={() => this.openSave(row)} type="primary" size="mini">{'编辑'}</Button>
                                <Popconfirm onConfirm={() => this.delRow(row)}>
                                    <Button disabled type="danger" size="mini">{'删除'}</Button>
                                </Popconfirm>
                            </>
                        )
                    }
                }
            ],
            saveForm: Categorize.SaveResultEmpty,
            saveFormVisible: false,
            saveResult: null
        }


    }

    componentDidMount() {
        this.getList()
    }

    componentWillUnmount() {
        return true
    }

    async getList() {
        const { body } = await this.props.onListReq({ param: this.state.queryForm })
        this.setState({ result: body || Categorize.ListResultEmpty })
    }

    updateQueryForm(name, value, offListRefresh = false) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (!offListRefresh) this.getList() })
    }

    updateSaveForm(name, value) {
        const saveForm = Object.assign(this.state.saveForm, { [name]: value })
        this.setState({ saveForm })
    }

    openSave(oldData) {
        const saveForm = oldData || Categorize.SaveResultEmpty

        if (saveForm.openingHours) {
            saveForm.openingHours = moment(saveForm.openingHours).toDate()
        }

        this.setState({ saveForm, saveFormVisible: true })
    }

    async delRow({ id }) {
        const { success, message } = await apiV2.delLoanSupermarketData({ id })
        if (success) { Message.success(message) } else { Message.error(message) }

        this.getList() // 刷新
    }

    async saveData() {
        const param = this.state.saveForm

        if (param.openingHours) {
            param.openingHours = moment(param.openingHours).format('YYYY-MM-DD HH:mm:ss')
        }

        const saveResult = await this.props.onSaveReq({ param });
        this.setState({ saveResult })
        this.getList() // 刷新
    }

    render() {
        const { queryForm, result, columns, saveFormVisible, saveForm, saveResult } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="名称">
                        <Input value={queryForm.name} onChange={e => this.updateQueryForm('name', e, true)} />
                    </Form.Item>
                    <Form.Item label="是否启用">
                        <SwitchesThatCanDisabled value={queryForm.status} onChangeValue={e => this.updateQueryForm('status', e, true)} />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => this.getList()} type="primary">{'查询'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.openSave()} type="primary">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={columns} data={result.records} />

                <Pagination pageSize={queryForm.pageSize} total={result.total} pageNumber={queryForm.currentPage}
                    onCurrentChange={e => this.updateQueryForm('currentPage', e)}
                    onSizeChange={e => this.updateQueryForm('pageSize', e)} />


                <Drawer width={500} visible={saveFormVisible} onClose={() => this.setState({ saveFormVisible: false, saveResult: null })}>

                    <Form>
                        <Form.Item label="名称">
                            <Input value={saveForm.name} onChange={e => this.updateSaveForm('name', e)} />
                        </Form.Item>

                        <Form.Item label="默认排序">
                            <Input value={saveForm.defaultCollation} onChange={e => this.updateSaveForm('defaultCollation', e)} />
                        </Form.Item>

                        <Form.Item label="LOGO">
                            <ImageUploadV1 url={saveForm.logoUrl} onChange={e => this.updateSaveForm('logoUrl', e)} />
                        </Form.Item>

                        <Form.Item label="是否激活">
                            <Switch value={saveForm.activation} onChange={e => this.updateSaveForm('activation', e)} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => this.saveData()}>{'提交'}</Button>
                        </Form.Item>

                        {saveResult ? (
                            <Form.Item>
                                <Alert title={saveResult.message} type={saveResult.success ? 'success' : 'error'} />
                            </Form.Item>
                        ) : null}
                    </Form>
                </Drawer>
            </>
        )
    }
}