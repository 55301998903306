import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import UserTables from './components/user_tables'
import UserSearch from './components/user_search'
import { Button } from 'element-react'

/**
 * 用户列表
 */
export default class UserList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            body: {},
            query: {},
            queryFormDataHistory: null
        }
    }



    // 从服务器获取数据列表
    async serverGetTableList(queryFormData = this.state.queryFormDataHistory) {
        const { body } = await apiV2.getNormalRepaymentNotApplyList({ data: queryFormData })
        this.setState({ body: body || {}, queryFormDataHistory: queryFormData })
    }

    // 导出
    async serverGetTableListExport() {
        const param = this.state.query
        await apiV2.getNormalRepaymentNotApplyListExcel({ param, isDownload: true })
    }

    cd(query) {
        this.setState({ query })
    }

    render() {
        const { body } = this.state

        return (
            <>
                <UserSearch onCd={c => this.cd(c)} onSearch={(condition) => this.serverGetTableList(condition)} total={body.total}>
                    <Button type="primary" onClick={() => this.serverGetTableListExport()}>{'导出列表'}</Button>
                    <UserTables onRefresh={() => this.serverGetTableList()} list={body} />
                </UserSearch>
            </>)
    }
}
