// 报表统计-推广渠道
import { Button, DatePicker, Form, Input, Table } from 'element-react'
import React, { Component } from 'react'
import Pagination from '../../components/MyPagination'
import Text from '../../components/text'
import apiV2 from '../../api/api-v2'
import moment from 'moment'
import PropTypes from 'prop-types'

export default class PromotionChannels extends Component {

    static propTypes = {
        tableColumn: PropTypes.array,
        getListFunction: PropTypes.func,
        queryParamHandle: PropTypes.func,
        defaultParam: PropTypes.object
    }


    static ResultEmpty = {
        records: [], total: 0
    }

    constructor(props) {
        super(props)

        const propTableColumn = props.tableColumn || []
        const defaultParam = props.defaultParam || {}

        this.state = {
            queryForm: Object.assign({
                channel: '',
                current: 1,
                lastFindTime: null,
                size: 10
            }, defaultParam),
            tableResult: PromotionChannels.ResultEmpty,
            tableColumns: [
                ...propTableColumn,
                {
                    label: '统计时间',
                    prop: 'createTime',
                    align: 'center',
                    fixed: true,
                    render: ({ createTime }) => {
                        return <span onClick={() => this.goDay(createTime)}><Text bold color="blue">{moment(createTime).format('YYYY-MM-DD')}</Text></span>
                    }
                },
                {
                    label: 'PV',
                    prop: 'pv',
                    align: 'center',
                    render: ({ pv }) => <Text size="1.3rem" color="blue" bold>{pv}</Text>
                }, {
                    label: 'UV',
                    prop: 'uv',
                    align: 'center',
                    render: ({ uv }) => <Text size="1.3rem" color="blue" bold>{uv}</Text>
                },
                {
                    label: '注册人数',
                    prop: 'registerNum',
                    align: 'center',
                    render: ({ registerNum, qqNum, wxNum }) => {
                        return (
                            <>
                                <Text color="red" size="1rem" bold>{registerNum}</Text><br />
                            </>
                        )
                    }
                },
                {
                    label: '资料完成人数',
                    prop: 'authNum',
                    align: 'center',
                    render: ({ authNum }) => <Text color="orange" size="1.1rem" bold>{authNum}</Text>
                },
            ]
        }

        this.serverGet()
    }

    goDay(date) {
        window.location.href = `?c_s_d=${date}/#/risk/h5_channel_statistics_today`
    }


    async serverGet() {
        const queryForm = JSON.parse(JSON.stringify(this.state.queryForm))
        if (queryForm.lastFindTime) {
            queryForm.lastFindTime = moment(queryForm.lastFindTime).format('YYYY-MM-DD')
        }
        const queryParamHandle = this.props.queryParamHandle
        if (queryParamHandle) queryParamHandle(queryForm)

        const getFunc = this.props.getListFunction || apiV2.getH5RiskStatistics
        const { body } = await getFunc({ query: queryForm })
        this.setState({ tableResult: body || PromotionChannels.ResultEmpty })
    }

    changeQueryForm(name, value, refresh) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => { if (refresh) this.serverGet() })
    }

    render() {
        const { tableResult, tableColumns, queryForm } = this.state
        return (
            <>
                <Form inline>
                    <Form.Item label="渠道名称">
                        <Input value={queryForm.channel}
                            onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>
                    <Form.Item label="查询日期">
                        <DatePicker value={queryForm.lastFindTime}
                            onChange={(e) => this.changeQueryForm('lastFindTime', e, false,)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.serverGet()}>{'查询'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={tableColumns} data={tableResult.records} />

                <Pagination total={tableResult.total} pageNumber={queryForm.current} pageSize={queryForm.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />
            </>
        )
    }
}