import { Button, Rate, Tag } from 'element-react'
import React from 'react'
import Money from '../../../components/Money'
import EnabkeStatus from '../../../components/EnabkeStatus'
import OrderRemarksForm from '../order_remarks_form'

/**
 * 公共表格字段
 */
export const CommonTableFields = [
    // {
    //     label: '小贷分',
    //     prop: 'xdScore',
    //     width: '120',
    //     align: 'center',
    //     render: ({ xdScore }) => <Money value={xdScore} />
    // },
    // {
    //     label: '反欺诈分',
    //     prop: 'fqzScore',
    //     width: '120',
    //     align: 'center',
    //     render: ({ fqzScore }) => <Money value={fqzScore} />
    // },
    {
        label: '在库',
        prop: 'isInStock',
        width: '80',
        align: 'center',
        fixed: true,
        render: ({ isInStock }) => <EnabkeStatus enable={isInStock} />
    }
]

/**
 * 风险
 */
export const RiskoFields = [
    // {
    //     label: '风控等级',
    //     prop: 'tzLevel',
    //     width: '120',
    //     align: 'center',
    //     render: ({ tzLevel }) => {
    //         if (tzLevel === 1) {
    //             tzLevel = 2
    //         } else if (tzLevel === 2) {
    //             tzLevel = 1
    //         }
    //         return <Rate max={2} disabled value={tzLevel} />
    //     }
    // }
]



/**
 * 应还金额表格字段
 */
export const PayableTableFields = [
    {
        label: '应还金额',
        width: '140',
        align: 'center',
        fixed: 'right',
        render: ({ standAmount, allAmount, partialPayment }) => {
            return (
                <>
                    <Tag type="primary">{'单期:'}{standAmount || 0}</Tag>
                    <br />
                    <Tag type="success">{'全额:'}{allAmount || 0}</Tag>
                    <br />
                    <Tag type="success">{'已还金额:'}{partialPayment || 0}</Tag>
                </>
            )
        }
    }
]


/**
 * 代打款表格字段
 */
export function PaymentToBeMadeTableOpt(reactObject) {
    return {
        label: '操作',
        prop: 'id',
        fixed: 'right',
        align: 'center',
        width: '150',
        render: ({ userId, orderId, type }) => {
            return (
                <>
                    <Button onClick={() => reactObject.setState({ orderInfoId: orderId })} type="text" size="mini">{'详情'}</Button>
                    <Button onClick={() => reactObject.setState({ orderStagesId: orderId })} type="text" size="mini">{'分期'}</Button>
                    <Button type="info" onClick={() => reactObject.setState({ phoneInfoId: userId })} size="mini">{'手机详情'}</Button>
                    {/* <Button type="warning" onClick={() => this.setState({ phoneInfoId: userId })} size="mini">{'用户详情'}</Button> */}
                    <Button type="text" size="mini" onClick={() => reactObject.setState({ paymentStateId: orderId })}>{'打款'}</Button>
                    <Button onClick={() => reactObject.setState({ orderApprovalId: orderId })} type="text" size="mini">{'额度'}</Button>
                    <OrderRemarksForm orderId={orderId} />
                    {type === 3 ?
                        <Button type="text" onClick={() => reactObject.setState({ singlePeriodId: orderId })} size="mini">{'改为单期'}</Button>
                        : null}

                </>)
        }
    }
}
