import React, { Component } from 'react'
import { Switch, Message, Form, Button, Input, DateRangePicker } from 'element-react'
import Search from '@components/Search'
import MyPagination from '@components/MyPagination'
import apiV2 from '../../api/api-v2'
import moment from 'moment'
import UserTables from './components/user_tables'
import GetLoginSMSCodePhone from './components/get_login_sms_code_phone'

/**
 * 认证配置
 */
export default class AuthConfig extends Component {

    constructor(props) {
        super(props)
        this.state = {
            getSMS: false,
            queryFormData: {
                beginTime: null,
                channel: '',
                current: 1,
                endTime: null,
                mobile: '',
                orderNum: '',
                size: 10,
                status: true,
                realUserName: ''
            },
            list: {
                records: [],
                total: 0,
                size: 10,
                current: 1,
                orders: [],
                optimizeCountSql: true,
                searchCount: true,
                countId: null,
                maxLimit: null,
                pages: 0
            },

        }
    }

    componentWillMount() {
        this.getMemberList()
    }


    onChangeQueryForm = (itemName) => {
        return (e) => {
            this.updateChangequeryForm(itemName, e)
        }
    }

    updateChangequeryForm(itemName, e, callback) {
        const queryFormData = Object.assign(this.state.queryFormData, { [itemName]: e })
        this.setState({
            queryFormData
        }, callback)
    }

    onChangeQueryFormDate(e) {
        const newForm = {}

        if (e && e.length > 0) {
            newForm.beginTime = e[0]
            newForm.endTime = e[1]
        } else {
            newForm.beginTime = null
            newForm.endTime = null
        }
        const queryFormData = Object.assign(this.state.queryFormData, newForm)
        this.setState({
            queryFormData
        })
    }

    sizeChange(e) {
        this.updateChangequeryForm('size', e, this.getMemberList)
    }
    onCurrentChange(e) {
        this.updateChangequeryForm('current', e, this.getMemberList)
    }

    async getMemberList() {
        const formatCreate = 'YYYY-MM-DD'
        const newForm = this.state.queryFormData
        const { beginTime, endTime } = newForm

        if (beginTime && endTime) {
            newForm.beginTime = moment(beginTime).format(formatCreate)
            newForm.endTime = moment(endTime).format(formatCreate)
        }

        const { body, message, success } = await apiV2.getMemberList({
            bodyQuery: newForm
        })

        if (success) {
            this.setState({
                list: body
            })
        } else {
            Message.error(message)
        }
    }

    closeGetSMS() {
        this.setState({ getSMS: false })
    }

    export() {
        apiV2.exportUserList({ param: this.state.queryFormData, isDownload: true })
    }

    render() {
        const { queryFormData, list } = this.state

        return (
            <div>
                <Search>
                    <Form.Item>
                        <Input placeholder="手机号" value={queryFormData.mobile}
                            onChange={this.onChangeQueryForm('mobile')} clearable="true" />
                    </Form.Item>

                    <Form.Item>
                        <Input placeholder="渠道" value={queryFormData.channel}
                            onChange={this.onChangeQueryForm('channel')} clearable="true" />
                    </Form.Item>

                    <Form.Item label="真实姓名">
                        <Input value={queryFormData.realUserName} onChange={this.onChangeQueryForm('realUserName')} />
                    </Form.Item>

                    <Form.Item>
                        <Input placeholder="借款次数" value={queryFormData.orderNum} type="number"
                            onChange={this.onChangeQueryForm('orderNum')} clearable="true" />
                    </Form.Item>

                    <Form.Item>
                        <span>{'启用状态：'}</span>
                        <Switch onText="开启" offText="关闭" value={queryFormData.status}
                            onChange={this.onChangeQueryForm('status')} clearable="true" />
                    </Form.Item>

                    <Form.Item>
                        <DateRangePicker placeholder="注册时间" value={[queryFormData.beginTime, queryFormData.endTime]}
                            onChange={this.onChangeQueryFormDate.bind(this)} />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={this.getMemberList.bind(this)} type="primary">{'搜索'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.setState({ getSMS: true })} type="primary">{'获取验证码'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.export()} type="success">{'导出'}</Button>
                    </Form.Item>

                </Search>

                <GetLoginSMSCodePhone dialogVisible={this.state.getSMS} onClose={() => this.closeGetSMS()} />
                <UserTables onRefresh={() => this.getMemberList()} list={list} />

                <MyPagination
                    total={list.total}
                    pageSize={list.size}
                    pageNumber={list.current}
                    onSizeChange={this.sizeChange.bind(this)}
                    onCurrentChange={this.onCurrentChange.bind(this)} />
            </div>
        )
    }
}