import React, { Component } from 'react';

import apiV2 from '../../../api/api-v2';
import DcCategorize from './dc_categorize';

/**
 * 贷超类目
 */
export default class Series extends Component {

    render() {
        return (<DcCategorize onSaveReq={apiV2.saveSeriesData} onListReq={apiV2.getSeriesData} />);
    }
}