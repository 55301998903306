import React, { Component } from 'react'

import { List, Popover } from 'antd'
import { Button } from 'element-react'
import PropTypes from 'prop-types'

/**
 * 下拉点击
 */
export default class Drop extends Component {

    static propTypes = {
        name: PropTypes.any,
        list: PropTypes.array,
        onClick: PropTypes.func,
        onListClick: PropTypes.func,
    }

    render() {
        const name = this.props.name || '详情'
        const list = this.props.list || []

        return (
            <>
                <div>
                    <Button onClick={this.props.onClick} type="text" size="mini">{name}</Button>
                    <Popover trigger="hover" content={
                        <List>
                            <a>
                                {list.map(({ label, value }) => <List.Item onClick={() => this.props.onListClick(value)} key={label}>{label}</List.Item>)}
                            </a>
                        </List>} title="更多操作">
                        {!list.length ? null : <i style={{ color: '#20a0ff' }} size="mini" className="el-icon-plus" />}
                    </Popover>
                </div>

            </>
        )
    }
}
