import React, { Component } from 'react'
import {
  Message,
  Form,
  Button,
  Input,
  Select,
  Table,
  Tag,
} from 'element-react'
import Search from '@components/Search'
import MyPagination from '@components/MyPagination'
import apiV2 from '../../api/api-v2'
import moment from 'moment'
import GetLoginSMSCodePhone from './components/get_login_sms_code_phone'
import EnabkeStatus from '../../components/EnabkeStatus'
import { Drawer } from 'antd'
import RiskH5 from '../../components/risk_h5'



/**
 * 认证配置
 */
export default class AuthConfig extends Component {
  constructor(props) {
    super(props)

    const that = this

    this.state = {
      userIdRisk: null,
      getSMS: false,
      editStatusId: null,
      open: false,
      queryFormData: {
        "current": 1,
        "idCardNum": "",
        "mobile": "",
        "name": "",
        "size": 10,
        "status": null
      },
      list: {
        records: [],
        total: 0,
        size: 10,
        current: 1,
        orders: [],
        optimizeCountSql: true,
        searchCount: true,
        countId: null,
        maxLimit: null,
        pages: 0,
      },
      columns: [
        {
          label: "身份证号",
          prop: "cardNum",
          width: "120",
          align: "center",
        },
        {
          label: "渠道名称",
          prop: "channelName",
          width: "120",
          align: "center",
        },
        {
          label: "雷达状态",
          prop: "ldStatus",
          align: "center",
          width: "100",
          render({ ldStatus }) {
            return <EnabkeStatus enable={ldStatus} />;
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: "140",
          align: "center",
        },
        {
          label: "用户ID",
          prop: "id",
          width: "140",
          align: "center",
        },
        {
          label: "手机号",
          prop: "mobile",
          width: "140",
          align: "center",
        },
        {
          label: "真实姓名",
          prop: "realUserName",
          width: "140",
          align: "center",
        },
        {
          label: "状态",
          prop: "status",
          width: "140",
          align: "center",
          render({ status }) {
            const lab = {
              1: { t: "待审核", c: "primary" },
              2: { t: "通过", c: "success" },
              3: { t: "拒绝", c: "warning" },
            };

            const c = lab[status] || { t: "未知" };

            return <Tag type={c.c}>{c.t}</Tag>;
          },
        },
        {
          label: "风控获取状态",
          prop: "tzStatus",
          width: "140",
          align: "center",
          render({ status }) {
            return <EnabkeStatus enable={status} />;
          },
        },
        {
          label: "修改时间",
          prop: "uptTime",
          width: "140",
          align: "center",
        },
        {
          label: "操作",
          width: "140",
          fixed: 'right',
          align: "center",
          render({ id, }) {
            return <>
              <Button type="text" onClick={() => {
                that.setState({ open: true, editStatusId: id })
              }}>
                修改状态
              </Button>

              <Button type="text" onClick={() => {
                that.setState({ userIdRisk: id })
              }}>
                风控信息
              </Button>
            </>
          }
        },
      ],
    };
  }



  componentWillMount() {
    this.getMemberList();
  }

  setValue(value) {
    this.setState(value)
  }

  onChangeQueryForm = (itemName) => {
    return (e) => {
      this.updateChangequeryForm(itemName, e);
    };
  };

  updateChangequeryForm(itemName, e, callback) {
    const queryFormData = Object.assign(this.state.queryFormData, {
      [itemName]: e,
    });
    this.setState(
      {
        queryFormData,
      },
      callback
    );
  }

  onChangeQueryFormDate(e) {
    const newForm = {};

    if (e && e.length > 0) {
      newForm.beginTime = e[0];
      newForm.endTime = e[1];
    } else {
      newForm.beginTime = null;
      newForm.endTime = null;
    }
    const queryFormData = Object.assign(this.state.queryFormData, newForm);
    this.setState({
      queryFormData,
    });
  }

  sizeChange(e) {
    this.updateChangequeryForm("size", e, this.getMemberList);
  }
  onCurrentChange(e) {
    this.updateChangequeryForm("current", e, this.getMemberList);
  }

  async getMemberList() {
    const formatCreate = "YYYY-MM-DD";
    const newForm = this.state.queryFormData;
    const { beginTime, endTime } = newForm;

    if (beginTime && endTime) {
      newForm.beginTime = moment(beginTime).format(formatCreate);
      newForm.endTime = moment(endTime).format(formatCreate);
    }

    const { body, message, success } = await apiV2.getH5RiskRegisterList({
      bodyQuery: newForm,
    })

    if (success) {
      this.setState({
        list: body,
      });
    } else {
      Message.error(message);
    }
  }

  closeGetSMS() {
    this.setState({ getSMS: false });
  }

  async reqUpdateEditStatus() {
    const { editStatusId, editStatus } = this.state
    try {
      const { success, message } = await apiV2.h5RiskUptById({ param: { id: editStatusId, status: editStatus } })
      if (!success) {
        return alert(message);
      }
      this.setState({ open: false })
    } catch (err) {
      alert(JSON.stringify(err));
    }
  }

  render() {
    const { queryFormData, list, editStatus, open } = this.state

    return (
      <div>

        <Drawer title="修改状态" placement="right" onClose={() => this.setState({ open: false })}
          visible={open}>
          <Select
            value={editStatus}
            clearable
            onChange={v => this.setState({ editStatus: v })}
          >
            <Select.Option value={1} label={"待审核"}></Select.Option>
            <Select.Option value={2} label={"通过"}></Select.Option>
            <Select.Option value={3} label={"拒绝"}></Select.Option>
          </Select>
          <Button type="text" onClick={this.reqUpdateEditStatus.bind(this)}>确认修改</Button>
        </Drawer>

        <RiskH5 userId={this.state.userIdRisk} onClose={() => this.setState({ userIdRisk: null })} />

        <Search>
          <Form.Item>
            <Input
              placeholder="手机号"
              value={queryFormData.mobile}
              onChange={this.onChangeQueryForm("mobile")}
              clearable="true"
            />
          </Form.Item>

          <Form.Item>
            <Input
              placeholder="身份证号"
              value={queryFormData.cardNum}
              onChange={this.onChangeQueryForm("idCardNum")}
              clearable="true"
            />
          </Form.Item>

          <Form.Item label="真实姓名">
            <Input
              value={queryFormData.realUserName}
              onChange={this.onChangeQueryForm("name")}
            />
          </Form.Item>


          <Form.Item label="状态">
            <Select
              value={queryFormData.type}
              clearable
              onChange={this.onChangeQueryFormDate.bind(this, "status")}
            >
              <Select.Option value={1} label={"待审核"}></Select.Option>
              <Select.Option value={2} label={"通过"}></Select.Option>
              <Select.Option value={3} label={"拒绝"}></Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button onClick={this.getMemberList.bind(this)} type="primary">
              {"搜索"}
            </Button>
          </Form.Item>


        </Search>

        <GetLoginSMSCodePhone
          dialogVisible={this.state.getSMS}
          onClose={() => this.closeGetSMS()}
        />
        <Table columns={this.state.columns} data={list.records} />

        <MyPagination
          total={list.total}
          pageSize={list.size}
          pageNumber={list.current}
          onSizeChange={this.sizeChange.bind(this)}
          onCurrentChange={this.onCurrentChange.bind(this)}
        />
      </div>
    );
  }
}
