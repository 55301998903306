import React, { Component } from 'react'

import apiV2 from '../../api/api-v2'
import Pagination from '../../components/MyPagination'
import PropTypes from 'prop-types'
import { Alert, Button, Form, Input, Message, Switch, Table } from 'element-react'
import Image from '../../components/Image'
import EnabkeStatus from '../../components/EnabkeStatus'
import Paste from '../../components/paste'
import Money from '../../components/Money'
import ImageUploadV1 from '../../components/ImageUploadV1'
import Popconfirm from '../../components/MyPopconfirm'

import { Drawer } from 'antd'

/**
 * 贷超
 */
export default class LoanSupermarket extends Component {

    static ListResultEmpty = { records: [], total: 0 }
    static SaveResultEmpty = {
        imageUrl: '',
        sort: 0,
        status: true,
        url: ''
    }

    static propTypes = {
        readOnly: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                current: 1,
                size: 10
            },

            result: LoanSupermarket.ListResultEmpty,
            columns: [
                {
                    label: '#',
                    prop: 'id',
                    width: '100',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '图片',
                    prop: 'imageUrl',
                    width: '100',
                    align: 'center',
                    render: ({ imageUrl }) => <Image src={imageUrl} width="3rem" />
                },
                {
                    label: '是否启用',
                    prop: 'status',
                    width: '150',
                    align: 'center',
                    render: ({ status }) => <EnabkeStatus enable={status} />
                },
                {
                    label: '跳转链接',
                    prop: 'url',
                    width: '200',
                    align: 'center',
                    render: ({ url }) => <Paste content={url} />
                },
                {
                    label: '排序',
                    prop: 'sort',
                    width: '80',
                    align: 'center',
                    render: ({ sort }) => <Money value={sort} />
                },

                {
                    label: '添加时间',
                    prop: 'createTime',
                    width: '200',
                    align: 'center',
                },

                {
                    label: '修改时间',
                    prop: 'editTime',
                    width: '200',
                    align: 'center',
                },
                {
                    label: '操作',
                    prop: 'row',
                    width: '120',
                    align: 'center',
                    fixed: 'right',
                    render: row => {
                        return (
                            <>
                                <Button onClick={() => this.openSave(row)} type="primary" size="mini">{'编辑'}</Button>
                                <Popconfirm onConfirm={() => this.delRow(row)}>
                                    <Button type="danger" size="mini">{'删除'}</Button>
                                </Popconfirm>
                            </>
                        )
                    }
                }
            ],
            saveForm: LoanSupermarket.SaveResultEmpty,
            saveFormVisible: false,
            saveResult: null
        }


    }

    componentDidMount() {
        this.getList()
    }

    componentWillUnmount() {
        return true
    }

    async getList() {
        const { body } = await apiV2.getLoanSupermarket({ param: this.state.queryForm })
        this.setState({ result: body || LoanSupermarket.ListResultEmpty })
    }

    updateQueryForm(name, value) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, this.getList)
    }

    updateSaveForm(name, value) {
        const saveForm = Object.assign(this.state.saveForm, { [name]: value })
        this.setState({ saveForm })
    }

    openSave(oldData) {
        this.setState({ saveForm: oldData || LoanSupermarket.SaveResultEmpty, saveFormVisible: true })
    }

    async delRow({ id }) {
        const { success, message } = await apiV2.delLoanSupermarket({ id })
        if (success) { Message.success(message) } else { Message.error(message) }

        this.getList() // 刷新
    }

    async saveData() {
        const saveResult = await apiV2.saveLoanSupermarket({ param: this.state.saveForm })
        this.setState({ saveResult })
        this.getList() // 刷新
    }

    render() {
        const { queryForm, result, columns, saveFormVisible, saveForm, saveResult } = this.state

        return (
            <>

                <Form>
                    <Form.Item>
                        <Button onClick={() => this.getList()} type="primary">{'查询'}</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => this.openSave()} type="primary">{'添加'}</Button>
                    </Form.Item>
                </Form>

                <Table columns={columns} data={result.records} />

                <Pagination pageSize={queryForm.size} total={result.total} pageNumber={queryForm.current}
                    onCurrentChange={e => this.updateQueryForm('current', e)}
                    onSizeChange={e => this.updateQueryForm('size', e)} />


                <Drawer width={500} visible={saveFormVisible} onClose={() => this.setState({ saveFormVisible: false, saveResult: null })}>

                    <Form>
                        <Form.Item label="跳转链接">
                            <Input type="textarea" value={saveForm.url} onChange={e => this.updateSaveForm('url', e)} />
                        </Form.Item>
                        <Form.Item label="图片">
                            <ImageUploadV1 url={saveForm.imageUrl} onChange={e => this.updateSaveForm('imageUrl', e)} />
                        </Form.Item>
                        <Form.Item label="排序">
                            <Input placeholder="数字越大，优先级越高" value={saveForm.sort} onChange={e => this.updateSaveForm('sort', e)} />
                        </Form.Item>
                        <Form.Item label="是否启用">
                            <Switch value={saveForm.status} onChange={e => this.updateSaveForm('status', e)} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() => this.saveData()}>{'提交'}</Button>
                        </Form.Item>

                        {saveResult ? (
                            <Form.Item>
                                <Alert title={saveResult.message} type={saveResult.success ? 'success' : 'error'} />
                            </Form.Item>
                        ) : null}
                    </Form>
                </Drawer>

            </>
        )
    }
}