import { Alert, Button, Form, Input, Select, Switch, Table, Tag } from 'element-react'
import React, { Component } from 'react'

import Pagination from '../../components/MyPagination'
import EnabkeStatus from '../../components/EnabkeStatus'
import Text from '../../components/text'
import Paste from '../../components/paste'

import apiV2 from '../../api/api-v2'
import { SoftVersionType } from './types'
import Condition from '../../components/Condition'
import { Drawer } from 'antd'

/**
 * 软件版本管理
 */
export default class SoftVersion extends Component {


    static ResultEmpty = { records: [], total: 0 }
    static AddOrUptFormEmpty = {
        appType: null,
        uptContent: '',
        uptDdress: '',
        status: true,
        versionNum: '',
        isMustUpt: false
    }

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                appType: null,
                current: 1,
                size: 10,
                status: true,
                versionNum: ''
            },
            addOrUptForm: SoftVersion.AddOrUptFormEmpty,
            result: SoftVersion.ResultEmpty,
            resultSubmit: { message: '' },
            formVisible: false,
            resultColumns: [
                {
                    label: '#',
                    prop: 'versionId',
                    width: '60',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '软件类型',
                    prop: 'appType',
                    width: '120',
                    align: 'center',
                    render: ({ appType }) => {
                        const typeOrg = SoftVersionType.find(({ symbol }) => symbol === appType)
                        return typeOrg ? <Tag type={typeOrg.theme}>{typeOrg.label}</Tag> : null
                    }
                },
                {
                    label: '启用状态',
                    prop: 'status',
                    width: '120',
                    align: 'center',
                    render: ({ status }) => <EnabkeStatus enable={status} />
                },
                {
                    label: '强制更新',
                    prop: 'isMustUpt',
                    width: '120',
                    align: 'center',
                    render: ({ isMustUpt }) => <EnabkeStatus enable={isMustUpt} />
                },
                {
                    label: '更新内容',
                    prop: 'uptContent',
                    width: '200',
                    align: 'center',
                    render: ({ uptContent }) => <Text rich>{uptContent}</Text>
                },
                {
                    label: '更新地址',
                    prop: 'uptDdress',
                    width: '300',
                    align: 'center',
                    render: ({ uptDdress }) => <Paste content={uptDdress}/>
                },

                {
                    label: '版本号',
                    prop: 'versionNum',
                    width: '120',
                    align: 'center',
                    fixed: 'right'
                },
                {
                    label: '发布时间',
                    prop: 'createTime',
                    width: '150',
                    align: 'center',
                },
                {
                    label: '操作',
                    prop: 'row',
                    width: '100',
                    align: 'center',
                    fixed: 'right',
                    render: row => {
                        return <Button onClick={() => this.openEdit(row)} type="text">{'编辑'}</Button>
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.getListResult()
    }

     //组件销毁前调用，清除一些事件(比如定时事件)
     componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    changeQueryForm(name, value, refreshList) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => refreshList && this.getListResult())
    }

    changeAUForm(name, value) {
        const addOrUptForm = Object.assign(this.state.addOrUptForm, { [name]: value })
        this.setState({ addOrUptForm })
    }


    async getListResult() {
        const { body } = await apiV2.getSoftVersionList({ data: this.state.queryForm })
        this.setState({ result: body })
    }

    async submit() {
        const resultSubmit = await apiV2.saveSoftVersion({ data: this.state.addOrUptForm })
        this.setState({ resultSubmit})

        this.getListResult()
    }

    async openEdit(row) {
        this.setState({
            addOrUptForm: row,
            formVisible: true
        })
    }

    render() {
        const { result, resultColumns, queryForm, addOrUptForm, resultSubmit, formVisible } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="软件类型">
                        <Select value={queryForm.appType} onChange={(e) => this.changeQueryForm('appType', e)} clearable>
                            {SoftVersionType.map(({ symbol, label }) => <Select.Option key={symbol} value={symbol} label={label} />)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="启用状态">
                        <Switch onText="开启" offText="关闭" value={queryForm.status} onChange={(e) => this.changeQueryForm('status', e)} />
                    </Form.Item>
                    <Form.Item label="版本号">
                        <Input value={queryForm.versionNum} onChange={(e) => this.changeQueryForm('versionNum', e)} placeholder="1.0.1" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => this.getListResult()}>{'查询'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => this.openEdit(SoftVersion.AddOrUptFormEmpty)}>{'添加'}</Button>
                    </Form.Item>

                </Form>

                <Table columns={resultColumns} data={result.records} border />

                <Pagination total={result.total} pageSize={queryForm.size} pageNumber={queryForm.current}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />

                <Drawer visible={formVisible} onClose={() => this.setState({ formVisible: false })} width="750">
                    <Form>
                        <Form.Item label="软件类型">
                            <Select value={addOrUptForm.appType} onChange={(e) => this.changeAUForm('appType', e)} >
                                {SoftVersionType.map(({ symbol, label }) => <Select.Option key={symbol} value={symbol} label={label} />)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="启用状态">
                            <Switch onText="开启" offText="关闭" value={addOrUptForm.status} onChange={(e) => this.changeAUForm('status', e)} />
                        </Form.Item>
                        <Form.Item label="版本号">
                            <Input value={addOrUptForm.versionNum} onChange={(e) => this.changeAUForm('versionNum', e)} placeholder="1.0.1" />
                        </Form.Item>

                        <Form.Item label="更新内容">
                            <Input type="textarea" autosize value={addOrUptForm.uptContent} onChange={(e) => this.changeAUForm('uptContent', e)} />
                        </Form.Item>

                        <Form.Item label="更新地址">
                            <Input type="textarea" autosize value={addOrUptForm.uptDdress} onChange={(e) => this.changeAUForm('uptDdress', e)} />
                        </Form.Item>

                        <Form.Item label="强制更新">
                            <Switch onText="开启" offText="关闭" value={addOrUptForm.isMustUpt} onChange={(e) => this.changeAUForm('isMustUpt', e)} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => this.submit()}>{'提交'}</Button>
                        </Form.Item>

                        <Condition visible={resultSubmit.message}>
                            <Form.Item>
                                <Alert message={resultSubmit.message} type={resultSubmit.success ? 'success' : 'error'} />
                            </Form.Item>
                        </Condition>
                    </Form>
                </Drawer>
            </>)
    }

}
