import { Table } from 'element-react'
import React, { Component } from 'react'
import Pagination from '../../../components/MyPagination'
import Image from '../../../components/Image'

import PropTypes from 'prop-types'
import apiV2 from '../../../api/api-v2'


/**
 * 用户应用搜索
 */
export default class UserAppList extends Component {

    static propTypes = {
        userId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            queryFormData: {
                current: 1,
                size: 10
            },
            result: {},
            columns: [
                {
                    label: '应用标识',
                    prop: 'appId',
                    width: '180',
                    align: 'center',
                },
                {
                    label: '应用图标',
                    prop: 'appLogo',
                    width: '150',
                    align: 'center',
                    render: ({ appLogo }) => {
                        return <Image src={appLogo} width="4rem" />
                    }
                },
                {
                    label: '应用名称',
                    prop: 'appName',
                    width: '150',
                    align: 'center',
                    fixed: true,
                },
                {
                    label: '记录时间',
                    prop: 'createTime',
                    align: 'center',
                },
            ]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.serverGet()
        }
    }

    async serverGet() {
        const param = this.state.queryFormData
        param.userId = this.props.userId
        const { body } = await apiV2.getUserAppDataList({ param })
        this.setState({
            result: body || {}
        })
    }

    /**
     * 改变查询表单值
     * @param {*} name 属性名
     * @param {*} value 属性值
     * @param {*} listRefresh 是否刷新列表
     */
    changeQueryForm(name, value, listRefresh) {
        const queryFormData = Object.assign(this.state.queryFormData, { [name]: value })
        this.setState({ queryFormData }, () => { if (listRefresh) this.serverGet() })
    }

    render() {
        const { queryFormData, result, columns } = this.state

        return (
            <>
                <Table columns={columns} data={result.records} />
                <Pagination total={result.total} pageNumber={queryFormData.current} pageSize={queryFormData.size}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />
            </>
        )
    }
}

