import React, { Component } from 'react'

import PropTypes from 'prop-types'

import UserInfoTabs from './user_info_tabs'
import { Drawer } from 'antd'


/**
 * 用户信息
 */
export default class UserInfo extends Component {

    static propTypes = {
        userId: PropTypes.number,
        onClose: PropTypes.func
    }


    constructor(props) {
        super(props)

        this.state = {
            visible: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId && this.props.userId) {
            this.setState({
                visible: true
            })
        }
    }

    close() {
        this.setState({ visible: false })
        this.props.onClose()
    }

    render() {
        return (
            <Drawer onClose={() => this.close()} visible={this.state.visible} placement="bottom"
                height="85%" title="用户详情">
                <UserInfoTabs userId={this.props.userId} />
            </Drawer>
        )
    }
}