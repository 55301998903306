import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Message } from 'element-react'
import apiV2 from '../../api/api-v2'
import Space from '../../components/space'
import { Drawer } from 'antd'


/**
 * 添加备注
 */
export default class OrderRemarksForm extends Component {
    static propTypes = {
        orderId: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.state = {
            submitLoading: false,
            content: '',
            visible: false
        }
    }

    async submit() {
        this.setState({ submitLoading: true })
        try {
            if (!this.state.content) {
                return Message.error('请输入备注')
            }
            const { message, success } = await apiV2.addOrderRemarks({ id: this.props.orderId, value: this.state.content })

            if (success) {
                Message.success(message)
                this.setState({ content: '', visible: false })
            } else {
                Message.error(message)
            }
        } finally {
            this.setState({ submitLoading: false })
        }
    }

    get formUI() {
        const { submitLoading } = this.state;
        return (
            <Form inline>
                <Form.Item>
                    <Input value={this.state.content} type="textarea" placeholder="内容"
                        onChange={content => this.setState({ content })} />
                </Form.Item>
                <Form.Item>
                    <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.submit()} type="danger" size="small">{'提交'}</Button>
                </Form.Item>
            </Form>)
    }

    render() {
        return (
            <>
                <Space width="0 0.2rem">
                    <Button onClick={() => this.setState({ visible: true })} type="text" size="mini">{'加备注'}</Button>
                </Space>
                <Drawer onClose={() => this.setState({ visible: false })} visible={this.state.visible} title="添加订单备注">
                    {this.formUI}
                </Drawer>
            </>
        )
    }
}