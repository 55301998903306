import { Card } from 'element-react'
import React, { Component } from 'react'
import Text from './text'
import PropTypes from 'prop-types'

/**
 * 卡片头部
 */
export default class CardHead extends Component {

    static propTypes = {
        children: PropTypes.any,
        title: PropTypes.any,
        margin: PropTypes.any,
    }

    render() {
        const margin = this.props.margin || '0.5rem 0'

        return (
            <Card style={{ margin }} header={<Text size="0.9rem">{this.props.title}</Text>}>
                {this.props.children}
            </Card>
        )
    }
}