import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import 'element-theme-default'
import './index.css'
import 'antd/dist/antd.css'
import App from './routes/index'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from '@redux/store'

if (process.env.REACT_APP_THEME_CSS_SRC) {
  // eslint-disable-next-line
  const tm = require(process.env.REACT_APP_THEME_CSS_SRC)
  console.log('dy tm=', tm)
}

ReactDOM.render(
  <Provider store={store}><App /></Provider>,
  document.getElementById('root')
)
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

