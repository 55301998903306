import React from 'react'
import { HIDE_ORDER_NO } from "../utils/app";
import Paste from "./paste";

// 共有表格字段
export function getShareOrderColumns() {
    const s = [];
    if (!HIDE_ORDER_NO) {
        s.push({
            label: '单号',
            prop: 'orderCode',
            width: '185',
            align: 'center',
            render: ({ orderCode }) => <Paste content={orderCode} />
        });
    }
    return s;
}