import React, { Component } from 'react'
import { Form, Message } from 'element-react'
import api from '@api/index'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { managelogin } from './action'
import '@styles/login.less'
import './css/login2.css'

import apiV2 from '../../api/api-v2'
import { APP_NAME, LOGIN_MODE } from '../../utils/app'

class Login extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    managelogin: PropTypes.func.isRequired,
    btnLoading: PropTypes.bool.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      loginMode: LOGIN_MODE, // 登录方式：VERIFYCODE("验证码登录")；PASSWORD("密码登录")
      codeText: '获取验证码',
      codeDisabled: false,
      count: 60,
      form: {
        adminName: '',
        password: '',
        code: ''
      },
      rules: {
        adminName: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  }
  componentWillMount() {
    // const elements = [
    //   'Hydrogen',
    //   'Helium',
    //   'Lithium',
    //   'Beryllium'
    // ]

    // const xx = elements.map(({ 'length': item }) => item)
    // console.log(xx)
  }
  componentDidMount() {
    window.sessionStorage.clear()
    const a = this.getCookie('adminName')
    const p = this.getCookie('password')
    if (a && p) {
      this.setState({
        channelForm: {
          adminName: a,
          password: p
        }
      })
    }
  }
  fetchCode = async () => {
    this.setState({
      codeDisabled: true
    })

    const res = await apiV2.sendLoginPhoneVerificationCode(this.state.form.adminName)

    // const res = await api.verifycodeApi({
    //   phone: this.state.form.adminName
    // })
    if (res.success) {
      Message.success(res.message + '【发送验证码】')
      let count = this.state.count

      this.onChange('code', res.body)

      const timer = setInterval(() => {
        this.setState({
          count: (count--),
          codeDisabled: true,
          codeText: count
        }, () => {
          if (count === 0) {
            clearInterval(timer)
            this.setState({
              count: 60,
              codeDisabled: false,
              codeText: '获取验证码'
            })
          }
        })
      }, 1000)
    } else {
      this.setState({ form: { adminName: '' }, codeDisabled: false })
      Message.warning(res.message)
    }
  }
  getCode = () => {
    if (this.state.form.adminName === '') {
      Message.warning('请先输入手机号')
      return
    } else if (!(/^1[345789]\d{9}$/.test(this.state.form.adminName))) {
      Message.warning('手机号格式不正确')
      this.setState({ form: { adminName: '' } })
      return
    } else {
      this.fetchCode()
    }
  }
  // 获取cookie
  getCookie(key) {
    const name = key + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim()
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  // 设置cookie,默认是30天
  setCookie(key, value) {
    const d = new Date()
    d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toGMTString()
    document.cookie = `${key}=${value};${expires}`
  }
  removeCookie(key) {
    const exp = new Date()
    exp.setTime(exp.getTime() - 1)
    const expires = 'expires=' + exp.toGMTString()
    const accountInfo = document.cookie
    var pos = accountInfo.indexOf(key)
    if (pos !== -1) {
      document.cookie = `${key}='';${expires}`
    }
  }
  // 用户名-密码登录
  channelLogin = e => {
    e.preventDefault()
    const mode = this.state.loginMode
    if (mode !== '') {
      this.form.validate((valid) => {
        if (valid) {
          const { form } = this.state
          let trans = {}
          if (mode === 'PASSWORD') {
            trans = {
              mode,
              adminName: form.adminName,
              password: form.password
            }
          } else {
            trans = {
              mode,
              adminName: form.adminName,
              password: form.code
            }
          }
          this.props.managelogin(trans, this.props.history)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    } else {
      return false
    }
  }
  onChange = (key, val) => {
    this.setState({
      form: Object.assign({}, this.state.form, { [key]: val.target.value })
    })
  }
  selectAdminLoginByName = async () => {
    const res = await api.selectAdminLoginByNameApi({ adminName: this.state.form.adminName })
    console.log(res)
    if (res.success) {
      this.setState({
        adminName: this.state.form.adminName,
        loginMode: res.data
      })
    } else {
      this.setState({
        form: {
          adminName: ''
        }
      })
      Message.warning(res.msg)
    }
  }
  nameBlur = () => {
    if (this.state.form.adminName !== '') {
      //this.selectAdminLoginByName()
    }
  }
  render() {
    const { form, rules, loginMode } = this.state
    const { btnLoading } = this.props
    return (
      <div class="login2_body">
        <div class="login2_content">
          <Form model={form} rules={rules} ref={e => { this.form = e }}>
            <div class="login2_form sign-in">
              <h2 className='login2_h2'>欢迎回来</h2>
              <label className='label'>
                <span className='label_span'>用户名</span>
                <Form.Item prop="adminName">
                  <input className='login2_rm input' value={form.adminName} onInput={this.onChange.bind(this, 'adminName')} onBlur={this.nameBlur} type="text" />
                </Form.Item>
              </label>
              <label className='label'>
                <span className='label_span'>密码</span>
                <Form.Item prop="password">
                  <input className='login2_rm input' type="password" value={form.password} onInput={this.onChange.bind(this, 'password')} />
                </Form.Item>
              </label>
              <p class="forgot-pass"><a href="javascript:">忘记密码？请联系管理员</a></p>
              <button type="button" class="submit login2_rm button" onClick={this.channelLogin}>登 录</button>
              <button style={{ display: 'none' }} type="button" class=" button fb-btn">使用 <span>facebook</span> 帐号登录</button>
            </div>
          </Form>
          <div class="login2_sub-cont">
            <div class="login2_img">
              <div class="img__text m--up">
                <h2 className='login2_h2'>{APP_NAME}</h2>
                <p>欢迎来到{APP_NAME}后台管理平台</p>
              </div>
              <div class="img__text m--in">
                <h2 className='login2_h2'>已有帐号？</h2>
                <p>有帐号就登录吧，好久不见了！</p>
              </div>
              <div class="img__btn" style={{ display: 'none' }}>
                <span class="m--up">注 册</span>
                <span class="m--in">登 录</span>
              </div>
            </div>
            <div class="login2_form sign-up">
              <h2 className='login2_h2'>立即注册</h2>
              <label className='label'>
                <span>用户名</span>
                <input type="text" />
              </label>
              <label className='label'>
                <span>用户名</span>
                <input type="email" />
              </label>
              <label className='label'>
                <span>密码</span>
                <input type="password" />
              </label>
              <button type="button" class="submit button">注 册</button>
              <button type="button" class="fb-btn">使用 <span>facebook</span> 帐号注册</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
Login.propTypes = {
  propsData: PropTypes.object,
  propsDataHistory: PropTypes.object
}
const mapStateToProps = state => {
  const {
    btnLoading
  } = state
  return {
    btnLoading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ managelogin }, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
