import React, { Component } from 'react'
import { Drawer, Alert } from 'antd'
import { Form, Button,Input } from 'element-react'
import apiV2 from '../../api/api-v2'
import PropTypes from 'prop-types'
import Condition from '../../components/Condition'

/**
 * 订单审核额度
 */
export default class OrderApprovalLimit extends Component {

    static propTypes = {
        orderId: PropTypes.number,
        onClose: PropTypes.func
    }

    static RemissionAmountEmpty = {
        orderId: null,
        quota: 3000,
        rate: 0,
        deadlineDay: 0
    }

    static ResultEmpty = { message: '' }

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            addFormData: OrderApprovalLimit.RemissionAmountEmpty,
            result: OrderApprovalLimit.ResultEmpty
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId && this.props.orderId) {
            this.open()
        }
    }

    open() {
        this.setState({ visible: true })
    }

    changeAddForm(name, value) {
        const addFormData = Object.assign(this.state.addFormData, { [name]: value })
        this.setState({ addFormData })
    }

    async onSubmit() {
        const data = this.state.addFormData
        data.orderId = this.props.orderId
        const result = await apiV2.uptOrderQuota({ data })
        this.setState({ result })
    }

    close() {
        this.setState({ visible: false })
        this.props.onClose()
    }

    render() {
        const { addFormData, result, visible } = this.state

        return (
            <Drawer title="订单额度调整" placement="right" onClose={() => this.close()} visible={visible}>
                <Form>
                    <Form.Item label="周期">
                        <Input value={addFormData.deadlineDay} onChange={(e) => this.changeAddForm('deadlineDay', e)}
                            placeholder="单位（天）" />
                    </Form.Item>

                    <Form.Item label="额度">
                        <Input value={addFormData.quota} onChange={(e) => this.changeAddForm('quota', e)}
                            placeholder="精确到分" />
                    </Form.Item>
                    <Form.Item label="费率">
                        <Input value={addFormData.rate} onChange={(e) => this.changeAddForm('rate', e)}
                            placeholder="百分比" />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.onSubmit()} type="primary">{'确定'}</Button>
                    </Form.Item>

                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert message={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>)
    }
}
