import React, { Component } from 'react'

import { Statistic } from 'antd'
import { MoneyStatisticsStyle } from '../utils/format'
import PropTypes from 'prop-types'

/**
* 金额
*/
export default class Paste extends Component {

    static propTypes = {
        value: PropTypes.any,
        percentage: PropTypes.bool
    }

    render() {
        const style = { ...MoneyStatisticsStyle }
        const { value, percentage } = this.props

        try {
            if (this.props.value >= 0.7) {
                style.color = 'red'
            }
        } catch (e) {
            console.debug(e)
        }

        return (
            <Statistic valueStyle={style} value={percentage ? (value * 100).toFixed(2) + '%' : (value || 0)} />
        )
    }
}
