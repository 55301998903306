import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CardHead from '../../components/card_head'
import { Layout, Table } from 'element-react'
import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { GaugeChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use(
    [GridComponent, GaugeChart, CanvasRenderer]
)
/**
 * 信用评分报告
 */
export default class CreditScoringReport extends Component {
    static propTypes = {
        value: PropTypes.any,
    }

    componentDidMount() {
        const myChart = echarts.init(document.getElementById('credit_scoring_chat'))
        this.setState({ myChart }, this.setData)
    }

    componentDidUpdate() {
        this.setData()
    }

    setData(v = this.props.value) {
        this.state.myChart.setOption({
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.25, '#FF6E76'],
                                [0.5, '#FDDD60'],
                                [0.75, '#58D9F9'],
                                [1, '#7CFFB2']
                            ]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '12%',
                        width: 20,
                        offsetCenter: [0, '-60%'],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: '#464646',
                        fontSize: 20,
                        distance: -60,
                        formatter: function () {
                            return ''
                        }
                    },
                    title: {
                        offsetCenter: [0, '-20%'],
                        fontSize: 30
                    },
                    detail: {
                        fontSize: 50,
                        offsetCenter: [0, '0%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            return Math.round(value * 1000) + '分'
                        },
                        color: 'auto'
                    },
                    data: [
                        {
                            value: v / 1000,
                            name: '米融分数'
                        }
                    ]
                }
            ]
        })
    }

    render() {
        const columns = [
            {
                label: '等级区间',
                prop: 'r',
                align: 'center',
            },
            {
                label: '等级风险',
                prop: 'l',
                align: 'center',
            },
            {
                label: '描述',
                prop: 'd',
                align: 'center',
            }
        ]
        const data = [
            { r: '0-50', l: '通过', d: '通过' },
            { r: '50-60', l: '都一定欺诈率', d: '请仔细审核' },
            { r: '60-70', l: '欺诈概率较较高', d: '建议拒绝，否者请仔细检查其他数据' },
            { r: '70-80', l: '欺诈概率较高', d: '建议拒绝' },
            { r: '80-100', l: '欺诈概率较极高', d: '建议拒绝' }
        ]

        return (
            <CardHead title="信用评分报告">
                <Layout.Row gutter="20" justify="space-between" align="middle">
                    <Layout.Col span="10">
                        <div id="credit_scoring_chat" style={{ width: 500, height: 500 }} />
                    </Layout.Col>
                    <Layout.Col span="14">
                        <Table columns={columns} data={data} />
                    </Layout.Col>
                </Layout.Row>
            </CardHead>
        )
    }
}