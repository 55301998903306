import { Drawer } from 'antd'
import { Button, Form, Input, Select, Switch, Alert } from 'element-react'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { ChannelBillingType } from '../types'
import apiV2 from '../../../api/api-v2'
import Condition from '../../../components/Condition'



/**
 * 推广渠道表单
 */
export default class PromotionChannelsForm extends Component {

    static propTypes = {
        show: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object
    }

    static AddEmpty = {
        'channel': '',
        'name': '',
        'price': 0,
        'quota': 0,
        'remark': '',
        isPool: false,
        'status': true,
        'type': '',
        'url': ''
    }

    constructor(props) {
        super(props)

        this.state = {
            submitLoading: false,
            visible: props.show,
            formData: PromotionChannelsForm.AddEmpty,
            result: { message: '' }
        }
    }



    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show) {
            const formData = this.props.data
            this.setState({ visible: true, formData: formData ? formData : PromotionChannelsForm.AddEmpty })
        }
    }



    onClose() {
        this.setState({
            visible: false,
            result: { message: '' }
        })
        const onClose = this.props.onClose
        if (onClose) onClose()
    }

    changeFormData(name, value) {
        const formData = Object.assign(this.state.formData, { [name]: value })
        this.setState({ formData })
    }

    async onSubmit() {
        this.setState({ loading: true })
        try {
            const result = await apiV2.savePromotionChannel({ data: this.state.formData })
            this.setState({ result })
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        const { formData, result, visible,submitLoading } = this.state

        return (
            <Drawer title="推广渠道" visible={visible} width="600" onClose={() => this.onClose()}>
                <Form>
                    <Form.Item label="名称">
                        <Input value={formData.name} onChange={e => this.changeFormData('name', e)} placeholder="自定义名称" />
                    </Form.Item>
                    <Form.Item label="渠道名称">
                        <Input value={formData.channel} onChange={e => this.changeFormData('channel', e)} placeholder="自定义名称" />
                    </Form.Item>
                    <Form.Item label="计费类型">
                        <Select value={formData.type} onChange={e => this.changeFormData('type', e)}>
                            {ChannelBillingType.map(label => <Select.Option value={label} key={label} label={label} />)}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item label="推广地址">
                        <Input value={formData.url} onChange={e => this.changeFormData('url', e)} type="textarea" placeholder="Http协议" />
                    </Form.Item> */}
                    <Form.Item label="是否开启">
                        <Switch onText="开启" offText="关闭" value={formData.status} onChange={e => this.changeFormData('status', e)} />
                    </Form.Item>
                    <Form.Item label="价格">
                        <Input value={formData.price} onChange={e => this.changeFormData('price', e)} placeholder="精确到分" />
                    </Form.Item>
                    <Form.Item label="授信额度">
                        <Input value={formData.quota} onChange={e => this.changeFormData('quota', e)} placeholder="精确到分" />
                    </Form.Item>
                    <Form.Item label="是否对库">
                        <Switch value={formData.isPool} onChange={e => this.changeFormData('isPool', e)} />
                    </Form.Item>
                    <Form.Item label="备注">
                        <Input value={formData.remark} onChange={e => this.changeFormData('remark', e)} type="textarea" />
                    </Form.Item>
                    <Form.Item >
                        <Button disabled={submitLoading} loading={submitLoading} onClick={() => this.onSubmit()} type="primary">{'提交'}</Button>
                    </Form.Item>




                    <Condition visible={result.message}>
                        <Form.Item>
                            <Alert title={result.message} type={result.success ? 'success' : 'error'} />
                        </Form.Item>
                    </Condition>
                </Form>
            </Drawer>
        )
    }
}
