import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FractionBoxProgress from './fraction_box_progress'

/**
 * 评分报告
 */
export default class ScoringReport extends Component {

    static propTypes = {
        data: PropTypes.array,
    }

    render() {
        return (
            <FractionBoxProgress title="评分报告" data={this.props.data} />
        )
    }
}