import React, { Component } from 'react'

import { Button, Form, Input, Message, Switch, Checkbox, Tabs, Card, Radio } from 'element-react'
import { Select, Slider } from 'antd'
import apiV2 from '../../api/api-v2'
import Space from '../../components/space'
import Condition from '../../components/Condition'
import ImageUploadV1 from '../../components/ImageUploadV1'

function parseJSONArray(content) {
    try {
        const json = JSON.parse(content)
        return Array.isArray(json) ? json : []
    } catch (e) {
        console.debug(e)
    }
    return []
}

/**
 * 系统设置
 */
export default class UserBlacklist extends Component {


    static ConfigKeyName = 'configKey'
    static ConfigValueName = 'content'
    static DeserializationValue = ','

    static AttrType = {
        12: 'switch', 20: 'switch', 30: 'tag', 16: 'checkbox',
        17: 'range', 22: 'switch', 23: 'switch', 24: 'switch', 25: 'switch',
        33: 'switch', 34: 'switch', 31: 'switch'
    }

    constructor(props) {
        super(props)

        this.state = {
            configList: [],
            alipayApps: [],
            alipayManyConfig: null
        }
    }

    componentDidMount() {
        this.getAllConfig()
        this.fetchAlipayManyConfig()
        this.getAlipayManyApps()
    }

    async getAllConfig() {
        const { body } = await apiV2.getAllConfig({})
        this.setState({ configList: body || [] })
    }

    async getAlipayManyApps() {
        const list = await apiV2.getAlipayManyApps({})
        if (list.length) {
            this.setState({ alipayApps: list || [] })
        }
    }

    async fetchAlipayManyConfig() {
        const alipayManyConfig = await apiV2.getAlipayManyConfig({})
        this.setState({
            alipayManyConfig
        })
    }

    newConfigItem(configKey) {
        return { [UserBlacklist.ConfigKeyName]: configKey, [UserBlacklist.ConfigValueName]: '' }
    }

    findRow(name) {
        return this.state.configList.filter((row) => {
            return row[UserBlacklist.ConfigKeyName] === name
        })[0] || this.newConfigItem(name)
    }

    findValue(key, parseFn) {
        const value = this.findRow(key)[UserBlacklist.ConfigValueName]
        if (parseFn) return parseFn(value)
        return value
    }

    divisionToArray(value, split = UserBlacklist.DeserializationValue) {
        try {
            if (!value) return []
            return value.split(split)
        } catch (e) {
            console.debug(e)
            return ''
        }
    }



    checkboxToArray(value) {
        return parseJSONArray(value).filter(({ open }) => open).map(({ name }) => name)
    }


    divisionToArrayNumber(value, split = UserBlacklist.DeserializationValue) {
        try {
            if (!value) return []
            return value.split(split).map(it => parseInt(it))
        } catch (e) {
            console.debug(e)
            return ''
        }
    }


    arrayTocheckbox(value, original) {
        return JSON.stringify(parseJSONArray(original).map(row => {
            row.open = value.includes(row.name)
            return row
        }))
    }

    arrayToImage({ value, name }, original) {
        return JSON.stringify(parseJSONArray(original).map(row => {
            if (name === row.key) row.content = value
            return row
        }))
    }

    arrayToDivision(value, og, split = UserBlacklist.DeserializationValue) {
        console.log(og)
        return (value || []).join(split)
    }

    setConfigValue(name, value, deserializationFn) {
        const find = this.findRow(name)

        if (deserializationFn) {
            find[UserBlacklist.ConfigValueName] = deserializationFn(value, find[UserBlacklist.ConfigValueName])
        } else {
            find[UserBlacklist.ConfigValueName] = value
        }


        let configList = this.state.configList

        if (find.configId) { // 存在
            configList = configList.map((row) => {
                return row[UserBlacklist.ConfigKeyName] === name ? find : row
            })
        } else { //不存在
            configList = [...configList, find]
        }

        this.setState({ configList })

    }

    async udpateTo(name) {
        const find = this.findRow(name)
        const { success, message } = await apiV2.saveSystemConfig({ data: find })
        if (success) {
            Message.success(message)
        } else {
            Message.error(message)
        }
    }

    uptUseAppId() {
        return (v) => {
            const alipayManyConfig = Object.assign(this.state.alipayManyConfig, { useAppId: v })
            this.setState({ alipayManyConfig })
        }
    }

    submitAlipayManyConfig() {
        return async () => {
            try {
                await apiV2.saveAlipayManyConfig({ param: this.state.alipayManyConfig })
                Message.success('修改成功')
            } catch (e) {
                console.warn(e)
                Message.error('修改失败')
            }
        }
    }

    render() {
        const { alipayManyConfig, alipayApps } = this.state
        return (
            <Tabs type="border-card" activeName="1">
                <Tabs.Pane label="常规配置" name="1">
                    <Form labelWidth="200">
                        {
                            this.state.configList.map(({ configKey, title, content }) => {
                                const type = UserBlacklist.AttrType[configKey] || 'text'
                                return (
                                    <Form.Item label={title} key={configKey}>
                                        <Condition visible={type === 'checkbox'}>
                                            <Checkbox.Group value={this.findValue(configKey, this.checkboxToArray)} style={{ display: 'flex' }}
                                                onChange={(v) => this.setConfigValue(configKey, v, this.arrayTocheckbox)}>
                                                {parseJSONArray(content).map(({ name, key, content: img }) => {
                                                    return (
                                                        <Checkbox key={key} name={key} label={name}>{name}
                                                            <ImageUploadV1 url={img}
                                                                onChange={value => this.setConfigValue(configKey, { value, name: key }, this.arrayToImage)} />
                                                        </Checkbox>)
                                                })}
                                            </Checkbox.Group>
                                        </Condition>

                                        <Condition visible={type === 'text'}>
                                            <Input value={this.findValue(configKey)} onChange={(v) => this.setConfigValue(configKey, v)} />
                                        </Condition>
                                        <Condition visible={type === 'switch'}>
                                            <Switch onText="开启" offText="关闭" value={this.findValue(configKey) === 'true'} onChange={(v) => this.setConfigValue(configKey, v)} />
                                        </Condition>
                                        <Condition visible={type === 'tag'}>
                                            <Select value={this.findValue(configKey, this.divisionToArray)}
                                                onChange={(v) => this.setConfigValue(configKey, v, this.arrayToDivision)}
                                                mode="tags" placeholder="输入后按回车确定" />
                                        </Condition>

                                        <Condition visible={type === 'range'}>
                                            <Slider defaultValue={this.findValue(configKey, this.divisionToArrayNumber)} range min={18} max={80}
                                                onChange={(v) => this.setConfigValue(configKey, v, this.arrayToDivision)} />
                                        </Condition>

                                        <Space><Button type="text" onClick={() => this.udpateTo(configKey)}>{'修改'}</Button></Space>
                                    </Form.Item>)
                            })
                        }

                        <Form.Item style={{ display: 'none' }}>
                            <Button type="primary">{'修改'}</Button>
                        </Form.Item>

                    </Form>
                </Tabs.Pane>
                <Tabs.Pane label="支付管理" name="2">
                    <Card header={
                        <div className="clearfix">
                            <span style={{ lineHeight: '36px' }}>{'指定支付宝收款商户'}</span>
                            <span style={{ float: 'right' }}>
                                <Button onClick={this.submitAlipayManyConfig()} type="primary">{'修改'}</Button>
                            </span>
                        </div>
                    }
                    >
                        <h3> {'指定支付AppId'} </h3>
                        {alipayManyConfig ?
                            <Radio.Group value={alipayManyConfig.useAppId} onChange={this.uptUseAppId()}>
                                {(alipayApps || []).map((appId) => {
                                    return <Radio.Button key={appId} value={appId} />
                                })}
                            </Radio.Group> : null}
                    </Card>
                </Tabs.Pane>
            </Tabs>
        )
    }
}
