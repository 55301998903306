import { Drawer } from 'antd'
import { Button, Form, Table, Input, Switch, Alert } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'

import Condition from '../../components/Condition'
import EnabkeStatus from '../../components/EnabkeStatus'
import Money from '../../components/Money'
import Pagination from '../../components/MyPagination'

export default class WithdrawalQuota extends Component {

    static ResultEmpty = { records: [], total: 0 }
    static AddOrUptFormEmpty = {
        'isDelete': false,
        'money': 0,
        'num': 0,
        'promoteId': null,
    }

    constructor(props) {
        super(props)

        this.state = {
            result: WithdrawalQuota.ResultEmpty,
            queryForm: {
                current: 1,
                size: 10
            },
            resultSubmit: { message: '' },
            columns: [
                {
                    label: '#',
                    prop: 'promoteId',
                    width: '60',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '添加时间',
                    prop: 'createTime',
                    align: 'center'
                },
                {
                    label: '提升额度',
                    prop: 'money',
                    align: 'center',
                    render: ({ money }) => <Money value={money} />
                },
                {
                    label: '状态',
                    prop: 'isDelete',
                    align: 'center',
                    render: ({ isDelete }) => <EnabkeStatus enable={!isDelete} />
                },
                {
                    label: '还款次数',
                    prop: 'num',
                    align: 'center'
                },
                {
                    label: '操作',
                    prop: 'row',
                    width: '80',
                    align: 'center',
                    fixed: 'right',
                    render: (row) => {
                        return <Button onClick={() => this.openEdit(row)} type="text">{'编辑'}</Button>
                    }
                }
            ],
            formVisible: false,
            addOrUptForm: WithdrawalQuota.AddOrUptFormEmpty
        }
    }

    componentDidMount() {
        this.getListResult()
    }

    changeQueryForm(name, value, refreshList) {
        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => refreshList && this.getListResult())
    }

    async getListResult() {
        const { body } = await apiV2.getPromoteQuotaList({ data: this.state.queryForm })
        this.setState({ result: body || WithdrawalQuota.ResultEmpty })
    }

    changeAUForm(name, value) {
        const addOrUptForm = Object.assign(this.state.addOrUptForm, { [name]: value })
        this.setState({ addOrUptForm })
    }

    openEdit(row) {
        this.setState({
            addOrUptForm: row,
            formVisible: true
        })
    }

    async submit() {
        const resultSubmit = await apiV2.savePromoteQuota({ data: this.state.addOrUptForm })
        this.setState({ resultSubmit})
        this.getListResult()
    }

    render() {
        const { columns, result, queryForm, formVisible, addOrUptForm, resultSubmit } = this.state

        return (
            <>
                <Form>
                    <Form.Item>
                        <Button type="primary" onClick={() => this.openEdit(WithdrawalQuota.AddOrUptFormEmpty)}>{'添加'}</Button>
                    </Form.Item>
                </Form>
                <Table columns={columns} data={result.records} />
                <Pagination total={result.total} pageSize={queryForm.size} pageNumber={queryForm.current}
                    onCurrentChange={(e) => this.changeQueryForm('current', e, true)}
                    onSizeChange={(e) => this.changeQueryForm('size', e, true)} />

                <Drawer visible={formVisible} onClose={() => this.setState({ formVisible: false })} width="500">
                    <Form >
                        <Form.Item label="提升额度">
                            <Input value={addOrUptForm.money} onChange={(e) => this.changeAUForm('money', e)} />
                        </Form.Item>

                        <Form.Item label="还款次数">
                            <Input value={addOrUptForm.num} onChange={(e) => this.changeAUForm('num', e)} />
                        </Form.Item>

                        <Form.Item label="是否禁用">
                            <Switch onText="是" offText="否" value={addOrUptForm.isDelete} onChange={(e) => this.changeAUForm('isDelete', e)} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => this.submit()}>{'提交'}</Button>
                        </Form.Item>

                        <Condition visible={resultSubmit.message}>
                            <Form.Item>
                                <Alert message={resultSubmit.message} type={resultSubmit.success ? 'success' : 'error'} />
                            </Form.Item>
                        </Condition>
                    </Form>
                </Drawer>
            </>)
    }

}
