import { Alert, Button, Card, InputNumber, Message, Pagination, Table } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import Text from '../../components/text'
import Popconfirm from '../../components/MyPopconfirm'
import ContactCustomerServiceForm from './components/contact_customer_service_form'
import { Popover } from 'antd'


export default class ContactCustomerService extends Component {

    constructor(props) {
        super(props)

        this.state = {
            query: {
                current: 1,
                size: 10
            },
            result: null,
            resultSort: null,
            formShow: false,
            saveRow: null,
            formSort: null,
            columns: [
                {
                    label: '添加时间',
                    prop: 'createTime',
                    width: 200,
                    align: 'center',
                    render: ({ createTime }) => <Text bold color="blue">{createTime}</Text>
                },
                {
                    label: '信息',
                    prop: 'content',
                    align: 'center',
                },
                {
                    label: '排序',
                    prop: 'sort',
                    width: 200,
                    align: 'center',
                    render: ({ sort, serverId }) => {
                        const { formSort, resultSort } = this.state

                        return (
                            <Popover onVisibleChange={o => !o ? this.setState({ resultSort: null, formSort: null }) : null} content={
                                <>
                                    <InputNumber size="small" defaultValue={formSort ? formSort.sort : sort} onChange={this.setSort(serverId)} />
                                    <Button onClick={() => this.submitSort()} type="text">{'确认修改'}</Button>
                                    {resultSort ? <Alert type={resultSort.success ? 'success' : 'error'} title={resultSort.message} /> : null}
                                </>}>
                                <a>{sort}</a>
                            </Popover>)
                    }
                },
                {
                    label: '标题',
                    prop: 'title',
                    width: 200,
                    align: 'center',
                    fixed: true,
                },
                {
                    label: '操作',
                    width: 140,
                    align: 'center',
                    fixed: 'right',
                    render: (row) => {
                        return (
                            <>
                                <Button.Group>
                                    <Button onClick={this.openForm(row)} type="primary" icon="edit" />
                                    <Popconfirm onConfirm={this.deleteRow(row)}>
                                        <Button type="primary" icon="delete" />
                                    </Popconfirm>
                                </Button.Group>
                            </>)
                    }
                },

            ]
        }
    }

    componentDidMount() {
        this.fetchContactCustomerServiceList()
    }

    async fetchContactCustomerServiceList() {
        const { body } = await apiV2.getContactCustomerServiceList({ param: this.state.query })
        this.setState({ result: body })
    }

    // 删除
    deleteRow({ serverId }) {
        return async () => {
            const { message, success } = await apiV2.delContactCustomerServiceList({ serverId })

            if (success) {
                Message.success(message)
            } else {
                Message.error(message)
            }

            this.listRefresh()
        }
    }

    // 修改查询表单参数
    editQueryForm(name, resultRefresh = true) {
        return v => {
            const query = Object.assign(this.state.query, { [name]: v })
            this.setState(query, () => {
                if (resultRefresh) this.fetchContactCustomerServiceList()
            })
        }
    }

    listRefresh() {
        return () => {
            this.setState({ formShow: false })
            this.fetchContactCustomerServiceList()
        }
    }

    openForm(saveRow) {
        return () => this.setState({ saveRow, formShow: true })
    }

    setSort(serverId) {
        return (value) => {
            this.setState({ formSort: { sort: value, serverId } })
        }
    }

    async submitSort() {
        const { serverId, sort } = this.state.formSort
        const resultSort = await apiV2.sortContactCustomerServiceList({ serverId, sort })
        this.setState({ resultSort })
    }

    render() {
        const { result, query, columns, formShow, saveRow } = this.state
        return (
            <Card
                header={
                    <div className="clearfix">
                        <span style={{ lineHeight: '36px' }}>{'客服信息列表'}</span>
                        <span style={{ float: 'right' }}>
                            <Button onClick={this.listRefresh()} type="primary">{'刷新'}</Button>
                            <Button onClick={this.openForm(null)} type="primary">{'添加'}</Button>
                        </span>
                    </div>
                }
            >
                {result ?
                    <>
                        <Table data={result.records} columns={columns} />
                        <Pagination total={result.total} current={query.current} size={query.size}
                            onCurrentChange={this.editQueryForm('current', true)}
                            onSizeChange={this.editQueryForm('size', true)}
                        />
                    </> : null}

                <ContactCustomerServiceForm data={saveRow} show={formShow} onClose={this.listRefresh()} />
            </Card>
        )
    }
} 