import { Form, Input, Button, Table, Pagination } from 'element-react'
import React, { Component } from 'react'
import api from '../../api/api-v2'
import Text from '../../components/text'
import Money from '../../components/Money'
import EnabkeStatus from '../../components/EnabkeStatus'

// 短信流水列表
export default class SmsBillList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            queryForm: {
                mobile: null,
                current: 1,
                size: 10
            },
            columns: [{
                label: '发生时间',
                prop: 'createTime',
                width: 200,
                align: 'center',
                fixed: true,
                render: ({ createTime }) => {
                    return <Text bold color="blue">{createTime}</Text>
                }
            }, {
                label: '手机号',
                prop: 'mobile',
                align: 'center',
                render: ({ mobile }) => <Money value={mobile} />
            }, {
                label: '状态',
                prop: 'status',
                width: 140,
                align: 'center',
                render: ({ status }) => <EnabkeStatus enable={status} />
            }, {
                label: '内容',
                prop: 'content',
                align: 'center',
            }, {
                label: '失败原因',
                prop: 'errMsg',
                align: 'center',
            }],
            result: null
        }
    }

    componentDidMount() {
        this.fetchResult()
    }

    // 修改查询表单参数
    editQueryForm(name, resultRefresh) {
        return v => {
            const queryForm = Object.assign(this.state.queryForm, { [name]: v })
            this.setState(queryForm, () => {
                if (resultRefresh) this.fetchResult()
            })
        }
    }

    // 获取数据
    async fetchResult() {
        const { body } = await api.fetchSmsBillList({ param: this.state.queryForm })
        this.setState({
            result: body
        })
    }

    render() {
        const { queryForm, columns, result } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="手机号">
                        <Input value={queryForm.mobile} onChange={this.editQueryForm('mobile')} />
                    </Form.Item>
                    <Form.Item >
                        <Button onClick={() => this.fetchResult()}>{'查询'}</Button>
                    </Form.Item>
                </Form>
                {result ?
                    <>
                        <Table border columns={columns} data={result.records} />
                        <Pagination total={result.total} current={queryForm.current} size={queryForm.size}
                            onCurrentChange={this.editQueryForm('current', true)}
                            onSizeChange={this.editQueryForm('size', true)}
                        />
                    </> : null}

            </>)
    }
}